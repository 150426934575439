<div class="personal-page-header"
     [ngClass]="{'personal-page-header-large': 1,
                 'personal-page-header-mobile': isMobile,
                 'personal-page-header-can-edit': userCanEdit()}">

  <!-- Currently displayed image -->
  <div 
      *ngIf="image1"
      appPhotoDisplay 
      [photoData]="image1"
      [isBackgroundImage]=true
      class="personal-page-header-image"
      [ngClass]="{'personal-page-header-image-fade-in': image1IsActive,
                  'personal-page-header-image-fade-out': !image1IsActive}"
      >
  </div>

  <!-- Next image to display -->
  <div
      *ngIf="image2"
      appPhotoDisplay
      [photoData]="image2"
      [isBackgroundImage]=true
      class="personal-page-header-image personal-page-header-large"
      [ngClass]="{'personal-page-header-image-fade-in': image2IsActive,
                  'personal-page-header-image-fade-out': !image2IsActive}">
  </div>

  <!-- Dimmer -->
  <div class="personal-page-header-image-dimmer">
  </div>

  <!-- Main Content -->
  <div class="personal-page-header-content">

    <div *ngIf="header">

      <!-- Headline -->
      <div class="personal-page-header-headline">
        {{header.headline}}
      </div>

      <!-- Subline -->
      <div class="personal-page-header-subline">
        {{header.subline}}
      </div>

      <!-- Pencil icon for opening up edit-mode -->
      <div *ngIf="!editor.isEditMode && userCanEdit()" 
          class="personal-page-header-edit-block">
        <div class="personal-page-edit-icon-holder"
             (click)="openSettingsDialog()">
          <kint-icon data-icon="pencil" class="header-photo-add-picture-icon">
          </kint-icon>
        </div>
      </div>
    </div>

    
  </div>

</div>