import { Component, OnInit, Input } from '@angular/core';
import { KintTimelineSupplementText } from '../../timeline-supplement';

@Component({
  selector: 'app-timeline-supplement-story',
  templateUrl: './timeline-supplement-story.component.html',
  styleUrls: ['./timeline-supplement-story.component.css']
})
export class TimelineSupplementStoryComponent implements OnInit {

  @Input('story') story: string;
  @Input('supplement') supplement: KintTimelineSupplementText;

  constructor() { }

  ngOnInit() {
  }

}
