import { AfterViewChecked } from '@angular/core';

/**
 *
 * This class can be used to wait for the next UI update of the
 * given component.
 *
 * For example, maybe I execute a command and want to run something
 * else after the DOM is updated. For that, I can simply:
 *
 * this.onNextViewCheck().then( () => {
 *   // do stuff here
 * })
 *
 */

export class ViewCheckHelper implements AfterViewChecked {

  private waitingForNextCheck = false;


  /**
   * Called every time the view is re-drawn/checked
   */
  ngAfterViewChecked(): void {
    if (this.waitingForNextCheck) {
      this.waitingForNextCheck = false;
    }
  }


  /**
   * Returns a promise which resolves after the next ngAfterViewChecked
   */
  onNextViewCheck(): Promise<any> {
    this.waitingForNextCheck = true;

    const promise = new Promise((resolve, reject) => {

      function waitAndCheck() {
        setTimeout(function() {
          if ( this.waitingForNextCheck ) {
            waitAndCheck();
          } else {
            resolve(true);
          }
        }, 50);
      }

      waitAndCheck();
    });

    return promise;
  }

}
