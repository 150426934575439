import { OnInit } from '@angular/core';
import { KintTimelineBlock } from '../../kint-timeline-block';
import { KintTimeline } from '../../kint-timeline';
var TimelineBlockVerticalComponent = /** @class */ (function () {
    function TimelineBlockVerticalComponent(ng1AppDataModelService) {
        this.ng1AppDataModelService = ng1AppDataModelService;
    }
    TimelineBlockVerticalComponent.prototype.ngOnInit = function () {
    };
    TimelineBlockVerticalComponent.prototype.isMobile = function () {
        return this.ng1AppDataModelService.isMobile();
    };
    return TimelineBlockVerticalComponent;
}());
export { TimelineBlockVerticalComponent };
