import { Component, OnInit, Input, ElementRef, ViewChild} from '@angular/core';
import { PersonalPageRow } from 'src/app/KintObject/kint-personal-page';
import { PersonalPageEditor } from '../personal-page-editor';
import { ViewCheckHelper } from 'src/app/Util/viewCheck/view-check-helper';

// jQuery... naughty...
declare var $: any;

@Component({
  selector: 'app-personal-page-tiles-row',
  templateUrl: './personal-page-tiles-row.component.html',
  styleUrls: ['./personal-page-tiles-row.component.css']
})
export class PersonalPageTilesRowComponent extends ViewCheckHelper implements OnInit {

  @Input('row') row: PersonalPageRow;
  @Input('isMobile') isMobile: boolean;
  @Input('editor') editor: PersonalPageEditor;

  @ViewChild('headlineField') headlineField: ElementRef;
  @ViewChild('textField') textField: ElementRef;

  constructor(private elementRef: ElementRef) {
    super();
  }

  ngOnInit() {
  }


  clickedTextField(): void {
    this.startEditMode('textField');
  }
  clickedHeadlineField(): void {
    this.startEditMode('headlineField');
  }

  /**
   * Request starting edit mode with focus on the given field
   */
  startEditMode(fieldToSelect: string): void {

    // Get the top of this element pre-edit-mode
    const oldElementTop = this.elementRef.nativeElement.getBoundingClientRect().top;

    // Actually start the edit mode
    this.editor.startEditMode();

    // Fix the scroll position after we re-render
    this.onNextViewCheck().then(() => {
      const updatedElementTop = this.elementRef.nativeElement.getBoundingClientRect().top;

      const currentScrollPos = $(window).scrollTop();
      const newScrollPos = currentScrollPos - (oldElementTop - updatedElementTop);
      $(window).scrollTop(newScrollPos);

      if (fieldToSelect === 'headlineField') {
        this.headlineField.nativeElement.focus();
      } else if (fieldToSelect === 'textField') {
        this.textField.nativeElement.focus();
      }
    });

  }


  /**
   * Whether or not to display in 2-column format or not
   */
  showTwoColumnTiles(): boolean {
    if (this.isMobile) {
      return false;
    }

    if ((this.row.tiles.length === 1) ||
        (this.row.tiles.length === 2) ||
        (this.row.tiles.length === 4)) {
      return true;
    }
    return false;
  }


  /**
   * Whether or not to display in 3-column format or not
   */
  showThreeColumnTiles(): boolean {
    if (this.isMobile) {
      return false;
    }

    if ((this.row.tiles.length === 3) ||
        (this.row.tiles.length > 5)) {
      return true;
    }
    return false;
  }

}
