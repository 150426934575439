/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./party.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../ng1/General/kint-slider-toggle.component";
import * as i3 from "./party.component";
import * as i4 from "../../MessageBox/message-box.service";
import * as i5 from "../../Util/util/util.service";
var styles_PartyComponent = [i0.styles];
var RenderType_PartyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PartyComponent, data: {} });
export { RenderType_PartyComponent as RenderType_PartyComponent };
export function View_PartyComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "ng1-kint-slider-toggle", [], null, [[null, "dataToggleChange"], [null, "callback"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("dataToggleChange" === en)) {
        var pd_0 = ((_co.sliderValue = $event) !== false);
        ad = (pd_0 && ad);
    } if (("callback" === en)) {
        var pd_1 = (_co.dataChangeCallback() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 999424, null, 0, i2.KintSliderToggleDirective, [i1.ElementRef, i1.Injector], { dataToggle: [0, "dataToggle"] }, { callback: "callback" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.sliderValue; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.partyName; _ck(_v, 2, 0, currVal_0); }); }
export function View_PartyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-party", [], null, null, null, View_PartyComponent_0, RenderType_PartyComponent)), i1.ɵdid(1, 114688, null, 0, i3.PartyComponent, [i4.MessageBox, i5.UtilService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PartyComponentNgFactory = i1.ɵccf("app-party", i3.PartyComponent, View_PartyComponent_Host_0, {}, {}, []);
export { PartyComponentNgFactory as PartyComponentNgFactory };
