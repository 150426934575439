import { OnInit, EventEmitter } from '@angular/core';
import { KintFaceComparePrompt } from 'src/app/KintObject/kint-faces';
import { downgradeComponent } from '@angular/upgrade/static';
var FaceCompareComponent = /** @class */ (function () {
    function FaceCompareComponent(faceComparePromptService, domService) {
        this.faceComparePromptService = faceComparePromptService;
        this.domService = domService;
        this.closeCallback = new EventEmitter();
        this.promptingForPersonId = null;
        this.isProcessing = false;
        this.FACE_SLIDE_ANIMATION_DURATION = '0.75s';
        this.FACE_SLIDE_ANIMATION_DISTANCE = '700px';
        this.FACE_THUMBNAIL_HOLDER_SELECTOR = '.face-compare-thumbnail-holder';
    }
    /**
     * Initialize Component
     */
    FaceCompareComponent.prototype.ngOnInit = function () {
        if (this.faceComparePrompts.length < 1) {
            console.error('FaceCompareComponent may not be loaded without a list of faceComparPrompts. Closing');
            return;
        }
        this.currentFaceComparePrompt = this.faceComparePrompts[0];
        this.promptingForPersonId = this.currentFaceComparePrompt.personFace.person.id;
        this.zoomToFace();
    };
    /**
     * Close the dialog
     */
    FaceCompareComponent.prototype.closeDialog = function () {
        if (this.closeCallback) {
            this.closeCallback.emit();
        }
    };
    /**
     * Move to the next prompt in the list
     */
    FaceCompareComponent.prototype.moveToNextFace = function () {
        // Get a new array from FaceComparePromptService (if it exists)
        // This helps us adapt if a single answer results in new/different FaceComparePrompts
        this.faceComparePrompts = this.faceComparePromptService.getFaceComparePromptsForPerson(this.promptingForPersonId);
        // Find the next, unanswered faceComparePrompt
        for (var i = 0; i < this.faceComparePrompts.length; i++) {
            var nextFaceComparePrompt = this.faceComparePrompts[i];
            if (!nextFaceComparePrompt.answeredLocally && (nextFaceComparePrompt.id !== this.currentFaceComparePrompt.id)) {
                this.moveToSpecificFace(nextFaceComparePrompt);
                return;
            }
        }
        // We've reached the end. Close this dialog
        this.closeDialog();
    };
    FaceCompareComponent.prototype.moveToSpecificFace = function (nextFace) {
        this.currentFaceComparePrompt = nextFace;
        this.animateFaceSliding();
        this.zoomToFace();
    };
    FaceCompareComponent.prototype.zoomToFace = function () {
        /*
    
        ** Skip this for now **
    
        // Make sure the source image is centered/zoomed around the newFaceId
        if (!this.currentFaceComparePrompt.newFace.photo || !this.currentFaceComparePrompt.newFace.photo.faces) {
          return;
        }
        this.currentFaceComparePrompt.newFace.photo.faces.forEach((face) => {
          if (face.faceId === this.currentFaceComparePrompt.newFace.faceId) {
            console.log('face.coordinates: ', face.coordinates);
            const percentX = (face.coordinates.left + face.coordinates.width / 2.0) * 100;
            const percentY = (face.coordinates.top + face.coordinates.height / 2.0) * 100;
    
            console.log('percentX: ', percentX);
            console.log('percentY: ', percentY);
    
            setTimeout(() => {
              $('.face-compare-top').css({
                backgroundPositionX: '-' + percentX + '%',
                backgroundPositionY: '-' + percentY + '%'
              });
            }, 2000);
          }
        });
        */
        return;
    };
    FaceCompareComponent.prototype.confirmFace = function () {
        var response = this.faceComparePromptService.FACE_COMPARE_PROMPT_RESPONSE.MATCH;
        this.respondToFace(this.currentFaceComparePrompt, response);
    };
    FaceCompareComponent.prototype.rejectFace = function () {
        var response = this.faceComparePromptService.FACE_COMPARE_PROMPT_RESPONSE.NOT_MATCH;
        this.respondToFace(this.currentFaceComparePrompt, response);
    };
    FaceCompareComponent.prototype.skipFace = function () {
        var response = this.faceComparePromptService.FACE_COMPARE_PROMPT_RESPONSE.SKIP;
        this.respondToFace(this.currentFaceComparePrompt, response);
    };
    FaceCompareComponent.prototype.animateFaceSliding = function () {
        var _this = this;
        // Slide away right
        $(this.FACE_THUMBNAIL_HOLDER_SELECTOR).css({
            marginLeft: this.FACE_SLIDE_ANIMATION_DISTANCE
        });
        setTimeout(function () {
            // Disable the transition and slide them way-left again
            $(_this.FACE_THUMBNAIL_HOLDER_SELECTOR).css({
                transition: 'none'
            });
            $(_this.FACE_THUMBNAIL_HOLDER_SELECTOR).css({
                marginLeft: '-' + _this.FACE_SLIDE_ANIMATION_DISTANCE
            });
            // Re-enable the animation and animate it in.
            setTimeout(function () {
                $(_this.FACE_THUMBNAIL_HOLDER_SELECTOR).css({
                    transition: 'margin-left ' + _this.FACE_SLIDE_ANIMATION_DURATION
                });
                $(_this.FACE_THUMBNAIL_HOLDER_SELECTOR).css({
                    marginLeft: '0px'
                });
            });
        }, 600);
    };
    /**
     * Handle the user's input and then advance to the next prompt (if any)
     */
    FaceCompareComponent.prototype.respondToFace = function (faceComparePrompt, response) {
        var self = this;
        var faceComparePromptId = faceComparePrompt.id;
        faceComparePrompt.answeredLocally = true;
        this.isProcessing = true;
        this.faceComparePromptService.respondToFaceComparePrompt(faceComparePromptId, response).then(function () {
            console.log('Successfully matched face');
        }, function (err) {
            console.error('Error responding to face: ', err);
        }).finally(function () {
            console.log('response processed. Waiting...');
            // Artificial delay...
            self.moveToNextFace();
            self.isProcessing = false;
        });
    };
    return FaceCompareComponent;
}());
export { FaceCompareComponent };
// Provide a downgraded version of this to run with AngularJS
angular.module('KintributeApp')
    .directive('faceCompareComponent', downgradeComponent({
    component: FaceCompareComponent,
    inputs: []
}));
