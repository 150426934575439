/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./timeline-supplement-story.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./timeline-supplement-story.component";
var styles_TimelineSupplementStoryComponent = [i0.styles];
var RenderType_TimelineSupplementStoryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TimelineSupplementStoryComponent, data: {} });
export { RenderType_TimelineSupplementStoryComponent as RenderType_TimelineSupplementStoryComponent };
export function View_TimelineSupplementStoryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "timeline-supplement-story"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.supplement.text; _ck(_v, 1, 0, currVal_0); }); }
export function View_TimelineSupplementStoryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-timeline-supplement-story", [], null, null, null, View_TimelineSupplementStoryComponent_0, RenderType_TimelineSupplementStoryComponent)), i1.ɵdid(1, 114688, null, 0, i2.TimelineSupplementStoryComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TimelineSupplementStoryComponentNgFactory = i1.ɵccf("app-timeline-supplement-story", i2.TimelineSupplementStoryComponent, View_TimelineSupplementStoryComponent_Host_0, { story: "story", supplement: "supplement" }, {}, []);
export { TimelineSupplementStoryComponentNgFactory as TimelineSupplementStoryComponentNgFactory };
