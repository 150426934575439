<div class="personal-page-text-row"
     [ngClass]="{'personal-page-text-row-can-edit': editor.userCanEdit()}">

  <!-- Headline -->
  <div class="personal-page-text-headline"
       [ngClass]="{'personal-page-text-headline-editor': editor.isEditMode}">

    <div *ngIf="!editor.isEditMode" 
         class="personal-page-hoverable-text"
         (click)="clickedHeadlineField()">
        {{row.headline}}
    </div>
    <div *ngIf="editor.isEditMode">
      <textarea [(ngModel)]="row.headline" 
                class="personal-page-text-row-textarea"
                rows="1">
      </textarea>
    </div>
    
  </div>

  <!-- Normal Text -->
  <div class="personal-page-text-text">
    <div *ngIf="!editor.isEditMode" 
         class="personal-page-hoverable-text"
         (click)="clickedTextField()">
      {{row.text}}
    </div>
    <div *ngIf="editor.isEditMode">
      <textarea [(ngModel)]="row.text" 
                class="personal-page-text-row-textarea"
                rows="5">
      </textarea>
    </div>
  </div>

</div>