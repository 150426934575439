import { Directive, ElementRef, Injector, Input, Output } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
import { PhotoEssayTile } from 'src/app/PhotoEssay/photo-essay';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: 'photo-essay-tile'
})
export class PhotoEssayTileDirective extends UpgradeComponent {

    @Input('editor') editor: any;
    @Input('tile') tile: any;

    constructor(elementRef: ElementRef, injector: Injector) {
        console.log('photo-essay-tile constructor!');
        super('photoEssayTile', elementRef, injector);
    }
}
