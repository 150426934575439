import { PersonalPageEditor } from 'src/app/PersonalPage/personal-page-editor';
import { downgradeInjectable } from '@angular/upgrade/static';
import { KintLocation } from 'src/app/KintObject/kint-location';
import { KintTimeline } from 'src/app/Timeline/kint-timeline';
import { Meta } from '@angular/platform-browser';
import * as i0 from "@angular/core";
import * as i1 from "@angular/platform-browser";
/*
 *************************************************************************************
 *
 * The AngularJS portion of this app doesn't have access to our typescript classes
 * In order to give them access, we expose this BuilderService which can be used
 * as a factory to build our Typescript objects
 *
 * This is purely used to build objects, and is only needed for the AngularJS interop
 *
 *************************************************************************************
 */
var BuilderService = /** @class */ (function () {
    function BuilderService(apiService, meta) {
        this.apiService = apiService;
        this.meta = meta;
    }
    /**
     * Builds a Personal Page Editor
     */
    BuilderService.prototype.buildPersonalPageEditor = function () {
        var editor = new PersonalPageEditor();
        editor.setApiService(this.apiService);
        return editor;
    };
    /**
     * Builds a KintLocation object
     */
    BuilderService.prototype.buildKintLocation = function (name) {
        return new KintLocation(name);
    };
    /**
     * Builds a KintTimeline from a Collection
     */
    BuilderService.prototype.buildKintTimeline = function (collection) {
        return new KintTimeline(collection);
    };
    BuilderService.prototype.addMetaTagsForPhoto = function (photo, url) {
        var title = 'Kintribute Photo';
        var description = '';
        var image = '';
        if (photo) {
            if (photo.story) {
                description = photo.story;
            }
            if (photo.link) {
                image = photo.link;
            }
        }
        this.addMetaTags(title, description, image, url);
    };
    BuilderService.prototype.addMetaTags = function (title, description, image, url) {
        this.meta.addTag({ name: 'title', content: title });
        this.meta.addTag({ name: 'og:title', content: title });
        this.meta.addTag({ name: 'description', content: description });
        this.meta.addTag({ name: 'og:description', content: description });
        this.meta.addTag({ name: 'image', content: image });
        this.meta.addTag({ name: 'og:image', content: image });
        this.meta.addTag({ name: 'url', content: url });
        this.meta.addTag({ name: 'og:url', content: url });
    };
    BuilderService.ngInjectableDef = i0.defineInjectable({ factory: function BuilderService_Factory() { return new BuilderService(i0.inject("APIService"), i0.inject(i1.Meta)); }, token: BuilderService, providedIn: "root" });
    return BuilderService;
}());
export { BuilderService };
// Register an AngularJS service, whose value is the "downgraded" Angular injectable.
angular.module('KintributeApp')
    .factory('BuilderService', downgradeInjectable(BuilderService));
