import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor() {
  }

  /**
   * Perform a deep-copy of any Javascript object
   *
   * @param obj Any Javascript object that we want to copy
   */

  deepCopy(obj: any): any {
    return this.deepClone(obj);
    /*
    return JSON.parse(JSON.stringify(obj));
    */
  }

  deepClone(obj): any {

    // return value is input is not an Object or Array.
    if (typeof(obj) !== 'object' || obj === null) {
      return obj;
    }

    let clone;

    if (Array.isArray(obj)) {
      clone = obj.slice();  // unlink Array reference.
    } else {
      clone = Object.assign({}, obj); // Unlink Object reference.
    }

    const keys = Object.keys(clone);

    for (let i = 0; i < keys.length; i++) {
      clone[keys[i]] = this.deepClone(clone[keys[i]]); // recursively unlink reference to nested objects.
    }

    return clone; // return unlinked clone.

  }


  /**
   * Splits comma-separated terms into an array of terms
   *
   * @param   commaSeparatedTerms   list of comma-separated terms
   *
   * @return  [terms]
   */
  splitTerms(commaSeparatedTerms: string): string[] {
    const filteredTermList = [];

    const termList = commaSeparatedTerms.trim().split(/[,\n]+/);
    for (let i = 0; i < termList.length; i++) {
      const term = termList[i].trim();
      if (term !== '') {
        filteredTermList.push(term);
      }
    }

    return filteredTermList;
  }

}
