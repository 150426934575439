/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./timeline-layout-vertical.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../timeline-block-vertical/timeline-block-vertical.component.ngfactory";
import * as i3 from "../timeline-block-vertical/timeline-block-vertical.component";
import * as i4 from "@angular/common";
import * as i5 from "./timeline-layout-vertical.component";
var styles_TimelineLayoutVerticalComponent = [i0.styles];
var RenderType_TimelineLayoutVerticalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TimelineLayoutVerticalComponent, data: {} });
export { RenderType_TimelineLayoutVerticalComponent as RenderType_TimelineLayoutVerticalComponent };
function View_TimelineLayoutVerticalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-timeline-block-vertical", [], null, null, null, i2.View_TimelineBlockVerticalComponent_0, i2.RenderType_TimelineBlockVerticalComponent)), i1.ɵdid(2, 114688, null, 0, i3.TimelineBlockVerticalComponent, ["AppDataModelService"], { timelineBlock: [0, "timelineBlock"], timeline: [1, "timeline"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.timeline; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_TimelineLayoutVerticalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "timeline-layout-vertical-undated-photos"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" + ", " Undated Photos "])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "clearfix"]], null, null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.timeline.undatedPhotos.length; _ck(_v, 2, 0, currVal_0); }); }
export function View_TimelineLayoutVerticalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "timeline-layout-vertical"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i4.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "timeline-layout-vertical-mobile": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimelineLayoutVerticalComponent_1)), i1.ɵdid(4, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimelineLayoutVerticalComponent_2)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "timeline-layout-vertical"; var currVal_1 = _ck(_v, 2, 0, _co.isMobile()); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.timeline.timelineBlocks; _ck(_v, 4, 0, currVal_2); var currVal_3 = (_co.timeline.undatedPhotos.length > 0); _ck(_v, 6, 0, currVal_3); }, null); }
export function View_TimelineLayoutVerticalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-timeline-layout-vertical", [], null, null, null, View_TimelineLayoutVerticalComponent_0, RenderType_TimelineLayoutVerticalComponent)), i1.ɵdid(1, 114688, null, 0, i5.TimelineLayoutVerticalComponent, ["AppDataModelService"], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TimelineLayoutVerticalComponentNgFactory = i1.ɵccf("app-timeline-layout-vertical", i5.TimelineLayoutVerticalComponent, View_TimelineLayoutVerticalComponent_Host_0, { timeline: "timeline" }, {}, []);
export { TimelineLayoutVerticalComponentNgFactory as TimelineLayoutVerticalComponentNgFactory };
