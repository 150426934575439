import { KintPhoto } from '../KintObject/kint-photo';
import { DateHelper } from '../Util/Date/DateHelper';
import { KintTimelineEntry } from './kint-timeline-entry';

/**
 * This is a block of time for the Timeline. This includes a header and
 * dates within that range
 /**
  *
  *
  * @export
  * @class KintTimelineBlock
  */
 export class KintTimelineBlock {

  // Instance Members
  header = '';
  timelineFormat = null;
  timelineBlocks: KintTimelineBlock[];
  timelineEntries: KintTimelineEntry[];
  startIsoDate: string;
  endIsoDate: string;
  level: number;


  /**
   * Constructor
   */
  constructor(header: string,
              timelineFormat: KintTimelineFormat,
              startDateIsoString: string,
              endDateIsoString: string,
              sortedPhotos: KintPhoto[],
              level: number) {

    this.header = header;
    this.timelineFormat = timelineFormat;
    this.level = level;

    this.timelineBlocks = [];
    this.timelineEntries = [];

    /*
    if (sortedPhotos.length === 0) {
      console.error('Error building KintTimelineBlock. Insufficient photos');
      return;
    }

    this.startIsoDate = sortedPhotos[0].date.isoDate;
    this.endIsoDate = sortedPhotos[sortedPhotos.length - 1].date.isoDate;
    const startDate = new Date(this.startIsoDate);
    const endDate = new Date(this.endIsoDate);
    */

    const startDate = new Date(startDateIsoString);
    const endDate = new Date(endDateIsoString);

    // If we are done creating our "blocks", then just add the photos as timeline entries
    if (level >= timelineFormat.timelineBlockTypes.length) {
      sortedPhotos.forEach( (photo) => {
        this.timelineEntries.push( new KintTimelineEntry(photo) );
      });
    }

    // If we have more "blocks" to build, build them now (photos will go inside them)
    if (level < timelineFormat.timelineBlockTypes.length) {
      const timelineBlockToBuild = timelineFormat.timelineBlockTypes[level];
      this.timelineBlocks = KintTimelineBlocksBuilder.buildTimelineBlocks(timelineBlockToBuild, startDate, endDate,
                                                                          sortedPhotos, timelineFormat, level);
    }

  }




  /**
   * Adds a TimelineBlock to the given block
   *
   * @param {KintTimelineBlock} timelineBlock
   * @memberof KintTimelineBlock
   */
  addTimelineBlock(timelineBlock: KintTimelineBlock): void {
    this.timelineBlocks.push(timelineBlock);
  }


  /**
   * Adds a TimelineEntry to the given block
   *
   * @param {KintTimelineEntry} timelineEntry
   * @memberof KintTimelineBlock
   */
  addTimelineEntry(timelineEntry: KintTimelineEntry): void {

    // See if this timeline belongs in a sub-block or if this is the
    // best place to put it
    let addedToSubBlock = false;
    this.timelineBlocks.forEach((timelineBlock) => {
      if (timelineBlock.isEntryInThisBlock(timelineEntry)) {
        timelineBlock.addTimelineEntry(timelineEntry);
        addedToSubBlock = true;
      }
    });

    // If we didn't add it to a sub-block, add it as a top-level entry to this block
    if (!addedToSubBlock) {
      this.timelineEntries.push(timelineEntry);
    }
  }


  /**
   * Checks t see if the given timeline entry belongs in the given block or no
   */
  isEntryInThisBlock(timelineEntry: KintTimelineEntry): boolean {
    if ((timelineEntry.photo.date.isoDate <= this.startIsoDate) &&
        (timelineEntry.photo.date.isoDate <= this.endIsoDate)) {
      return true;
    }

    return false;
  }

 }



export enum KintTimelineBlockType {
  MASTER = 'MASTER',
  DECADE = 'DECADE',
  YEAR = 'YEAR',
  MONTH = 'MONTH',
  WEEK = 'WEEK',
  DAY = 'DAY',
  TIME_OF_DAY = 'TIME_OF_DAY',
  TIME = 'TIME'
}



export class KintTimelineBlocksBuilder {

  // Static Members
  static ONE_DAY = 24 * 60 * 60 * 1000;
  static FIVE_DAYS = KintTimelineBlocksBuilder.ONE_DAY * 5;
  static ONE_WEEK = KintTimelineBlocksBuilder.ONE_DAY * 7;
  static ONE_MONTH = KintTimelineBlocksBuilder.ONE_DAY * 30;
  static ONE_YEAR = 365 * KintTimelineBlocksBuilder.ONE_DAY;



  /**
   * Build timeline blocks of the specific type
   *
   * @param {Date} startDate
   * @param {Date} endDate
   * @param {KintPhoto[]} sortedPhotos
   * @param {number} level
   * @returns {KintTimelineBlock[]}
   * @memberof KintTimelineBlock
   */
  static buildTimelineBlocks(timelineBlockType: KintTimelineBlockType, startDate: Date, endDate: Date, sortedPhotos: KintPhoto[],
                             timelineFormat: KintTimelineFormat, level: number): KintTimelineBlock[] {

    let timelineBlocks = [];
    switch (timelineBlockType) {
      case KintTimelineBlockType.DECADE: {
        timelineBlocks = KintTimelineBlocksBuilder.buildDecadeBlocks(startDate, endDate, sortedPhotos, timelineFormat, level);
        break;
      }
      case KintTimelineBlockType.YEAR: {
        timelineBlocks = KintTimelineBlocksBuilder.buildYearBlocks(startDate, endDate, sortedPhotos, timelineFormat, level);
        break;
      }
      case KintTimelineBlockType.MONTH: {
        timelineBlocks = KintTimelineBlocksBuilder.buildMonthBlocks(startDate, endDate, sortedPhotos, timelineFormat, level);
        break;
      }
      case KintTimelineBlockType.WEEK: {
        timelineBlocks = KintTimelineBlocksBuilder.buildWeekBlocks(startDate, endDate, sortedPhotos, timelineFormat, level);
        break;
      }
      case KintTimelineBlockType.DAY: {
        timelineBlocks = KintTimelineBlocksBuilder.buildDayBlocks(startDate, endDate, sortedPhotos, timelineFormat, level);
        break;
      }
      case KintTimelineBlockType.TIME_OF_DAY: {
        timelineBlocks = KintTimelineBlocksBuilder.buildTimeOfDayBlocks(startDate, endDate, sortedPhotos, timelineFormat, level);
        break;
      }
    }
    return timelineBlocks;
  }


  /**
   * Build decade-level blocks
   *
   * @param {Date} startDate
   * @param {Date} endDate
   * @param {KintPhoto[]} sortedPhotos
   * @param {number} level
   * @returns {KintTimelineBlock[]}
   * @memberof KintTimelineBlock
   */
  static buildDecadeBlocks(startDate: Date, endDate: Date, sortedPhotos: KintPhoto[],
                           timelineFormat: KintTimelineFormat, level: number): KintTimelineBlock[] {

    const generatedTimlineBlocks = [];

    let decadeStart = Math.floor(startDate.getFullYear() / 10) * 10;
    let decadeEnd = decadeStart + 10;

    while (decadeStart <= endDate.getFullYear()) {

      // Build a new Decade block type with all of our photos within this time span
      const decadeStartString = '' + decadeStart; // Need string value for the "Date" constructor
      const decadeEndString = '' + decadeEnd;

      // const decadeStartIsoDate = new Date(decadeStartString).toISOString();
      const decadeStartIsoDate = DateHelper.buildUnbiasZeroBasedISODateString(decadeStart, 0, 0);
      const decadeEndIsoDate = DateHelper.buildUnbiasZeroBasedISODateString(decadeEnd, 0, 0);

      const photosInDecade = this.getPhotosInTimeRange(decadeStartIsoDate, decadeEndIsoDate, sortedPhotos);
      if (photosInDecade.length > 0) {

        // Build the decade with these photos
        const header = '' + decadeStart + ' - ' + decadeEnd;
        console.log('Building decade: ' + header + ' with ' + photosInDecade.length + ' photos');
        if (decadeStart === 2000) {
          console.log('breaking...');
        }

        const kintTimelineDecadeBlock = new KintTimelineBlock(
          header,
          timelineFormat,
          decadeStartIsoDate,
          decadeEndIsoDate,
          photosInDecade,
          level + 1
        );
        generatedTimlineBlocks.push(kintTimelineDecadeBlock);

      }

      // See if we have another one:
      decadeStart += 10;
      decadeEnd += 10;
    }

    return generatedTimlineBlocks;
  }


  /**
   * Build year-level blocks
   *
   * @param {Date} startDate
   * @param {Date} endDate
   * @param {KintPhoto[]} sortedPhotos
   * @param {number} level
   * @returns {KintTimelineBlock[]}
   * @memberof KintTimelineBlock
   */
  static buildYearBlocks(startDate: Date, endDate: Date, sortedPhotos: KintPhoto[],
                         timelineFormat: KintTimelineFormat, level: number): KintTimelineBlock[] {

    const generatedTimlineBlocks = [];

    let yearStart = startDate.getFullYear();
    let nextYear = yearStart + 1;

    while (yearStart <= endDate.getFullYear()) {

      // Build a new Year block type with all of our photos within this time span
      const yearStartIsoDate = DateHelper.buildUnbiasZeroBasedISODateString(yearStart, 0, 0); // new Date(yearStartString).toISOString();
      const yearEndIsoDate = DateHelper.buildUnbiasZeroBasedISODateString(nextYear, 0, 0); // new Date(nextYearString).toISOString();

      const photosInYear = this.getPhotosInTimeRange(yearStartIsoDate, yearEndIsoDate, sortedPhotos);
      if (photosInYear.length > 0) {

        // Build the Year with these photos
        const header = '' + yearStart;
        const kintTimelineDecadeBlock = new KintTimelineBlock(
          header,
          timelineFormat,
          yearStartIsoDate,
          yearEndIsoDate,
          photosInYear,
          level + 1
        );
        generatedTimlineBlocks.push(kintTimelineDecadeBlock);
      }

      // See if we have another year:
      yearStart += 1;
      nextYear += 1;
    }

    return generatedTimlineBlocks;
  }


  /**
   * Build month-level blocks
   *
   * @param {Date} startDate
   * @param {Date} endDate
   * @param {KintPhoto[]} sortedPhotos
   * @param {number} level
   * @returns {KintTimelineBlock[]}
   * @memberof KintTimelineBlock
   */
  static buildMonthBlocks(startDate: Date, endDate: Date, sortedPhotos: KintPhoto[],
                          timelineFormat: KintTimelineFormat, level: number): KintTimelineBlock[] {

    const generatedTimlineBlocks = [];

    const year = startDate.getFullYear();
    let monthStart = startDate.getMonth();
    let nextMonth = monthStart + 1;

    const endDateString = endDate.toISOString();

    let monthStartDateString = DateHelper.buildUnbiasZeroBasedISODateString(year, monthStart, 0);
    let monthEndDateString = DateHelper.buildUnbiasZeroBasedISODateString(year, monthStart + 1, 0);

    while (monthStartDateString <= endDateString) {

      // Build a new Decade block type with all of our photos within this time span
      const photosInMonth = this.getPhotosInTimeRange(monthStartDateString, monthEndDateString, sortedPhotos);
      if (photosInMonth.length > 0) {

        // Build the Month with these photos
        // Header is either "AUGUST" or "AUG"
        let header = '';
        if (level <= 3) {
          // full month name
          header =  DateHelper.getLongMonth(new Date(monthStartDateString));
        } else {
          // Abbreviated
          header =  DateHelper.getShortMonth(new Date(monthStartDateString));
        }

        const kintTimelineDecadeBlock = new KintTimelineBlock(
          header,
          timelineFormat,
          monthStartDateString,
          monthEndDateString,
          photosInMonth,
          level + 1
        );
        generatedTimlineBlocks.push(kintTimelineDecadeBlock);
      }

      // See if we have another year:
      monthStart += 1;
      nextMonth += 1;

      monthStartDateString = DateHelper.buildUnbiasZeroBasedISODateString(year, monthStart, 0);
      monthEndDateString = DateHelper.buildUnbiasZeroBasedISODateString(year, nextMonth, 0);
    }

    return generatedTimlineBlocks;
  }

  /**
   * Build week-level blocks
   *
   * @param {Date} startDate
   * @param {Date} endDate
   * @param {KintPhoto[]} sortedPhotos
   * @param {number} level
   * @returns {KintTimelineBlock[]}
   * @memberof KintTimelineBlock
   */
  static buildWeekBlocks(startDate: Date, endDate: Date, sortedPhotos: KintPhoto[],
                         timelineFormat: KintTimelineFormat, level: number): KintTimelineBlock[] {

    const generatedTimlineBlocks = [];

    const startIsoDate = startDate.toISOString();
    const endIsoDate = endDate.toISOString();

    const photosInMonth = this.getPhotosInTimeRange(startIsoDate, endIsoDate, sortedPhotos);
    if (photosInMonth.length > 0) {

      // Header is "AUG 20 - AUG 24, 2018"
      const startMonthName = DateHelper.getShortMonth(startDate);
      const endMonthName = DateHelper.getShortMonth(endDate);
      const header = startMonthName + ' ' + startDate.getDate() + ' - ' + endMonthName + ' ' + endDate.getDate();
      const kintTimelineDecadeBlock = new KintTimelineBlock(
        header,
        timelineFormat,
        startIsoDate,
        endIsoDate,
        photosInMonth,
        level + 1
      );
      generatedTimlineBlocks.push(kintTimelineDecadeBlock);
    }

    return generatedTimlineBlocks;
  }


  /**
   * Build month-level blocks
   *
   * @param {Date} startDate
   * @param {Date} endDate
   * @param {KintPhoto[]} sortedPhotos
   * @param {number} level
   * @returns {KintTimelineBlock[]}
   * @memberof KintTimelineBlock
   */
  static buildDayBlocks(startDate: Date, endDate: Date, sortedPhotos: KintPhoto[],
                        timelineFormat: KintTimelineFormat, level: number): KintTimelineBlock[] {

    const generatedTimlineBlocks = [];

    const currentDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());

    while (currentDate <= endDate) {

      // Build a new Day block type with all of our photos within this time span
      const dayStartIsoDate = currentDate.toISOString();
      const dayEndIsoDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(),
                                     23, 59, 59, 999).toISOString();

      const photosInMonth = this.getPhotosInTimeRange(dayStartIsoDate, dayEndIsoDate, sortedPhotos);
      if (photosInMonth.length > 0) {

        // Build the Month with these photos
        // Header is either "AUGUST" or "AUG"
        let header = '';
        if (level <= 3) {
          // full month name
          header =  DateHelper.getLongMonth(currentDate);
        } else {
          // Abbreviated
          header =  DateHelper.getShortMonth(currentDate);
        }
        header = header + ' ' + currentDate.getDate();

        const kintTimelineDecadeBlock = new KintTimelineBlock(
          header,
          timelineFormat,
          dayStartIsoDate,
          dayEndIsoDate,
          photosInMonth,
          level + 1
        );
        generatedTimlineBlocks.push(kintTimelineDecadeBlock);
      }

      // See if we have another day
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return generatedTimlineBlocks;
  }



  /**
   * Build month-level blocks
   *
   * @param {Date} startDate
   * @param {Date} endDate
   * @param {KintPhoto[]} sortedPhotos
   * @param {number} level
   * @returns {KintTimelineBlock[]}
   * @memberof KintTimelineBlock
   */
  static buildTimeOfDayBlocks(startDate: Date, endDate: Date, sortedPhotos: KintPhoto[],
                              timelineFormat: KintTimelineFormat, level: number): KintTimelineBlock[] {

    const generatedTimlineBlocks = [];

    //
    // Build fixed time-of-day blocks:
    //   - EARLY MORNING (12am-5am)
    //   - MORNING (5:01am-10am)
    //   - MID_MORNING (10:01am-12pm)
    //   - AFTERNOON (12:01pm-5pm)
    //   - EVENING (5:01pm-10pm)
    //   - TWILIGHT (10:01pm-11:59pm)
    //

    // Early Morning
    let timeOfDayStart = DateHelper.getTimeOnDate(startDate, 0, 0, false);
    let timeOfDayEnd = DateHelper.getTimeOnDate(startDate, 5, 0, true);
    let photosInTimeOfDay = KintTimelineBlocksBuilder.getPhotosInTimeRange(
      timeOfDayStart.toISOString(), timeOfDayEnd.toISOString(), sortedPhotos);

    if (photosInTimeOfDay.length > 0) {
      const kintTimelineDecadeBlock = new KintTimelineBlock(
        'Early Morning',
        timelineFormat,
        timeOfDayStart.toISOString(),
        timeOfDayEnd.toISOString(),
        photosInTimeOfDay,
        level + 1
      );
      generatedTimlineBlocks.push(kintTimelineDecadeBlock);
    }

    // Morning
    timeOfDayStart = DateHelper.getTimeOnDate(startDate, 5, 1, false);
    timeOfDayEnd = DateHelper.getTimeOnDate(startDate, 10, 0, true);
    photosInTimeOfDay = KintTimelineBlocksBuilder.getPhotosInTimeRange(
      timeOfDayStart.toISOString(), timeOfDayEnd.toISOString(), sortedPhotos);

    if (photosInTimeOfDay.length > 0) {
      const kintTimelineDecadeBlock = new KintTimelineBlock(
        'Morning',
        timelineFormat,
        timeOfDayStart.toISOString(),
        timeOfDayEnd.toISOString(),
        photosInTimeOfDay,
        level + 1
      );
      generatedTimlineBlocks.push(kintTimelineDecadeBlock);
    }

    // MID_MORNING
    timeOfDayStart = DateHelper.getTimeOnDate(startDate, 10, 1, false);
    timeOfDayEnd = DateHelper.getTimeOnDate(startDate, 12, 0, true);
    photosInTimeOfDay = KintTimelineBlocksBuilder.getPhotosInTimeRange(
      timeOfDayStart.toISOString(), timeOfDayEnd.toISOString(), sortedPhotos);

    if (photosInTimeOfDay.length > 0) {
      const kintTimelineDecadeBlock = new KintTimelineBlock(
        'Mid Morning',
        timelineFormat,
        timeOfDayStart.toISOString(),
        timeOfDayEnd.toISOString(),
        photosInTimeOfDay,
        level + 1
      );
      generatedTimlineBlocks.push(kintTimelineDecadeBlock);
    }

    // AFTERNOON
    timeOfDayStart = DateHelper.getTimeOnDate(startDate, 12, 1, false);
    timeOfDayEnd = DateHelper.getTimeOnDate(startDate, 17, 0, true);
    photosInTimeOfDay = KintTimelineBlocksBuilder.getPhotosInTimeRange(
      timeOfDayStart.toISOString(), timeOfDayEnd.toISOString(), sortedPhotos);

    if (photosInTimeOfDay.length > 0) {
      const kintTimelineDecadeBlock = new KintTimelineBlock(
        'Afternoon',
        timelineFormat,
        timeOfDayStart.toISOString(),
        timeOfDayEnd.toISOString(),
        photosInTimeOfDay,
        level + 1
      );
      generatedTimlineBlocks.push(kintTimelineDecadeBlock);
    }

    // Evening
    timeOfDayStart = DateHelper.getTimeOnDate(startDate, 17, 1, false);
    timeOfDayEnd = DateHelper.getTimeOnDate(startDate, 22, 0, true);
    photosInTimeOfDay = KintTimelineBlocksBuilder.getPhotosInTimeRange(
      timeOfDayStart.toISOString(), timeOfDayEnd.toISOString(), sortedPhotos);

    if (photosInTimeOfDay.length > 0) {
      const kintTimelineDecadeBlock = new KintTimelineBlock(
        'Evening',
        timelineFormat,
        timeOfDayStart.toISOString(),
        timeOfDayEnd.toISOString(),
        photosInTimeOfDay,
        level + 1
      );
      generatedTimlineBlocks.push(kintTimelineDecadeBlock);
    }

    // TWILIGHT
    timeOfDayStart = DateHelper.getTimeOnDate(startDate, 22, 1, false);
    timeOfDayEnd = DateHelper.getTimeOnDate(startDate, 23, 59, true);
    photosInTimeOfDay = KintTimelineBlocksBuilder.getPhotosInTimeRange(
      timeOfDayStart.toISOString(), timeOfDayEnd.toISOString(), sortedPhotos);

    if (photosInTimeOfDay.length > 0) {
      const kintTimelineDecadeBlock = new KintTimelineBlock(
        'Twighlight',
        timelineFormat,
        timeOfDayStart.toISOString(),
        timeOfDayEnd.toISOString(),
        photosInTimeOfDay,
        level + 1
      );
      generatedTimlineBlocks.push(kintTimelineDecadeBlock);
    }

    return generatedTimlineBlocks;
  }

  /**
   * Get photos within the current date range
   *
   * @param {string} startIsoDate
   * @param {string} endIsoDate
   * @param {KintPhoto[]} sortedPhotos
   * @returns
   * @memberof KintTimelineBlock
   */
  static getPhotosInTimeRange(onOrAfterStartDateIsoString: string, beforeEndDateIsoString: string, sortedPhotos: KintPhoto[]) {
    const photosInRange = [];
    const includeEndDate = false;
    sortedPhotos.forEach( (photo) => {
      if (DateHelper.isPhotoWithinRange(photo, onOrAfterStartDateIsoString, beforeEndDateIsoString, includeEndDate)) {
        photosInRange.push(photo);
      }
    });
    return photosInRange;
  }
}


export class KintTimelineFormat {

  timelineType: KintTimelineType;
  timelineBlockTypes: KintTimelineBlockType[];
  lowestLevelIsTime: boolean;

  constructor(timelineType: KintTimelineType) {
    this.timelineType = timelineType;
    this.lowestLevelIsTime = false;

    switch (this.timelineType) {
      case KintTimelineType.DECADE_YEAR_DAY: {
        this.timelineBlockTypes = [
          KintTimelineBlockType.DECADE,
          KintTimelineBlockType.YEAR
        ];
        this.lowestLevelIsTime = false;
        break;
      }

      case KintTimelineType.YEAR_MONTH_DAY: {
        this.timelineBlockTypes = [
          KintTimelineBlockType.YEAR,
          KintTimelineBlockType.MONTH
        ];
        this.lowestLevelIsTime = false;
        break;
      }

      case KintTimelineType.MONTH_DAY_TIME: {
        this.timelineBlockTypes = [
          KintTimelineBlockType.MONTH,
          KintTimelineBlockType.DAY
        ];
        this.lowestLevelIsTime = false;
        break;
      }

      case KintTimelineType.WEEK_DAY_TIME: {
        this.timelineBlockTypes = [
          KintTimelineBlockType.WEEK,
          KintTimelineBlockType.DAY
        ];
        this.lowestLevelIsTime = false;
        break;
      }

      case KintTimelineType.DAY_TIMEOFDAY_TIME: {
        this.timelineBlockTypes = [
          KintTimelineBlockType.DAY,
          KintTimelineBlockType.TIME_OF_DAY
        ];
        this.lowestLevelIsTime = true;
        break;
      }

      default: {
        console.error('Unknown timeline type: ' + this.timelineType);
        this.timelineBlockTypes = [
          KintTimelineBlockType.DAY,
          KintTimelineBlockType.TIME_OF_DAY
        ];
        this.lowestLevelIsTime = true;
        break;
      }
    }
  }

}

export enum KintTimelineType {
  DECADE_YEAR_DAY = 'DECADE_YEAR_DAY',
  YEAR_MONTH_DAY = 'YEAR_MONTH_DAY',
  MONTH_DAY_TIME = 'MONTH_DAY_TIME',
  WEEK_DAY_TIME = 'WEEK_DAY_TIME',
  DAY_TIMEOFDAY_TIME = 'DAY_TIMEOFDAY_TIME'
}
