import { Injectable } from '@angular/core';
import { PhotoEssay, PhotoEssayRow, PhotoEssayRowTypes, PhotoEssayTileTypes, PhotoEssayTile } from '../photo-essay';
import { UtilService } from 'src/app/Util/util/util.service';
import { KintPhoto } from 'src/app/KintObject/kint-photo';
import { downgradeInjectable } from '@angular/upgrade/static';
import { LogService } from 'src/app/Log/log.service';

// AngularJS is loaded separately
declare var angular: any;


@Injectable({
  providedIn: 'root'
})
export class PhotoEssayLayoutService {


  /**
   * Creates an instance of PhotoEssayLayoutService.
   *
   * @param {UtilService} utilService
   * @param {LogService} logService
   * @memberof PhotoEssayLayoutService
   */
  constructor(private utilService: UtilService,
              private logService: LogService) {
  }


  /**
   * Builds a default Photo Essay object from a bunch of photos
   * Note: Each photo can have an optional ".groupNext" boolean which tells the system
   * that the photo should ideally be grouped with the next item in the list
   *
   * @param originalPhotos    List of all photos that we want to include
   * @param enableAutoGrouping Whether or not we want to allow the system to group photos for us
   */
   buildPhotoEssay(originalPhotos: KintPhoto[], enableAutoGrouping: boolean): PhotoEssay {
    const photoEssay: PhotoEssay = new PhotoEssay();
    const rows: PhotoEssayRow[] = [];
    const photos: any[] = this.utilService.deepCopy(originalPhotos);

    // Always Lead with a caption
    rows.push(this.buildCaptionRow(''));

    let lastRowType = PhotoEssayRowTypes.CAPTION;
    while (photos.length > 0) {

      // Build the new row and a caption to go after it
      const photoEssayRow = this.getRandomPhotoEssayRow(lastRowType, photos, enableAutoGrouping);
      rows.push(photoEssayRow);

      // Always have an empty caption after the row!
      rows.push(this.buildCaptionRow(''));

      // Remove the used photos from the list of photos
      const photoCount = this.getPhotoCount(photoEssayRow);
      lastRowType = photoEssayRow.type;
      photos.splice(0, photoCount);
    }

    photoEssay.name = '';
    photoEssay.cover = originalPhotos.length > 0 ? originalPhotos[0] : null;
    photoEssay.layout.rows = rows;

    return photoEssay;
  }



  /**
   * Generates a random row-type, consuming the next X photos from the photo list
   * This handles all of the special auto-grouping vs manual-grouping logic!
   *
   * @param lastRowType         Row type that we used on the previous row
   * @param photoList           Remaining photos to pick from
   * @param enableAutoGrouping  Whether or not to auto-group
   */
  getRandomPhotoEssayRow(lastRowType: PhotoEssayRowTypes, photoList: KintPhoto[], enableAutoGrouping: boolean): PhotoEssayRow {

    this.logService.info('Generating new row. Trying not to duplicate row type: ' + lastRowType);

    if (photoList.length === 0) {
      return this.buildCaptionRow('');
    }


    // See if we have 3 grouped photos
    if ((photoList.length >= 3) && this.isGrouped(photoList[0]) && this.isGrouped(photoList[1])) {

      // User-Mandated: 3-photo grouping (randomize between columns & collage)
      let nextRowType: PhotoEssayRowTypes = PhotoEssayRowTypes.THREE_COLUMN_PHOTOS;
      if (Math.random() >= 0.5) {
        nextRowType = PhotoEssayRowTypes.THREE_COLUMN_PHOTOS;
      } else {
        nextRowType = PhotoEssayRowTypes.COLLAGE_THREE_PHOTOS;
      }
      if (lastRowType === nextRowType) {
        if (nextRowType === PhotoEssayRowTypes.THREE_COLUMN_PHOTOS) {
          nextRowType = PhotoEssayRowTypes.COLLAGE_THREE_PHOTOS;
        } else {
          nextRowType = PhotoEssayRowTypes.THREE_COLUMN_PHOTOS;
        }
      }

      const threePhotos = [photoList[0], photoList[1], photoList[2]];
      return this.generateRowOfType(nextRowType, threePhotos);

    } else if ((photoList.length >= 2) &&  this.isGrouped(photoList[0])) {

      // User-Mandated: 2-photo grouping
      const twoPhotos = [photoList[0], photoList[1]];
      return this.generateRowOfType(PhotoEssayRowTypes.SIDE_BY_SIDE_PHOTOS, twoPhotos);

    } else if (!enableAutoGrouping) {

      // User-Mandated: Single Photo
      this.logService.info('AutoGrouping is disabled. Continuing with single photos');
      const singlePhoto = [photoList[0]];
      return this.generateRowOfType(PhotoEssayRowTypes.SINGLE_PHOTO, singlePhoto);

    } else {

      // Auto-grouping is enabled. We are free to create whatever row we want!
      // Just need to make sure all random selections are valid and don't violoate
      // any grouping rules (or get too repetative)
      let validSelection = false;

      const val = Math.random() * 100;
      this.logService.info('Generating new row. Random value: ' + val);
      let newRowType: PhotoEssayRowTypes = PhotoEssayRowTypes.SINGLE_PHOTO;

      if (val < 25) {
        // Single photo row is always OK
        newRowType = PhotoEssayRowTypes.SINGLE_PHOTO;
        validSelection = true;
      } else if (val < 50) {

        // Two-photo row. Only allow the two-photo grouping if the photos aren't
        // manually being grouped though
        newRowType = PhotoEssayRowTypes.SIDE_BY_SIDE_PHOTOS;
        if ((photoList.length >= 2) &&
            (lastRowType !== PhotoEssayRowTypes.SIDE_BY_SIDE_PHOTOS) &&
            !this.isGrouped(photoList[0]) &&
            !this.isGrouped(photoList[1])) {
          validSelection = true;
        }

      } else if (val < 75) {

        // Three-photo row. Only allow the two-photo grouping if the photos aren't
        // manually being grouped though
        newRowType = PhotoEssayRowTypes.THREE_COLUMN_PHOTOS;
        if ((photoList.length >= 3) &&
            (lastRowType !== PhotoEssayRowTypes.THREE_COLUMN_PHOTOS) &&
            !this.isGrouped(photoList[0]) &&
            !this.isGrouped(photoList[1]) &&
            !this.isGrouped(photoList[2])) {
          validSelection = true;
        }
      } else {
        newRowType = PhotoEssayRowTypes.COLLAGE_THREE_PHOTOS;
        if ((photoList.length >= 3) &&
            (lastRowType !== PhotoEssayRowTypes.COLLAGE_THREE_PHOTOS) &&
            !this.isGrouped(photoList[0]) &&
            !this.isGrouped(photoList[1]) &&
            !this.isGrouped(photoList[2])) {
          validSelection = true;
        }
      }

      this.logService.info('Generating new row. Selected : ' + newRowType);
      this.logService.info('Generating new row. validSelection : ' + validSelection);

      // If this selection is valid, go ahead and generate the row
      if (validSelection) {
        return this.generateRowOfType(newRowType, photoList);
      }


      // If we didn't get a valid selection (either because we would be repeating a row type
      // or because we don't have enough photos), then try again (will generate a new random row)
      // Eventually, this will resolve itself, in the worst-case as extra single-photo rows
      this.logService.info('Generating new row: LAST WAS INVALID. Trying again...');
      return this.getRandomPhotoEssayRow(lastRowType, photoList, enableAutoGrouping);

    }
  }


  /**
   * Checks to see if the given photo object should be "grouped" with the next photo in the list
   *
   * @param photo Photo object with an optional 'groupNext" field indicating if we want to
   *              group this photo with the next one or not
   */
  isGrouped(photo: any): boolean {
    return photo && photo.groupNext;
  }



  /**
   * Generates an essay-row of the tiven type
   *
   * @param rowType   PhotoEssayRowType to generate
   * @param photoList List of remaining photos to pick from. MUST contain sufficient photos for the row!
   */
  generateRowOfType(rowType: PhotoEssayRowTypes, photoList: KintPhoto[]): PhotoEssayRow {
    switch (rowType) {
      case PhotoEssayRowTypes.SINGLE_PHOTO: {
        const singlePhoto = [photoList[0]];
        return this.buildPhotoRow(rowType, singlePhoto);
      }
      case PhotoEssayRowTypes.SIDE_BY_SIDE_PHOTOS: {
        const twoPhotos = [photoList[0], photoList[1]];
        return this.buildPhotoRow(rowType, twoPhotos);
      }
      case PhotoEssayRowTypes.THREE_COLUMN_PHOTOS: {
        const threePhotos = [photoList[0], photoList[1], photoList[2]];
        return this.buildPhotoRow(rowType, threePhotos);
      }
      case PhotoEssayRowTypes.COLLAGE_THREE_PHOTOS: {
        const threePhotos = [photoList[0], photoList[1], photoList[2]];
        return this.buildPhotoRow(rowType, threePhotos);
      }
      default: {
        console.error('Unhandled Photo Essay Row type: ' + rowType);
        return this.buildCaptionRow('');
      }
    }
  }


  /**
   * Builds a Row with the given photos
   *
   * @param rowType Row type
   * @param photos Photos we want to use
   */
  buildPhotoRow(rowType: PhotoEssayRowTypes, photos: KintPhoto[]): PhotoEssayRow {
    const tiles: PhotoEssayTile[] = [];
    const caption = '';

    for (let i = 0; i < photos.length; i++) {
      const photo = photos[i];
      const tile: PhotoEssayTile = new PhotoEssayTile(PhotoEssayTileTypes.PHOTO, photo, caption);
      tiles.push(tile);
    }

    return new PhotoEssayRow(rowType, tiles);
  }



  /**
   * Counts the number of photos in the given PhotoEssayRow
   */
  getPhotoCount(row: PhotoEssayRow): number {
    // Count the photo-tiles count
    let count = 0;
    for (const tile of row.tiles) {
      if (tile.type === PhotoEssayTileTypes.PHOTO) {
        count++;
      }
    }
    return count;
  }

  /**
   * Builds a new Row with the given caption
   *
   * @param caption
   */
  buildCaptionRow(caption: string): PhotoEssayRow {
    const tile: PhotoEssayTile = new PhotoEssayTile(PhotoEssayTileTypes.CAPTION, null, caption);
    const singleTile: PhotoEssayTile[] = [tile];
    return new PhotoEssayRow(PhotoEssayRowTypes.CAPTION, singleTile);
  }


  /**
   * Gets all of the photos from the given row
   */
  getPhotosFromRow(row: PhotoEssayRow): KintPhoto[] {
    const photos: KintPhoto[] = [];
    for (const tile of row.tiles) {
      if (tile.photo) {
        photos.push(tile.photo);
      }
    }
    return photos;
  }


  /**
   * Get all of the photos from the given essay
   *
   * @param {PhotoEssay} essay
   * @returns {KintPhoto[]}
   * @memberof PhotoEssayLayoutService
   */
  getPhotosFromEssay(essay: PhotoEssay): KintPhoto[] {
    let photos: KintPhoto[] = [];

    if (essay.layout && essay.layout.rows) {
      for (const row of essay.layout.rows) {
        const photosFromRow = this.getPhotosFromRow(row);
        photos = photos.concat(photosFromRow);
      }
    }

    return photos;
  }



  /**
   * Builds a Photo Essay tile of the given type
   *
   * @param {KintPhoto} photo
   * @returns {PhotoEssayTile}
   * @memberof PhotoEssayLayoutService
   */
  buildPhotoTile(photo: KintPhoto): PhotoEssayTile {
    return new PhotoEssayTile(PhotoEssayTileTypes.PHOTO, photo, null);
  }


  /**
   * Builds and empty row of the given type
   *
   * @param {PhotoEssayRowTypes} rowType
   * @returns {PhotoEssayRow}
   * @memberof PhotoEssayLayoutService
   */
  buildEmptyRowOfType(rowType: PhotoEssayRowTypes): PhotoEssayRow {

    const tiles: PhotoEssayTile[] = [];

    switch (rowType) {
      case PhotoEssayRowTypes.CAPTION: {
        tiles.push(new PhotoEssayTile(PhotoEssayTileTypes.CAPTION, null, ''));
        break;
      }
      case PhotoEssayRowTypes.SINGLE_PHOTO: {
        tiles.push(new PhotoEssayTile(PhotoEssayTileTypes.PHOTO, null, null));
        break;
      }
      case PhotoEssayRowTypes.SIDE_BY_SIDE_PHOTOS: {
        tiles.push(new PhotoEssayTile(PhotoEssayTileTypes.PHOTO, null, null));
        tiles.push(new PhotoEssayTile(PhotoEssayTileTypes.PHOTO, null, null));
        break;
      }
      case PhotoEssayRowTypes.THREE_COLUMN_PHOTOS:
      case PhotoEssayRowTypes.COLLAGE_THREE_PHOTOS: {
        tiles.push(new PhotoEssayTile(PhotoEssayTileTypes.PHOTO, null, null));
        tiles.push(new PhotoEssayTile(PhotoEssayTileTypes.PHOTO, null, null));
        tiles.push(new PhotoEssayTile(PhotoEssayTileTypes.PHOTO, null, null));
        break;
      }
      default: {
        this.logService.error('Unknown photo essay row type: ' + rowType);
      }
    }

    return new PhotoEssayRow(rowType, tiles);
  }



  /**
   * Builds a new row based on the given photos
   *
   * @param {KintPhoto[]} photos
   * @returns {PhotoEssayRow}
   * @memberof PhotoEssayLayoutService
   */
  rebuildRowForPhotos(photos: KintPhoto[]): PhotoEssayRow {

    if (photos.length === 0) {
      this.logService.info('Row no longer needed. Photos have been removed.');
      return null;
    }

    const photoEssayRows: PhotoEssayRow[] = [];

    // Build tiles for all of the photos
    const photoTiles: PhotoEssayTile[] = [];
    for (const photo of photos) {
      photoTiles.push(new PhotoEssayTile(PhotoEssayTileTypes.PHOTO, photo, null));
    }

    // Build rows of the correct types
    if (photos.length === 0) {
      return new PhotoEssayRow(PhotoEssayRowTypes.SINGLE_PHOTO, photoTiles);
    } else if (photos.length === 1) {
      return new PhotoEssayRow(PhotoEssayRowTypes.SINGLE_PHOTO, photoTiles);
    } else if (photos.length === 2) {
      return new PhotoEssayRow(PhotoEssayRowTypes.SIDE_BY_SIDE_PHOTOS, photoTiles);
    } else {
      if (photos.length > 3) {
        this.logService.error('Cannot build rows for more than 3 photos. Truncating.');
      }
      return new PhotoEssayRow(PhotoEssayRowTypes.THREE_COLUMN_PHOTOS, photoTiles);
    }
  }


  /**
   * Checks to see if the given row is a Caption row or not
   *
   * @param {PhotoEssayRow} row
   * @returns {boolean}
   * @memberof PhotoEssayLayoutService
   */
  isCaptionRow(row: PhotoEssayRow): boolean {
    if (row.type === PhotoEssayRowTypes.CAPTION) {
      return true;
    }
    return false;
  }


  /**
   * Checks to see if the given row is an empty caption row (caption with no text)
   *
   * @static
   * @param {PhotoEssayRow} row
   * @returns {boolean}
   * @memberof PhotoEssayLayoutService
   */
  isEmptyCaptionRow(row: PhotoEssayRow): boolean {
    if (row.type === PhotoEssayRowTypes.CAPTION &&
        row.tiles &&
        row.tiles.length > 0 &&
        row.tiles[0].caption.trim() === ''
      ) {
      return true;
    }
    return false;
  }
}

// Register an AngularJS service, whose value is the "downgraded" Angular injectable.
angular.module('KintributeApp').factory(
  'PhotoEssayLayoutService', downgradeInjectable(PhotoEssayLayoutService) as any);
