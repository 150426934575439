import { Component, OnInit, Input, Inject } from '@angular/core';
import { KintTimeline } from '../../kint-timeline';
import { downgradeComponent } from '@angular/upgrade/static';

// Angular is loaded separately
declare var angular: any;

@Component({
  selector: 'app-timeline-layout-vertical',
  templateUrl: './timeline-layout-vertical.component.html',
  styleUrls: ['./timeline-layout-vertical.component.css']
})
export class TimelineLayoutVerticalComponent implements OnInit {

  @Input('timeline') timeline: KintTimeline;

  constructor(@Inject('AppDataModelService') private ng1AppDataModelService: any) {
  }

  ngOnInit() {
  }

  isMobile(): boolean {
    return this.ng1AppDataModelService.isMobile();
  }

}



// Provide a downgraded version of this to run with AngularJS
angular.module('KintributeApp')
  .directive('timelineLayoutVertical', downgradeComponent(
    {
      component: TimelineLayoutVerticalComponent,
      inputs: ['timeline']
    }
  ));
