import { OnInit } from '@angular/core';
import { KintTimeline } from '../../kint-timeline';
import { DateHelper } from 'src/app/Util/Date/DateHelper';
import { KintTimelineEntry } from '../../kint-timeline-entry';
var TimelineEntryHorizontalComponent = /** @class */ (function () {
    function TimelineEntryHorizontalComponent(ng1ModalService) {
        this.ng1ModalService = ng1ModalService;
        this.labelTop = '';
        this.labelBottom = '';
    }
    TimelineEntryHorizontalComponent.prototype.ngOnInit = function () {
        console.log(this.timelineEntry);
        // Build all of the supplements for this entry
        if (this.timelineEntry.photo) {
            var photo = this.timelineEntry.photo;
            // Build out the Date/Time labels
            var date = new Date(photo.date.isoDate);
            if (this.timeline.timelineFormat.lowestLevelIsTime) {
                this.labelTop = DateHelper.getShortMonthFromKintDate(photo.date) +
                    DateHelper.getDayOfMonthFromKintDate(photo.date);
                this.labelBottom = DateHelper.getTime(date);
            }
            else {
                this.labelTop = DateHelper.getShortMonthFromKintDate(photo.date);
                this.labelBottom = DateHelper.getDayOfMonthFromKintDate(photo.date);
            }
            // If we only have a single label, make the top label more pronounced because it's now the primary label
            if (!this.labelBottom || (this.labelBottom.trim().length === 0)) {
                this.labelTop = this.labelTop.toUpperCase();
            }
        }
    };
    TimelineEntryHorizontalComponent.prototype.openPhotoPreview = function () {
        this.ng1ModalService.openPhotoPopup(this.timelineEntry.photo);
    };
    return TimelineEntryHorizontalComponent;
}());
export { TimelineEntryHorizontalComponent };
