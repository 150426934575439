<div class="personal-page-content"
     [ngClass]="{'personal-page-content-mobile': isMobile}">

  <div *ngFor="let row of page.layout.rows" class="personal-page-content-row-holder">
    
    <app-personal-page-row [row]="row"
                           [isMobile]="isMobile"
                           [editor]="editor">
    </app-personal-page-row>

  </div>

</div>