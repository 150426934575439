import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap, catchError} from 'rxjs/operators';
import { ILocationAutoCompletePhotonResponse,
          LocationAutoCompletePhotonResult,
          LocationAutoCompletePhotonResponse } from './location-autocomplete-photon-result';
import { KintGeo } from 'src/app/KintObject/kint-location';

@Injectable({
  providedIn: 'root'
})
export class LocationLookupPhotonService {

  private browserGeoLocation = null;

  constructor(private http: HttpClient) { }

  setBrowserLocation(position: any): void {
    this.browserGeoLocation = {
      lat: position.latitude,
      long: position.longitude
    };
  }


  /**
   * Build Photon Location lookup URL
   *
   * @param {string}    searchString    String we are searching for
   * @param {boolean}   isForTypahead   (whether or not this is for typeahead or not)
   * @param {KintGeo}   geoCenter       (optional for centering around the photo's coords)
   * @returns {string}
   * @memberof LocationLookupService
   */
  buildLookupUrl(searchString: string, isForTypahead: boolean, geoCenter: KintGeo): string {

    // Get the location of *this* browser (if available).
    // Note:  This may result in a prompt being put up to the user to allow geo-location
    if (navigator.geolocation) {
      const self = this;
      navigator.geolocation.getCurrentPosition(function(position) {
        if (position && position.coords) {
          self.setBrowserLocation(position.coords);
        }
      });
    } else {
      console.error('Geolocation no supported by this browser');
    }


    // Using Photon as a location provider. Build up the params for the fuzzy-search.
    // Important flags:
    //  - GEO and POI  so we get "Las Vegas, NV" and "MGM Grand, Las Vegas, NV"
    //  - typeahead = true (so it knows we may be mid-typing)
    //  - lat/long for the browser's location

    let locationLookupUrl = 'https://photon.komoot.de/api/?q=NAME_REPLACE&lang=en';
    locationLookupUrl = locationLookupUrl.replace('NAME_REPLACE', searchString);
    /*
    locationLookupUrl = locationLookupUrl.replace('KEY_REPLACE', 'DQ5NfVmexIYSt3YGlJDrArNw1pI1ashW');
    if (isForTypahead) {
      locationLookupUrl = locationLookupUrl + '&typeahead=true';
    }
    */

    // Add a centering bias for the lookup results
    if (geoCenter) {

      // Center around a specific set of coordinates (probably because that was on a photo)
      const locationBias = '&lat=' + this.browserGeoLocation.lat + '&lon=' + this.browserGeoLocation.long;
      locationLookupUrl = locationLookupUrl + locationBias;

    } else if (this.browserGeoLocation) {

      // Center around whatever the browser tells us our location is
      const locationBias = '&lat=' + this.browserGeoLocation.lat + '&lon=' + this.browserGeoLocation.long;
      locationLookupUrl = locationLookupUrl + locationBias;
    }

    return locationLookupUrl;
  }



  /**
   * Searches for the given location. This is a 'final' result and not to
   * be used for auto-complete use cases
   *
   * @param {''} searchString
   * @param {geoCenter} geoCenter (optional 'center' for auto-complete results)
   * @returns {Promise<ILocationAutoCompletePhotonResponse>}
   * @memberof LocationLookupService
   */
  searchFinal(searchString: '', geoCenter: KintGeo): Promise<ILocationAutoCompletePhotonResponse> {

    // If no search terms, just return immediately with an empty search result
    if (!searchString) {
      const promise = new Promise<ILocationAutoCompletePhotonResponse>((resolve, reject) => {
        resolve(new LocationAutoCompletePhotonResponse());
      });
      return promise;
    }

    const isForTypeahead = false;
    const locationLookupUrl = this.buildLookupUrl(searchString, isForTypeahead, geoCenter);

    return this.http.get<ILocationAutoCompletePhotonResponse>(locationLookupUrl).toPromise();
  }


  /**
   * Runs the given search and returns an observable containin the response
   *
   * @param {string} searchString
   * @param {KintGeo} geoCenter  (optional location to center around for auto-complete)
   * @returns {Observable<ILocationAutoCompletePhotonResponse>}
   * @memberof LocationLookupService
   */
  search(searchString: string, geoCenter: KintGeo): Observable<ILocationAutoCompletePhotonResponse> {

    // If no search string, just return an empty response (pretend it never happend)
    if (!searchString) {
      return this.buildEmptyResults();
    }

    const isForTypeahead = true;
    const locationLookupUrl = this.buildLookupUrl(searchString, isForTypeahead, geoCenter);

    return this.http.get<ILocationAutoCompletePhotonResponse>(locationLookupUrl)
      .pipe(
        tap((response: ILocationAutoCompletePhotonResponse) => {
          response.features = response.features
            .map(feature => new LocationAutoCompletePhotonResult(feature));
          return response;
        }),
        catchError((err: any) => {
         return new Observable<ILocationAutoCompletePhotonResponse>();
        })
      );
  }


  /**
   * Build an empty ILocationAutoCompletePhotonResponse. This is useful to fake
   * an 'empty' result from the server
   */
  buildEmptyResults(): Observable<ILocationAutoCompletePhotonResponse> {
    const response = new Observable<ILocationAutoCompletePhotonResponse>((observer) => {
      observer.next(new LocationAutoCompletePhotonResponse());
      observer.complete();
    });
    return response;
  }

}
