import { Component, OnInit, Input } from '@angular/core';
import { KintTimeline } from '../../kint-timeline';
import { downgradeComponent } from '@angular/upgrade/static';

// Angular is loaded separately
declare var angular: any;

@Component({
  selector: 'app-timeline-layout-horizontal',
  templateUrl: './timeline-layout-horizontal.component.html',
  styleUrls: ['./timeline-layout-horizontal.component.css']
})
export class TimelineLayoutHorizontalComponent implements OnInit {

  @Input('timeline') timeline: KintTimeline;

  constructor() { }

  ngOnInit() {
  }

}



// Provide a downgraded version of this to run with AngularJS
angular.module('KintributeApp')
  .directive('timelineLayoutHorizontal', downgradeComponent(
    {
      component: TimelineLayoutHorizontalComponent,
      inputs: ['timeline']
    }
  ));
