/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./personal-page-tile.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../Photo/photo-display.directive";
import * as i4 from "../../Log/log.service";
import * as i5 from "../../Photo/photo-selection.service";
import * as i6 from "./personal-page-tile.component";
var styles_PersonalPageTileComponent = [i0.styles];
var RenderType_PersonalPageTileComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PersonalPageTileComponent, data: {} });
export { RenderType_PersonalPageTileComponent as RenderType_PersonalPageTileComponent };
export function View_PersonalPageTileComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "personal-page-highlight"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "personal-page-tile-mobile": 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 8, "a", [], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "personal-page-tile-cover"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["appPhotoDisplay", ""], ["class", "personal-page-tile-cover-image"]], null, [["window", "resize"], [null, "load"], [null, "error"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onResize($event) !== false);
        ad = (pd_0 && ad);
    } if (("load" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6).onImageLoaded($event) !== false);
        ad = (pd_1 && ad);
    } if (("error" === en)) {
        var pd_2 = (i1.ɵnov(_v, 6).onImageLoadError($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 344064, null, 0, i3.PhotoDisplayDirective, [i1.ElementRef, i4.LogService, i5.PhotoSelectionService], { photoData: [0, "photoData"], isBackgroundImage: [1, "isBackgroundImage"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [["class", "personal-page-tile-cover-spacer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["class", "personal-page-tile-type"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, [" ", " "])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "personal-page-tile-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, [" ", " "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "personal-page-highlight"; var currVal_1 = _ck(_v, 2, 0, _co.isMobile); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_3 = _co.getCover(); var currVal_4 = true; _ck(_v, 6, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵinlineInterpolate(1, "", _co.getTileLink(), ""); _ck(_v, 3, 0, currVal_2); var currVal_5 = _co.getTileType(); _ck(_v, 9, 0, currVal_5); var currVal_6 = _co.getTileName(); _ck(_v, 11, 0, currVal_6); }); }
export function View_PersonalPageTileComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-personal-page-tile", [], null, null, null, View_PersonalPageTileComponent_0, RenderType_PersonalPageTileComponent)), i1.ɵdid(1, 114688, null, 0, i6.PersonalPageTileComponent, ["LinkHelperService"], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PersonalPageTileComponentNgFactory = i1.ɵccf("app-personal-page-tile", i6.PersonalPageTileComponent, View_PersonalPageTileComponent_Host_0, { tile: "tile", isMobile: "isMobile", editor: "editor" }, {}, []);
export { PersonalPageTileComponentNgFactory as PersonalPageTileComponentNgFactory };
