var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, DoCheck, ElementRef } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { PersonalPageEditor } from '../personal-page-editor';
import { ViewCheckHelper } from 'src/app/Util/viewCheck/view-check-helper';
var PersonalPageHeaderComponent = /** @class */ (function (_super) {
    __extends(PersonalPageHeaderComponent, _super);
    /**
     * Constructor
     *
     * @param appDataModelService
     */
    function PersonalPageHeaderComponent(elementRef) {
        var _this = _super.call(this) || this;
        _this.elementRef = elementRef;
        /**
         * Private Members
         */
        _this.image1IsActive = true;
        _this.image2IsActive = false;
        _this.IMAGE_CYCLE_DURATION = 4250;
        _this.IMAGE_ANIMATION_DURATION = 1100;
        _this.image1 = null;
        _this.image2 = null;
        _this.currentPhotoIndex = 0;
        _this.nextPhotoIndex = 0;
        _this.loaded = false;
        return _this;
    }
    /**
     * Initialize the component
     */
    PersonalPageHeaderComponent.prototype.ngOnInit = function () {
        if (this.header) {
            this.loaded = true;
            this.setupInitialImages();
        }
        else {
            // Wait for it to get ready and init later
        }
    };
    /**
     * Set up the initial images
     */
    PersonalPageHeaderComponent.prototype.setupInitialImages = function () {
        if (this.header) {
            // Set up initial images
            if (this.header.photos.length > 0) {
                this.image1 = this.header.photos[0];
            }
            if (this.header.photos.length > 1) {
                this.nextPhotoIndex = 1;
                this.image2 = this.header.photos[1];
            }
            this.cycleImages();
        }
    };
    PersonalPageHeaderComponent.prototype.userCanEdit = function () {
        if (this.editor && this.editor.userCanEdit()) {
            return true;
        }
        return false;
    };
    PersonalPageHeaderComponent.prototype.openSettingsDialog = function () {
        console.log('Open Settings Dialog');
    };
    PersonalPageHeaderComponent.prototype.ngDoCheck = function () {
        if (!this.loaded) {
            if (this.header) {
                this.loaded = true;
            }
        }
    };
    /**
     * Cycle to the next image (after a duration)
     */
    PersonalPageHeaderComponent.prototype.cycleImages = function () {
        var _this = this;
        // After the old image has transitioned out,
        // load the next image
        setTimeout(function () {
            _this.preloadNextImage();
        }, this.IMAGE_ANIMATION_DURATION);
        // Swap out to the new image
        setTimeout(function () {
            _this.moveNextImage();
        }, this.IMAGE_CYCLE_DURATION);
    };
    /**
     * Pre-load the next image in the list
     */
    PersonalPageHeaderComponent.prototype.preloadNextImage = function () {
        // Pre-load the image which is NOT active
        if (this.image1IsActive) {
            this.image2 = this.header.photos[this.nextPhotoIndex];
        }
        else {
            this.image1 = this.header.photos[this.nextPhotoIndex];
        }
    };
    /**
     * Move to the next image in the list
     */
    PersonalPageHeaderComponent.prototype.moveNextImage = function () {
        this.image1IsActive = !this.image1IsActive;
        this.image2IsActive = !this.image2IsActive;
        this.currentPhotoIndex++;
        if (this.currentPhotoIndex >= this.header.photos.length) {
            this.currentPhotoIndex = 0;
        }
        this.nextPhotoIndex = this.currentPhotoIndex + 1;
        if (this.nextPhotoIndex >= this.header.photos.length) {
            this.nextPhotoIndex = 0;
        }
        this.cycleImages();
    };
    return PersonalPageHeaderComponent;
}(ViewCheckHelper));
export { PersonalPageHeaderComponent };
// Provide a downgraded version of this to run with AngularJS
angular.module('KintributeApp')
    .directive('appPersonalPageHeader', downgradeComponent({
    component: PersonalPageHeaderComponent,
    inputs: ['header']
}));
