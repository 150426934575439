<div class="personal-page-row"
     [ngClass]="{'personal-page-row-mobile': isMobile}">

  <!-- Text Row -->
  <div *ngIf="row.type == 'TEXT'">
    <app-personal-page-text-row [row]="row"
                                [isMobile]="isMobile"
                                [editor]="editor">
    </app-personal-page-text-row>
  </div>

  <!-- Highlights / Tiles Row -->
  <div *ngIf="(row.type == 'COLLECTION_HIGHLIGHTS') || (row.type == 'ESSAY_HIGHLIGHTS')">
    <app-personal-page-tiles-row [row]="row"
                                 [isMobile]="isMobile"
                                 [editor]="editor">
    </app-personal-page-tiles-row>
  </div>

</div>