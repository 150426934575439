/** @ng2api @module ng2 */ /** for typedoc */

export * from './interface';
export * from './providers';
export * from './uiRouterNgModule';
export * from './uiRouterConfig';
export * from './directives/directives';
export * from './statebuilders/views';
export * from './statebuilders/lazyLoad';
export * from './lazyLoad/lazyLoadNgModule';

export * from '@uirouter/core';
