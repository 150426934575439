<div class="kint-edit-field">

  <!-- Field Label -->
  <div class="kint-edit-field-label">
    Tags
  </div>

  <!-- View/Edit field -->
  <div class="kint-edit-field-data">
    <div class="tag-editor-wrapper">

      <div class="tag-editor-tags-holder">
        
        <!-- Keywords First -->
        <div *ngFor="let keyword of photo.keywords" class="tag-editor-tag-bubble">
          <div class="tag-editor-tab-bubble-wrapper">
            <div class="tag-editor-bubble-content"
                 matTooltip="{{keyword}}">
                {{keyword}}
            </div>
            <div class="tag-editor-bubble-content glyphicon glyphicon-remove"
                (click)="removeKeyword(keyword)">
            </div>
          </div>
        </div>

        <!-- Auto-Tags next -->
        <ng-container *ngFor="let autoTag of photo.autoTags">
          <div *ngIf="autoTag.display" class="tag-editor-tag-bubble">
            <div class="tag-editor-tab-bubble-wrapper">
              <div class="tag-editor-bubble-content"
                   matTooltip="{{autoTag.name}}">
                  {{autoTag.name}}
              </div>
              <div class="tag-editor-bubble-content glyphicon glyphicon-remove"
                  (click)="removeAutoTag(autoTag)">
              </div>
            </div>
          </div>
        </ng-container>
      </div>


      <!-- Spacer between tags and "+ Add" (if there are any tags)-->
      <div *ngIf="hasTags()" class="tags-editor-add-spacer">
      </div>


      <!-- New Tag -->
      <div *ngIf="newTagText !== null">
          <input id="tag-editor-new-tag-field"
                 class="kint-edit-field-text kint-edit-field"
                 [(ngModel)]="newTagText"
                 placeholder="high school, picnic, etc"
                 autofocus
                 (blur)="finishNewTag()"
                 (keydown)="onKeydown($event)">
      </div>


      <!-- Add Tags -->
      <div class="kint-link-text"
           (click)="addTag()">
        +Add Tags
      </div>

    </div>

  </div>

</div>