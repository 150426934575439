/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./timeline-layout-horizontal.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../timeline-block-horizontal/timeline-block-horizontal.component.ngfactory";
import * as i3 from "../timeline-block-horizontal/timeline-block-horizontal.component";
import * as i4 from "@angular/common";
import * as i5 from "./timeline-layout-horizontal.component";
var styles_TimelineLayoutHorizontalComponent = [i0.styles];
var RenderType_TimelineLayoutHorizontalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TimelineLayoutHorizontalComponent, data: {} });
export { RenderType_TimelineLayoutHorizontalComponent as RenderType_TimelineLayoutHorizontalComponent };
function View_TimelineLayoutHorizontalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-timeline-block-horizontal", [], null, null, null, i2.View_TimelineBlockHorizontalComponent_0, i2.RenderType_TimelineBlockHorizontalComponent)), i1.ɵdid(2, 114688, null, 0, i3.TimelineBlockHorizontalComponent, [], { timelineBlock: [0, "timelineBlock"], timeline: [1, "timeline"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.timeline; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_TimelineLayoutHorizontalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "timeline-layout-horizontal-undated-photos"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" + ", " Undated Photos "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.timeline.undatedPhotos.length; _ck(_v, 2, 0, currVal_0); }); }
export function View_TimelineLayoutHorizontalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "timeline-layout-horizontal"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimelineLayoutHorizontalComponent_1)), i1.ɵdid(3, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimelineLayoutHorizontalComponent_2)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.timeline.timelineBlocks; _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.timeline.undatedPhotos.length > 0); _ck(_v, 5, 0, currVal_1); }, null); }
export function View_TimelineLayoutHorizontalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-timeline-layout-horizontal", [], null, null, null, View_TimelineLayoutHorizontalComponent_0, RenderType_TimelineLayoutHorizontalComponent)), i1.ɵdid(1, 114688, null, 0, i5.TimelineLayoutHorizontalComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TimelineLayoutHorizontalComponentNgFactory = i1.ɵccf("app-timeline-layout-horizontal", i5.TimelineLayoutHorizontalComponent, View_TimelineLayoutHorizontalComponent_Host_0, { timeline: "timeline" }, {}, []);
export { TimelineLayoutHorizontalComponentNgFactory as TimelineLayoutHorizontalComponentNgFactory };
