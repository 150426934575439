import { OnInit } from '@angular/core';
import { KintTimelineSupplementMap } from '../../timeline-supplement';
var TimelineSupplementMapComponent = /** @class */ (function () {
    function TimelineSupplementMapComponent() {
    }
    TimelineSupplementMapComponent.prototype.ngOnInit = function () {
        this.gpsCenter = [
            this.supplement.location.position.lat,
            this.supplement.location.position.long
        ];
        /**
         *  CodePen
         *
    
         // Define your product name and version
        tomtom.setProductInfo('<your-product-name>', '<your-product-version>');
        tomtom.L.map('map', {
            key: '<your-tomtom-API-key>'
        });
    
         */
    };
    return TimelineSupplementMapComponent;
}());
export { TimelineSupplementMapComponent };
