import { KintPhoto } from 'src/app/KintObject/kint-photo';
import { KintDate } from 'src/app/KintObject/kint-date';


/**
 * This is a Helper class for dealing with Date manipulation
 *
 * @export
 * @class DateHelper
 */
export class DateHelper {

  /**
   * Check to see if the given photo fits within the given range
   *
   * @static
   * @param {KintPhoto} photo
   * @param {string} startIsoDate
   * @param {string} endIsoDate
   * @returns {boolean}
   * @memberof KintTimelineBlock
   */
  static isPhotoWithinRange(photo: KintPhoto, startIsoDate: string, endIsoDate: string, includeEndDate: boolean): boolean {
    if (photo.date &&
        photo.date.isoDate &&
        (photo.date.isoDate >= startIsoDate)) {

      if (includeEndDate) {
        return (photo.date.isoDate <= endIsoDate);
      } else {
        return (photo.date.isoDate < endIsoDate);
      }

    }

    return false;
  }



  /**
   * Gets a short "Month" from the given KintDate
   *
   * @static
   * @param {KintDate} date
   * @returns {string}
   * @memberof DateHelper
   */
  static getShortMonthFromKintDate(date: KintDate): string {
    if (!date.month) {
      return '';
    }
    return this.getShortMonth(new Date(date.isoDate));
  }

  /**
   * Get the Short version of a Month for the given Date ("AUG");
   *
   * @static
   * @param {*} date
   * @returns {string}
   * @memberof DateService
   */
  static getShortMonth(date: Date): string {
    return date.toLocaleString('en-us', { month: 'short', timeZone: 'UTC' });
  }


  /**
   * Get the Long version of a Month for the given Date ("August");
   *
   * @static
   * @param {*} date
   * @returns {string}
   * @memberof DateService
   */
  static getLongMonth(date: Date): string {
    return date.toLocaleString('en-us', { month: 'long', timeZone: 'UTC' });
  }



  /**
   * Get the the day of the month from the date
   *
   * @static
   * @param {*} date
   * @returns {string}
   * @memberof DateService
   */
  static getDayOfMonthFromKintDate(date: KintDate): string {
    if (!date.day) {
      return '';
    }
    return '' + new Date(date.isoDate).getDate();
  }

  /**
   * Get the the day of the month from the date
   *
   * @static
   * @param {*} date
   * @returns {string}
   * @memberof DateService
   */
  static getDayOfMonth(date: Date): string {
    return '' + date.getDate();
  }


  /**
   * Get the time from the date
   *
   * @static
   * @param {*} date
   * @returns {string} formatted as "2:45 PM"
   * @memberof DateService
   */
  static getTime(date: Date): string {
    let amPm = 'AM';
    let hours = date.getHours();
    if (hours > 12) {
      amPm = 'PM';
      hours = hours - 12;
    }
    const minutesString = DateHelper.getTwoDigitNumberString(date.getMinutes());
    const formatedTime = hours + ':' + minutesString + amPm;
    return formatedTime;
  }



  /**
   * Gets the given Time on the specific date
   *
   * @param {Date} date
   * @param {number} hours
   * @param {number} minutes
   * @param {boolean} includeEndOfMinutes (59:59:999 instead of 59:00:000)
   * @returns {Date}
   * @memberof DateService
   */
  static getTimeOnDate(date: Date, hours: number, minutes: number, includeEndOfMinutes: boolean): Date {

    if (includeEndOfMinutes) {
      return new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        hours,
        minutes,
        59,
        999
      );
    }

    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      hours,
      minutes
    );
  }


  /**
   * Builds an ISODateString in ZULU time around the given components
   * Note: Month and Day must be 0-based!
   */
  static buildUnbiasZeroBasedISODateString(yearToUse: number, monthToUse: number, dayToUse: number): string {

    // If the month is > 11, then subtract 12 and add it 1 to the year
    // This is so that we can add 24 months to a date and let it count
    // as 2 more years (useful for loops)
    while (monthToUse > 11) {
      monthToUse = monthToUse - 12;
      yearToUse++;
    }

    const dayString = DateHelper.getTwoDigitNumberString(dayToUse + 1);     // ISODate is 1-based even though other date info is 0-based
    const monthString = DateHelper.getTwoDigitNumberString(monthToUse + 1); // ISODate is 1-based even though other date info is 0-based

    // manually build the ISO Date so we don't bias it with our current timezone
    let generatedIsoDate = 'YEAR-MONTH-DAYT00:00:00.000Z';
    generatedIsoDate = generatedIsoDate.replace('YEAR', '' + yearToUse);
    generatedIsoDate = generatedIsoDate.replace('MONTH', monthString);
    generatedIsoDate = generatedIsoDate.replace('DAY', dayString);

    return generatedIsoDate;
  }

  /**
   * Gets a 2-digit number for the given number. Example: 1 -> 01, 7 -> 07.  11 -> 11
   * https://stackoverflow.com/questions/8043026/how-to-format-numbers-by-prepending-0-to-single-digit-numbers/50757763
   */
  public static getTwoDigitNumberString(num: number): string {
    // Add a single '0' to the beginning and then get the last 2 characters from the string
    const formattedNumber = ('0' + num).slice(-2);
    return formattedNumber;
  }

  constructor() { }
}
