import { ElementRef, OnInit, DoCheck } from '@angular/core';
import { LogService } from '../Log/log.service';
import { PhotoSelectionService } from './photo-selection.service';
var PhotoDisplayDirective = /** @class */ (function () {
    function PhotoDisplayDirective(el, logService, photoSelectionService) {
        this.FALLBACK_AVATAR = '/img/avatarPlaceholder.png';
        this.initialized = false;
        // List of links which we have tried to use, but didn't work
        this.failedLinks = [];
        this.element = el;
        this.logService = logService;
        this.photoSelectionService = photoSelectionService;
    }
    /**
     * Initialize the component
     */
    PhotoDisplayDirective.prototype.ngOnInit = function () {
        this.initialized = true;
        this.element.nativeElement.style.backgroundColor = '#DADADA';
        // Grab the current Photo id
        if (this.photoData) {
            this.currentPhotoId = this.photoData.id;
        }
        // Select the best image to display
        this.chooseBestImage();
        this.recalculateSizes();
    };
    /**
     * Select the Best Image to display (sizing, etc)
     */
    PhotoDisplayDirective.prototype.chooseBestImage = function () {
        // Select the smallest possible photo that will do the job for us. Choices include:
        // 1. photo.alternate_sizes[ {height: xxxx, width: xxxxx, link: xxxxx}...]
        // 2. photo.link
        // 3. photo.alternateLinks['xxxxxxxx', 'yyyyyyyy', 'zzzzzzzzz']
        if (!this.photoData || !this.photoData.id) {
            if (!this.photoData) {
                this.logService.error('Photo object missing directive!');
            }
            if (!this.photoData.id) {
                this.logService.error('Photo ID missing in directive!');
            }
            this.logService.error('Photo not supplied to photo-display directive!');
            return;
        }
        var bestLink = this.photoSelectionService.chooseBestImage(this.photoData, $(this.element.nativeElement), this.failedLinks);
        if (bestLink) {
            this.setImageLink(bestLink);
        }
    };
    /**
     * Set the Image link to the current element (background image OR an img element)
     */
    PhotoDisplayDirective.prototype.setImageLink = function (link) {
        this.currentLink = link;
        // Fill the entire background, cropping if needed
        if (this.isBackgroundImage) {
            this.element.nativeElement.style.backgroundImage = 'url(' + link + ')';
            this.element.nativeElement.style.backgroundSize = 'cover';
            this.element.nativeElement.style.backgroundRepeat = 'no-repeate';
            this.element.nativeElement.style.backgroundColor = '#DADADA';
            this.element.nativeElement.style.backgroundPosition = 'center';
            this.handleLoadEventForBackgroundImage(link);
        }
        else {
            this.element.nativeElement.src = link;
        }
        this.recalculateSizes();
    };
    /**
     * Recalculate the size that this element is taking up
     * Depending on the call, this may update the size of the element
     * OR just reposition the photo within an existing frame
     */
    PhotoDisplayDirective.prototype.recalculateSizes = function () {
        var photo = this.photoData;
        var elementWidth = this.element.nativeElement.clientWidth;
        var elementHeight = this.element.nativeElement.clientHeight;
        if (photo && this.sizeToElement && photo.size) {
            var height = Math.floor(((elementWidth * photo.size.height) / photo.size.width)) + 'px';
            this.element.nativeElement.style.height = height;
        }
        if (photo && this.sizeToHeight && photo.size) {
            var width = Math.floor(((elementHeight * photo.size.width) / photo.size.height)) + 'px';
            this.element.nativeElement.style.width = width;
        }
        if (this.isBackgroundImage) {
            // Handle any cropping which may be going on!
            if (photo && photo.size) {
                // If our element is NOT portrait (include square elements)
                if (elementWidth >= elementHeight) {
                    // And our photo IS portrait
                    if (photo.size.height >= photo.size.width) {
                        // We have a portrait photo in a landscape frame!
                        // If < 20% of the photo will be cropped, that's fine. Just center it
                        // If > 20% of the photo will be cropped, then only drop out the top 10% and crop the rest from the bottom
                        // We know our photo is the full width of the frame. Figure out
                        // how tall our image SHOULD render without any cropping
                        var uncroppedHeight = (elementWidth * photo.size.height) / photo.size.width;
                        var croppedPercentage = (uncroppedHeight - elementHeight) / uncroppedHeight;
                        if (croppedPercentage <= 0.2) {
                            // no problem. Just evenly center the photo
                            this.element.nativeElement.style.backgroundPositionY = 'center';
                        }
                        else {
                            // Lots of cropping. Weight it towards the top (only trim off the top 10%)
                            var tenPercentHeight = uncroppedHeight * 0.10;
                            var positionString = '-' + tenPercentHeight + 'px';
                            this.element.nativeElement.style.backgroundPositionY = positionString;
                        }
                    }
                }
            }
        }
    };
    // Our background images load via CSS:   background-image: url("xxxxxx")
    // We need to be able to handle load-failures on those. To do that, we can
    // internally allocated a new Image object and set it's link equal to the same
    // background image link. When that loads/fails, we call the correct function
    PhotoDisplayDirective.prototype.handleLoadEventForBackgroundImage = function (link) {
        var _this = this;
        var img = new Image();
        img.onload = function (e) {
            _this.onImageLoaded(e);
            img = null;
        };
        img.onerror = function (e) {
            _this.logService.error('Error loading image: ' + e);
            _this.onImageLoadError(e);
            img = null;
        };
        img.src = link;
    };
    /**
     * Watch for changes in the photo id
     */
    PhotoDisplayDirective.prototype.ngDoCheck = function () {
        if (!this.initialized || !this.photoData) {
            return;
        }
        if (this.currentPhotoId !== this.photoData.id) {
            this.currentPhotoId = this.photoData.id;
            this.chooseBestImage();
        }
    };
    PhotoDisplayDirective.prototype.onResize = function (event) {
        this.recalculateSizes();
        this.chooseBestImage();
    };
    PhotoDisplayDirective.prototype.onImageLoaded = function (event) {
        // Image loaded. Nothing to do right now.
    };
    PhotoDisplayDirective.prototype.onImageLoadError = function (event) {
        this.logService.warn('Failed to load image. Attempting fallback...');
        // Store the link that failed.
        this.failedLinks.push(this.currentLink);
        var bestLink = this.photoSelectionService.chooseBestImage(this.photoData, $(this.element.nativeElement), this.failedLinks);
        if (bestLink !== this.currentLink) {
            this.setImageLink(bestLink);
        }
        else {
            // Out of alternates. Just use our fallback
            this.logService.error('Error loading image. No alternates available.');
            if (this.fallbackSrc) {
                this.setImageLink(this.fallbackSrc);
            }
        }
    };
    return PhotoDisplayDirective;
}());
export { PhotoDisplayDirective };
