import { Component, OnInit, Input } from '@angular/core';
import { KintTimelineSupplement } from '../../timeline-supplement';

@Component({
  selector: 'app-timeline-supplement',
  templateUrl: './timeline-supplement.component.html',
  styleUrls: ['./timeline-supplement.component.css']
})
export class TimelineSupplementComponent implements OnInit {

  @Input('supplement') supplement: KintTimelineSupplement;

  constructor() { }

  ngOnInit() {
    console.log('Supplement of type: ' + this.supplement.type);
  }

}
