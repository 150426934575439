import { Directive, ElementRef, Input, OnInit, DoCheck, HostListener } from '@angular/core';
import { KintPhoto } from '../KintObject/kint-photo';
import { LogService } from '../Log/log.service';
import { PhotoSelectionService } from './photo-selection.service';

/**
 * Attribute-Directive used to display a photo on the current Element (as a background)
 * This directive handles:
 *  - Selecting the correct "alternate-size" photo for reduced bandwidth
 *  - Cropping to a the top-portion of Lanscape photos
 *  - Retrying alternate-images when the original image fails
 *  - Adding special classes for lazy-loading (ImageRecycler)
 */

 // JQuery is loaded separately ...naughty naughty...
declare var $: any;


@Directive({
  selector: '[appPhotoDisplay]'
})
export class PhotoDisplayDirective implements OnInit, DoCheck {

  @Input() photoData: KintPhoto;
  @Input() isBackgroundImage: boolean;
  @Input() fallbackSrc: string;
  @Input() sizeToElement: boolean;
  @Input() sizeToHeight: boolean;

  private FALLBACK_AVATAR = '/img/avatarPlaceholder.png';
  private element: ElementRef;
  private logService: LogService;
  private photoSelectionService: PhotoSelectionService;
  private initialized = false;

  // List of links which we have tried to use, but didn't work
  private failedLinks: string[] = [];

  // Link that we are currently viewing/loading
  private currentLink: string;

  // Track the current photo so we know when changes happen (ngDoCheck)
  private currentPhotoId: string;

  constructor(el: ElementRef,
              logService: LogService,
              photoSelectionService: PhotoSelectionService) {
    this.element = el;
    this.logService = logService;
    this.photoSelectionService = photoSelectionService;
  }

  /**
   * Initialize the component
   */
  ngOnInit() {

    this.initialized = true;

    this.element.nativeElement.style.backgroundColor = '#DADADA';

    // Grab the current Photo id
    if (this.photoData) {
      this.currentPhotoId = this.photoData.id;
    }

    // Select the best image to display
    this.chooseBestImage();
    this.recalculateSizes();
  }


  /**
   * Select the Best Image to display (sizing, etc)
   */
  chooseBestImage(): void {

    // Select the smallest possible photo that will do the job for us. Choices include:
    // 1. photo.alternate_sizes[ {height: xxxx, width: xxxxx, link: xxxxx}...]
    // 2. photo.link
    // 3. photo.alternateLinks['xxxxxxxx', 'yyyyyyyy', 'zzzzzzzzz']

    if (!this.photoData || !this.photoData.id) {
      if (!this.photoData) {
        this.logService.error('Photo object missing directive!');
      }
      if (!this.photoData.id) {
        this.logService.error('Photo ID missing in directive!');
      }

      this.logService.error('Photo not supplied to photo-display directive!');
      return;
    }

    const bestLink = this.photoSelectionService.chooseBestImage(this.photoData, $(this.element.nativeElement), this.failedLinks);
    if (bestLink) {
      this.setImageLink(bestLink);
    }

  }


  /**
   * Set the Image link to the current element (background image OR an img element)
   */
  setImageLink(link: string): void {

    this.currentLink = link;

    // Fill the entire background, cropping if needed
    if (this.isBackgroundImage) {
      this.element.nativeElement.style.backgroundImage = 'url(' + link + ')';
      this.element.nativeElement.style.backgroundSize = 'cover';
      this.element.nativeElement.style.backgroundRepeat = 'no-repeate';
      this.element.nativeElement.style.backgroundColor = '#DADADA';
      this.element.nativeElement.style.backgroundPosition = 'center';

      this.handleLoadEventForBackgroundImage(link);
    } else {
      this.element.nativeElement.src = link;
    }

    this.recalculateSizes();

  }


  /**
   * Recalculate the size that this element is taking up
   * Depending on the call, this may update the size of the element
   * OR just reposition the photo within an existing frame
   */
  recalculateSizes(): void {

    const photo = this.photoData;
    const elementWidth = this.element.nativeElement.clientWidth;
    const elementHeight = this.element.nativeElement.clientHeight;

    if (photo && this.sizeToElement && photo.size) {

      const height = Math.floor(((elementWidth * photo.size.height) / photo.size.width)) + 'px';
      this.element.nativeElement.style.height = height;

    }

    if (photo && this.sizeToHeight && photo.size) {
      const width = Math.floor(((elementHeight * photo.size.width) / photo.size.height)) + 'px';
      this.element.nativeElement.style.width = width;
    }

    if (this.isBackgroundImage) {

      // Handle any cropping which may be going on!
      if (photo && photo.size) {

        // If our element is NOT portrait (include square elements)
        if (elementWidth >= elementHeight) {

          // And our photo IS portrait
          if (photo.size.height >= photo.size.width) {

            // We have a portrait photo in a landscape frame!
            // If < 20% of the photo will be cropped, that's fine. Just center it
            // If > 20% of the photo will be cropped, then only drop out the top 10% and crop the rest from the bottom

            // We know our photo is the full width of the frame. Figure out
            // how tall our image SHOULD render without any cropping
            const uncroppedHeight = (elementWidth * photo.size.height) / photo.size.width;

            const croppedPercentage = (uncroppedHeight - elementHeight) / uncroppedHeight;
            if (croppedPercentage <= 0.2) {
              // no problem. Just evenly center the photo
              this.element.nativeElement.style.backgroundPositionY = 'center';

            } else {

              // Lots of cropping. Weight it towards the top (only trim off the top 10%)
              const tenPercentHeight = uncroppedHeight * 0.10;
              const positionString = '-' + tenPercentHeight + 'px';

              this.element.nativeElement.style.backgroundPositionY = positionString;
            }
          }
        }
      }
    }

  }


  // Our background images load via CSS:   background-image: url("xxxxxx")
  // We need to be able to handle load-failures on those. To do that, we can
  // internally allocated a new Image object and set it's link equal to the same
  // background image link. When that loads/fails, we call the correct function
  handleLoadEventForBackgroundImage(link): void {

    let img = new Image();
    img.onload = (e: any) => {
      this.onImageLoaded(e);
      img = null;
    };
    img.onerror = (e: any) => {
      this.logService.error('Error loading image: ' + e);
      this.onImageLoadError(e);
      img = null;
    };
    img.src = link;
  }


  /**
   * Watch for changes in the photo id
   */
  ngDoCheck() {
    if (!this.initialized || !this.photoData) {
      return;
    }

    if (this.currentPhotoId !== this.photoData.id) {
      this.currentPhotoId = this.photoData.id;
      this.chooseBestImage();
    }
  }


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.recalculateSizes();
    this.chooseBestImage();
  }

  @HostListener('load', ['$event'])
  onImageLoaded(event) {
    // Image loaded. Nothing to do right now.
  }

  @HostListener('error', ['$event'])
  onImageLoadError(event) {
    this.logService.warn('Failed to load image. Attempting fallback...');

    // Store the link that failed.
    this.failedLinks.push(this.currentLink);

    const bestLink = this.photoSelectionService.chooseBestImage(this.photoData, $(this.element.nativeElement), this.failedLinks);
    if (bestLink !== this.currentLink) {
      this.setImageLink(bestLink);
    } else {
      // Out of alternates. Just use our fallback
      this.logService.error('Error loading image. No alternates available.');
      if (this.fallbackSrc) {
        this.setImageLink(this.fallbackSrc);
      }
    }

  }

}
