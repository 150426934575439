import { OnInit } from '@angular/core';
import { KintTimelineSupplementText } from '../../timeline-supplement';
var TimelineSupplementStoryComponent = /** @class */ (function () {
    function TimelineSupplementStoryComponent() {
    }
    TimelineSupplementStoryComponent.prototype.ngOnInit = function () {
    };
    return TimelineSupplementStoryComponent;
}());
export { TimelineSupplementStoryComponent };
