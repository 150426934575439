import { Component, OnInit, Input } from '@angular/core';
import { KintTimelineSupplementAudio } from '../../timeline-supplement';

@Component({
  selector: 'app-timeline-supplement-audio',
  templateUrl: './timeline-supplement-audio.component.html',
  styleUrls: ['./timeline-supplement-audio.component.css']
})
export class TimelineSupplementAudioComponent implements OnInit {

  @Input('supplement') supplement: KintTimelineSupplementAudio;

  constructor() { }

  ngOnInit() {
  }

}
