import { Component, OnInit, Input, Inject } from '@angular/core';
import { KintTimeline } from '../../kint-timeline';
import { DateHelper } from 'src/app/Util/Date/DateHelper';
import { KintTimelineEntry } from '../../kint-timeline-entry';


@Component({
  selector: 'app-timeline-entry-horizontal',
  templateUrl: './timeline-entry-horizontal.component.html',
  styleUrls: ['./timeline-entry-horizontal.component.css']
})
export class TimelineEntryHorizontalComponent implements OnInit {

  @Input('timelineEntry') timelineEntry: KintTimelineEntry;
  @Input('timeline') timeline: KintTimeline;

  labelTop: string;
  labelBottom: string;

  constructor(@Inject('ModalService') private ng1ModalService: any) {
    this.labelTop = '';
    this.labelBottom = '';
  }

  ngOnInit() {

    console.log(this.timelineEntry);

    // Build all of the supplements for this entry
    if (this.timelineEntry.photo) {

      const photo = this.timelineEntry.photo;

      // Build out the Date/Time labels
      const date = new Date(photo.date.isoDate);
      if (this.timeline.timelineFormat.lowestLevelIsTime) {
        this.labelTop = DateHelper.getShortMonthFromKintDate(photo.date) +
                        DateHelper.getDayOfMonthFromKintDate(photo.date);
        this.labelBottom = DateHelper.getTime(date);
      } else {
        this.labelTop = DateHelper.getShortMonthFromKintDate(photo.date);
        this.labelBottom = DateHelper.getDayOfMonthFromKintDate(photo.date);
      }

      // If we only have a single label, make the top label more pronounced because it's now the primary label
      if (!this.labelBottom || (this.labelBottom.trim().length === 0)) {
        this.labelTop = this.labelTop.toUpperCase();
      }

    }

  }

  openPhotoPreview(): void {
    this.ng1ModalService.openPhotoPopup(this.timelineEntry.photo);
  }

}
