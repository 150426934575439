import { DateHelper } from '../Util/Date/DateHelper';
/**
 * Kintribute Date class that we use. This supports optional days and months
 *
 * Note: day: -1 means "unknown day"
 * Note: month: -1 means "unknown month"
 */
var KintDate = /** @class */ (function () {
    function KintDate(day, month, year, isoDate) {
        this.day = day;
        this.month = month;
        this.year = year;
        this.isoDate = isoDate;
        if (!isoDate && year) {
            var dayToUse = (this.day != null) && (this.day >= 0) ? this.day : 0;
            var monthToUse = (this.month > 0) ? (this.month - 1) : 0; // (Javascript months are 0-based, thus the 'month -1')
            var yearToUse = (this.year >= 0) ? this.year : 0;
            // manually build the ISO Date so we don't bias it with our current timezone
            var generatedIsoDate = DateHelper.buildUnbiasZeroBasedISODateString(yearToUse, monthToUse, dayToUse);
            this.isoDate = generatedIsoDate;
        }
    }
    /**
     * Builds a new KintDate object from the KintDate object which may or may not exist
     *
     * Note: May return null
     */
    KintDate.fromDate = function (kintDate) {
        if (!kintDate) {
            return null;
        }
        return new KintDate(kintDate.day, kintDate.month, kintDate.year, kintDate.isoDate);
    };
    /**
     * See if the given date is equivilent to "not dated"
     * @param date
     */
    KintDate.isUndated = function (date) {
        if (!date) {
            return true;
        }
        // If no year, then it's undated
        if (date.year == null) {
            return true;
        }
        if ((date.day < 0) && (date.month < 0) && (date.year < 0)) {
            return true;
        }
        return false;
    };
    return KintDate;
}());
export { KintDate };
