import { UpgradeModule } from '@angular/upgrade/static';
var ɵ0 = function ($injector) { return $injector.get('MessageBoxService'); }, ɵ1 = function ($injector) { return $injector.get('AppDataModelService'); }, ɵ2 = function ($injector) { return $injector.get('APIService'); }, ɵ3 = function ($injector) { return $injector.get('DOMService'); }, ɵ4 = function ($injector) { return $injector.get('FaceComparePromptService'); }, ɵ5 = function ($injector) { return $injector.get('LinkHelperService'); }, ɵ6 = function ($injector) { return $injector.get('ModalService'); };
// import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
var AppModule = /** @class */ (function () {
    function AppModule(upgrade) {
        this.upgrade = upgrade;
    }
    AppModule.prototype.ngDoBootstrap = function () {
        // Load the Legacy Kintribute app prior to Angular6
        this.upgrade.bootstrap(document.body, ['KintributeApp']);
    };
    return AppModule;
}());
export { AppModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6 };
