import { KintLocation } from '../KintObject/kint-location';
import { KintAudio } from '../KintObject/kint-audio';
import { KintExternalLink } from '../KintObject/kint-photo';

export interface KintTimelineSupplement {
  type: KintTimelineSupplementTypes;
}


export class KintTimelineSupplementAudio implements KintTimelineSupplement {
  audioStories: KintAudio[];
  type: KintTimelineSupplementTypes;

  constructor(audioStories: KintAudio[]) {
    this.type = KintTimelineSupplementTypes.AUDIO;
    this.audioStories = audioStories;
  }
}


export class KintTimelineSupplementText implements KintTimelineSupplement {
  text: string;
  type: KintTimelineSupplementTypes;

  constructor(text: string) {
    this.type = KintTimelineSupplementTypes.TEXT;
    this.text = text;
  }
}


export class KintTimelineSupplementLink implements KintTimelineSupplement {
  externalLink: KintExternalLink;
  type: KintTimelineSupplementTypes;

  constructor(externalLink: KintExternalLink) {
    this.type = KintTimelineSupplementTypes.LINK;
    this.externalLink = externalLink;
  }
}


export class KintTimelineSupplementMap implements KintTimelineSupplement {
  location: KintLocation;
  type: KintTimelineSupplementTypes;

  constructor(location: KintLocation) {
    this.type = KintTimelineSupplementTypes.MAP;
    this.location = location;
  }
}


export enum KintTimelineSupplementTypes {
  TEXT = 'TEXT',
  LINK = 'LINK',
  AUDIO = 'AUDIO',
  MAP = 'MAP'
}
