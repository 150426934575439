/**
 * These interfaces define the data that we recieve from the Kintribute API
 *
 * We are deliberately not creating instance methods because want to be able
 * to use the classes and interfaces interchangibly (without explicitly calling
 * construtors for all of the JSON data. Basically, we just want to avoid
 * converting back and forth between the Javascript and Typescript objects
 */
var PhotoEssay = /** @class */ (function () {
    function PhotoEssay() {
        this.id = '';
        this.name = '';
        this.layout = {
            rows: []
        };
    }
    return PhotoEssay;
}());
export { PhotoEssay };
var PhotoEssayRow = /** @class */ (function () {
    function PhotoEssayRow(type, tiles) {
        this.type = type;
        this.tiles = tiles;
    }
    return PhotoEssayRow;
}());
export { PhotoEssayRow };
var PhotoEssayTile = /** @class */ (function () {
    function PhotoEssayTile(type, photo, caption) {
        this.type = type;
        this.photo = photo;
        this.caption = caption;
    }
    return PhotoEssayTile;
}());
export { PhotoEssayTile };
export var PhotoEssayRowTypes;
(function (PhotoEssayRowTypes) {
    PhotoEssayRowTypes["CAPTION"] = "CAPTION";
    PhotoEssayRowTypes["SINGLE_PHOTO"] = "SINGLE_PHOTO";
    PhotoEssayRowTypes["SIDE_BY_SIDE_PHOTOS"] = "SIDE_BY_SIDE_PHOTOS";
    PhotoEssayRowTypes["THREE_COLUMN_PHOTOS"] = "THREE_COLUMN_PHOTOS";
    PhotoEssayRowTypes["COLLAGE_THREE_PHOTOS"] = "COLLAGE_THREE_PHOTOS";
    // LEGACY TYPES
    PhotoEssayRowTypes["SINGLE_LANDSCAPE"] = "SINGLE_LANDSCAPE";
    PhotoEssayRowTypes["SINGLE_PORTRAIT"] = "SINGLE_PORTRAIT";
    PhotoEssayRowTypes["SIDE_BY_SIDE_LANDSCAPE"] = "SIDE_BY_SIDE_LANDSCAPE";
    PhotoEssayRowTypes["SIDE_BY_SIDE_PORTRAIT"] = "SIDE_BY_SIDE_PORTRAIT";
    PhotoEssayRowTypes["COLLAGE_TOP_HEAVY"] = "COLLAGE_TOP_HEAVY";
    PhotoEssayRowTypes["COLLAGE_LEFT_HEAVY"] = "COLLAGE_LEFT_HEAVY";
})(PhotoEssayRowTypes || (PhotoEssayRowTypes = {}));
export var PhotoEssayTileTypes;
(function (PhotoEssayTileTypes) {
    PhotoEssayTileTypes["CAPTION"] = "CAPTION";
    PhotoEssayTileTypes["PHOTO"] = "PHOTO";
})(PhotoEssayTileTypes || (PhotoEssayTileTypes = {}));
