import { downgradeComponent } from '@angular/upgrade/static';
import { Component, OnInit, Input, ElementRef, HostListener, Inject, ViewEncapsulation, DoCheck } from '@angular/core';
import { KintPhoto } from 'src/app/KintObject/kint-photo';
import { MessageBox } from 'src/app/MessageBox/message-box.service';
import { PhotoEssayRow, PhotoEssayRowTypes, PhotoEssayTile } from '../photo-essay';
import { PhotoEssayLayoutService } from '../photo-essay-layout/photo-essay-layout.service';
import { LogService } from 'src/app/Log/log.service';
import { PhotoService } from 'src/app/Photo/photo.service';
import { KintSize } from 'src/app/KintObject/kint-size';


// Naughty... Using jQuery
declare var $: any;

// Angular is loaded separately
declare var angular: any;


@Component({
  selector: 'app-photo-essay-row',
  templateUrl: './photo-essay-row.component.html',
  styleUrls: ['./photo-essay-row.component.css'],
  encapsulation: ViewEncapsulation.None // TODO;  Eventually, need to re-add this, bu tneeded because of AngularJS parent classes
})
export class PhotoEssayRowComponent implements OnInit, DoCheck {

  @Input('row') row: PhotoEssayRow;
  @Input('editor') editor: any; // PhotoEssayEditor object from Angular1

  /**
   * Member Variables
   */
  private MAX_ROW_WIDTH = 800;
  private MAX_ROW_HEIGHT = 700;
  private isMobile = false;
  private rowCss = {
    height: '0px',
    width: '0px'
  };
  private tilePadding = 28; // 2em
  private resizeId: any;
  private serializedPhotoIdsString = '';


  /**
   * Constructor
   *
   * @param elementRef
   * @param logService
   * @param photoService
   * @param photoEssayLayoutService
   * @param messageBox
   */
  constructor(private elementRef: ElementRef,
              private logService: LogService,
              private photoService: PhotoService,
              private photoEssayLayoutService: PhotoEssayLayoutService,
              private messageBox: MessageBox,
              @Inject('AppDataModelService') private appDataModelService: any) {
    this.logService.info('PhotoEssayRowComponent Constructor');
  }


  /**
   * Initialize the component
   */
  ngOnInit() {
    // TODO:  Figure out how to arrange all of the tiles within this row!
    this.logService.info('PhotoEssayRowComponent ngOnInit');
    this.resizeTiles();
    this.serializedPhotoIdsString = this.buildSerializedPhotoIdsString();
  }


  /**
   * Monitor for Photo changes within this row.  If the photo changes, then
   * make sure to rebuild the row
   */
  ngDoCheck() {
    const newSerializedPhotoIdsString = this.buildSerializedPhotoIdsString();
    if (newSerializedPhotoIdsString !== this.serializedPhotoIdsString) {
      this.resizeTiles();
      this.serializedPhotoIdsString = newSerializedPhotoIdsString;
    }
  }


  /**
   * Listen for screen size changes
   *
   * TODO:  Watch these screen events somewhere else and consolidate them!
   *        Having everyone subscribe to the actual window resize is not recommended!
   *
   * @param event
   */
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    clearTimeout(this.resizeId);
    this.resizeId = setTimeout(() => {
      this.resizeTiles();
     }, 500);
  }


  /**
   * Make sure all of our tiles have 'css' properties on them
   * so we can bind to them directly
   *
   * @memberof PhotoEssayRowComponent
   */
  addCssToTiles(): void {
    for (const tile of this.row.tiles) {
      tile.css = {
        position: 'relative',
        top: '0px',
        left: '0px',
        width: '0px',
        height: '0px',
        margin: 'auto',
        padding: ''
      };
    }
    this.rowCss = {
      width: '0px',
      height: '0px'
    };
  }


  /**
   * Resize the tiles within the row so that they all fit properly
   */
  resizeTiles(): void {

    this.isMobile = this.appDataModelService.isMobile();

    this.tilePadding = 14; // 1em
    if (this.isMobile) {
      this.tilePadding = 3;
    }

    // Compensate for less space on mobile
    this.MAX_ROW_WIDTH = 800;
    if (window.innerWidth < 800) {
      this.MAX_ROW_WIDTH = window.innerWidth - 8; // full-width with a little padding
    }

    this.addCssToTiles();

    // Captions don't need sizing
    if (this.row.type === PhotoEssayRowTypes.CAPTION) {
      return;
    }

    // Calculate the different stylings for our photos
    switch (this.row.type) {
      case PhotoEssayRowTypes.SINGLE_PHOTO:
      case PhotoEssayRowTypes.SINGLE_PORTRAIT:
      case PhotoEssayRowTypes.SINGLE_LANDSCAPE:
      case PhotoEssayRowTypes.SIDE_BY_SIDE_PHOTOS:
      case PhotoEssayRowTypes.SIDE_BY_SIDE_LANDSCAPE:
      case PhotoEssayRowTypes.SIDE_BY_SIDE_PORTRAIT:
      case PhotoEssayRowTypes.THREE_COLUMN_PHOTOS: {

        let totalWidth = 0;

        // Size all of the tiles to the max size (700px)
        for (const tile of this.row.tiles) {
          const photo = tile.photo;
          const calculatedWidth = this.photoService.calculateWidth(photo, this.MAX_ROW_HEIGHT);
          tile.css.height = this.MAX_ROW_HEIGHT;
          tile.css.width = calculatedWidth;
          totalWidth += calculatedWidth;
        }

        // Account for spacing between the tiles (2em between each photo)
        const paddingSize = (this.row.tiles.length - 1) * (this.tilePadding * 2);

        // Shrink the content down so that it all fits in the row
        if ((totalWidth + paddingSize) > this.MAX_ROW_WIDTH) {
          const scaleBy = (this.MAX_ROW_WIDTH - paddingSize) / totalWidth;
          for (const tile of this.row.tiles) {
            tile.css.height = tile.css.height * scaleBy;
            tile.css.width = tile.css.width * scaleBy;
          }
        }


        // Cleanup on all tiles (apply units and padding)
        for (let i = 0; i < this.row.tiles.length; i++) {
          const tile = this.row.tiles[i];
          tile.css.height = tile.css.height + 'px';
          tile.css.width = tile.css.width + 'px';
          tile.css.margin = '0px';
        }

        // Add Padding to all of the tiles (if needed)
        if (this.row.tiles.length === 1) {
          this.row.tiles[0].css.margin = '0px';
        } else if (this.row.tiles.length === 2) {
          this.row.tiles[0].css.margin = `0px ${this.tilePadding / 2.0}px 0px 0px`;
          this.row.tiles[1].css.margin = `0px 0px 0px ${this.tilePadding / 2.0}px `;
        } else if (this.row.tiles.length === 3) {
          this.row.tiles[0].css.margin = `0px ${this.tilePadding / 2.0}px  0px 0px`;
          this.row.tiles[1].css.margin = `0px ${this.tilePadding / 2.0}px  0px ${this.tilePadding / 2.0}px`;
          this.row.tiles[2].css.margin = `0px 0px 0px ${this.tilePadding / 2.0}px`;
        }

        break;
      }
      case PhotoEssayRowTypes.COLLAGE_THREE_PHOTOS:
      case PhotoEssayRowTypes.COLLAGE_TOP_HEAVY:
      case PhotoEssayRowTypes.COLLAGE_LEFT_HEAVY:  {

        this.arrangeCollage(this.row.tiles);
        break;
      }
      default: {
        this.logService.error('Error displaying photo essay row. Type: ' + this.row.type);
      }
    }

  }


  /**
   * Returns true if this row is a "collage" type
   */
  isCollage(): boolean {
    return ((this.row.type === PhotoEssayRowTypes.COLLAGE_THREE_PHOTOS) ||
            (this.row.type === PhotoEssayRowTypes.COLLAGE_LEFT_HEAVY) ||
            (this.row.type === PhotoEssayRowTypes.COLLAGE_TOP_HEAVY));
  }

  /**
   * Arrange the photo tiles into a collage
   * @param tiles
   */
  arrangeCollage(tiles: PhotoEssayTile[]): void {

    if (tiles.length !== 3) {
      this.logService.error('Error arranging photo collage. 3 photos needed');
      return;
    }


    const leftTile = tiles[0];
    const rightTopTile = tiles[1];
    const rightBottomTile = tiles[2];

    const leftTileSize = this.getPhotoSizeFromTile(leftTile);
    const rightTopTileSize = this.getPhotoSizeFromTile(rightTopTile);
    const rightBottomTileSize = this.getPhotoSizeFromTile(rightBottomTile);

    let leftTileScale = 1.0;
    let rightTopTileScale = 1.0;
    let rightBottomTileScale = 1.0;

    // First tile goes on the left
    const firstPhotoWidth = this.photoService.calculateWidth(leftTile.photo, this.MAX_ROW_HEIGHT);
    leftTileScale = firstPhotoWidth / leftTileSize.width;

    // Second two tiles need to be equal width. Scale to the top-right's width
    rightBottomTileScale = rightTopTileSize.width / rightBottomTileSize.width;

    // Next, see how tall the right-column will be currently.
    const rightColumnHeight = rightTopTileSize.height * rightTopTileScale + rightBottomTileSize.height * rightBottomTileScale;

    // The right column needs to be MAX_ROW_HEIGHT px tall, just like the left tile.
    const rightColumnScale = this.MAX_ROW_HEIGHT / rightColumnHeight;
    rightTopTileScale = rightTopTileScale * rightColumnScale;
    rightBottomTileScale = rightBottomTileScale * rightColumnScale;


    // Now, both columns are scaled to MAX_ROW_HEIGHT height. However, now, the full width
    // might be larger than this.MAX_ROW_WIDTH
    const totalWidth = leftTileSize.width * leftTileScale + rightBottomTileSize.width * rightBottomTileScale;

    // If we are too wide, scale everything down until we fit
    let scaleEverythingScale = 1.0;
    if (totalWidth > this.MAX_ROW_WIDTH) {
      scaleEverythingScale = this.MAX_ROW_WIDTH / totalWidth;
      leftTileScale = leftTileScale * scaleEverythingScale;
      rightTopTileScale = rightTopTileScale * scaleEverythingScale;
      rightBottomTileScale = rightBottomTileScale * scaleEverythingScale;
    }

    // Our scaling is all set up. Now apply the absolute positioning on the elements
    leftTile.css.position = 'absolute';
    leftTile.css.top = '0px';
    leftTile.css.left = '0px';
    leftTile.css.right = '';
    leftTile.css.width = (leftTileSize.width * leftTileScale) + 'px';
    leftTile.css.height = (leftTileSize.height * leftTileScale) + 'px';
    leftTile.css.padding = `${this.tilePadding / 2.0}px`;

    rightTopTile.css.position = 'absolute';
    rightTopTile.css.top = '0px';
    rightTopTile.css.left = '';
    rightTopTile.css.right = '0px';
    rightTopTile.css.width = (rightTopTileSize.width * rightTopTileScale) + 'px';
    rightTopTile.css.height = (rightTopTileSize.height * rightTopTileScale) + 'px';
    rightTopTile.css.padding = `${this.tilePadding / 2.0}px`;

    rightBottomTile.css.position = 'absolute';
    rightBottomTile.css.top = '';
    rightBottomTile.css.left = '';
    rightBottomTile.css.right = '0px';
    rightBottomTile.css.bottom = '0px';
    rightBottomTile.css.width = (rightBottomTileSize.width * rightBottomTileScale) + 'px';
    rightBottomTile.css.height = (rightBottomTileSize.height * rightBottomTileScale) + 'px';
    rightBottomTile.css.padding = `${this.tilePadding / 2.0}px`;

    this.rowCss.width = totalWidth * scaleEverythingScale + 'px';
    this.rowCss.height = leftTile.css.height;

  }



  /**
   * Get the non-scaled photo size from the given tile
   *
   * @param tile
   */
  getPhotoSizeFromTile(tile: PhotoEssayTile): KintSize {

    // Note: Treat any missing photos as 500x500 squares
    const kintSize: KintSize = {
      height: 500,
      width: 500
    };

    if (tile.photo && tile.photo.size) {
      kintSize.height = tile.photo.size.height;
      kintSize.width = tile.photo.size.width;
    }

    return kintSize;
  }


  /**
   * Check to see if this is a row that should be displayed or not
   *
   * @returns {boolean}
   * @memberof PhotoEssayRowComponent
   */
  shouldShow(): boolean {
    if (!this.editor.isEditMode && this.photoEssayLayoutService.isEmptyCaptionRow(this.row)) {
      return false;
    }
    return true;
  }


  /**
   * Check to see if this row (or a child) has a focus
   *
   * @returns {boolean}
   * @memberof PhotoEssayRowComponent
   */
  hasFocus(): boolean {
    // TODO: Probably shouldn't have jquery calls here, but not sure on other options
    return $(this.elementRef.nativeElement).find('*:focus').length > 0;
  }


  /**
   * Add a new row of the given type
   *
   * @param {PhotoEssayRowTypes} rowType
   * @memberof PhotoEssayRowComponent
   */
  addRow(rowType: PhotoEssayRowTypes): void {
    const insertAfterRowIndex = this.editor.getIndexOfRow(this.row);
    this.editor.addRow(rowType, insertAfterRowIndex);
  }


  /**
   * Delete the whole row. Delegate this to the parent!
   *
   * @returns {void}
   * @memberof PhotoEssayRowComponent
  /**
   *
   *
   * @returns {void}
   * @memberof PhotoEssayRowComponent
   */
  deleteRow(): void {

    const photosInRow: KintPhoto[] = this.photoEssayLayoutService.getPhotosFromRow(this.row);
    let photoOrPhotos = 'Photos';
    if (photosInRow.length === 1) {
      photoOrPhotos = 'Photo';
    }

    // Confirmation dialog!
    let title = 'Delete photo row?';
    let warning = photosInRow.length + ' ' + photoOrPhotos + ' will be deleted. This action cannot be undone.';

    // Different confirmation for text-only
    if (this.row.type === PhotoEssayRowTypes.CAPTION) {
      title = 'Delete caption?';
      warning = 'This caption will be deleted. This action cannot be undone.';
    } else  if (photosInRow.length === 0) {
      // Photo row with no photos. Go ahead and delete it!
      this.deleteRowConfirmed();
      return;
    }

    const confirmMessage = 'Delete';
    const cancelMessage = 'Cancel';

    const component = this;
    const confirmDlg = this.messageBox.confirmPromptAdvanced(title, warning, confirmMessage, cancelMessage);
    confirmDlg.result.then(
      function() {
        component.deleteRowConfirmed();
      },
      function() {
        // tslint:disable-next-line:no-console
        console.info('Cancelled row deletion');
      }
    );
  }


  /**
   * Deletion has been confirmed. Perform the deletion
   *
   * @memberof PhotoEssayRowComponent
   */
  deleteRowConfirmed(): void {

    // Add a max-height of full-height so that we can animate down to max-height of 0!
    const elementHeightString = $(this.elementRef.nativeElement)[0].getBoundingClientRect().height + 'px';
    $(this.elementRef.nativeElement).find('.photo-essay-row-row').css({'max-height': elementHeightString});

    // Wait just a moment for the DOM to catch up, and the mark it as deleted
    // After animating, remove the row!
    setTimeout(() => {
      $(this.elementRef.nativeElement).find('.photo-essay-row-row').addClass('photo-essay-row-row-deleted');
      setTimeout(() => {
        this.editor.deleteRow(this.row);
      }, 500);
    }, 100);

  }


  /**
   * Build a serialized string representing all of the photo ids in this row
   *
   * @returns {string}
   * @memberof PhotoEssayRowComponent
   */
  buildSerializedPhotoIdsString(): string {
    let serializedPhotoIdsString = '';
    for (const tile of this.row.tiles) {
      if (tile.photo && tile.photo.id) {
        serializedPhotoIdsString += ',' + tile.photo.id;
      }
    }
    return serializedPhotoIdsString;
  }
}

// Provide a downgraded version of this to run with AngularJS
angular.module('KintributeApp')
  .directive('photoEssayRow', downgradeComponent(
    {
      component: PhotoEssayRowComponent,
      inputs: ['row', 'editor']
    }
  ));
