import { OnInit } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
var SaveBarComponent = /** @class */ (function () {
    function SaveBarComponent() {
        this.isSaving = false;
        this.saveHandler = new EventEmitter();
        this.cancelHandler = new EventEmitter();
    }
    SaveBarComponent.prototype.ngOnInit = function () {
        console.log('SaveBarComponent.isSaving: ' + this.isSaving);
    };
    SaveBarComponent.prototype.cancel = function () {
        this.cancelHandler.emit('canceling');
    };
    SaveBarComponent.prototype.save = function () {
        this.saveHandler.emit('saving');
    };
    return SaveBarComponent;
}());
export { SaveBarComponent };
// Provide a downgraded version of this to run with AngularJS
angular.module('KintributeApp')
    .directive('appSaveBar', downgradeComponent({
    component: SaveBarComponent,
    inputs: ['isSaving'],
    outputs: ['saveHandler', 'cancelHandler']
}));
