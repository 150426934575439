<div [class.timeline-block-vertical-level-1]="timelineBlock.level == 1"
     [class.timeline-block-vertical-level-2]="timelineBlock.level == 2"
     [class.timeline-block-vertical-level-3]="timelineBlock.level == 3"
     [class.timeline-block-vertical-mobile]="isMobile()">

  <!-- Top-Level block -->
  <div *ngIf="timelineBlock.level == 1" 
       class="timeline-block-vertical-level-1-header">
      {{ timelineBlock.header }}
  </div>

  <!-- 2nd Level Block -->
  <div *ngIf="timelineBlock.level == 2" 
       class="timeline-block-vertical-level-2-header">

    <div class="timeline-block-vertical-level-2-text-holder">
      <div class="timline-block-level-2-text">
        {{ timelineBlock.header }}
      </div>
    </div>

    <div class="timeline-block-vertical-level-2-line-box timeline-block-vertical-level-2-line-box-top">
    </div>
    <div class="timeline-block-vertical-level-2-line-box timeline-block-vertical-level-2-line-box-bottom">
    </div>

  </div>
  

  <!-- 3rd Level Block -->
  <div *ngIf="timelineBlock.level == 3" class="timeline-block-vertical-level-3-header">
    {{ timelineBlock.header }}
  </div>


  <!-- Nested Timeline Blocks -->
  <!-- <div> SubBlocks: {{timelineBlock.timelineBlocks.length}} </div> -->
  <div *ngFor="let timelineBlock of timelineBlock.timelineBlocks">
    <app-timeline-block-vertical [timelineBlock]="timelineBlock" [timeline]="timeline">
    </app-timeline-block-vertical>
  </div>

  <!-- Stand-alone Timeline entries -->
  <!-- <div> Entries: {{timelineBlock.timelineEntries.length}} </div> -->
  <div *ngFor="let timelineEntry of timelineBlock.timelineEntries; index as index"
       [ngClass]="{'timeline-entry-vertical-extra-padding': (index !== 0) }">
    <app-timeline-entry-vertical [timelineEntry]="timelineEntry" [timeline]="timeline">
    </app-timeline-entry-vertical>
  </div>

</div>