import { KintLocation, KintGeo, KintAddress } from 'src/app/KintObject/kint-location';
import { LocationAutoCompleteResult } from '../LocationAutoCompleteResult';

export class LocationAutoCompletePhotonResult implements LocationAutoCompleteResult {

  public name = '';
  public addressShort = '';
  public address: KintAddress;
  public position: KintGeo;
  public poi: any;

  constructor(public feature: any) {

    if (feature.properties) {

      const featureProperties = feature.properties;
      this.fillMissingData(featureProperties);

      // Get name of Point of Interest (if any)
      this.name = featureProperties.name;

      // Build a KintAddressss
      this.address = new KintAddress(
        featureProperties.name,
        featureProperties.housenumber,
        featureProperties.street,
        featureProperties.city,
        featureProperties.state,
        featureProperties.country,
        featureProperties.postcode);

      // Build a short address
      this.addressShort = KintAddress.getShortAddress(this.address);

      // If no name, then use the number+street as the name and the rest as 'extra'
      if (!this.name) {
        if (this.address.houseNumber && this.address.street) {
          this.name = this.address.houseNumber + ' ' + this.address.street;
        }
      }

      // If still no name, then use the whole address
      if (!this.name) {
        this.name = this.addressShort;
      }
    }


    if (feature.geometry && feature.geometry.coordinates) {
      this.position = new KintGeo(feature.geometry.coordinates[1], feature.geometry.coordinates[0]);
    }

  }


  // Fill in missing data in the 'address'.
  fillMissingData(featureProperties: any): void {

    // if the "osm_value" = "city" (or similar), then we know the 'name' field
    // represents a city. Therefore, we need to stuff that 'city' field

    switch (featureProperties.osm_value) {
      case 'city':
      case 'town':
      case 'neighbourhood':
      case 'village':
      case 'suburb':
      case 'hamlet':
      case 'borough':
      case 'quarter':
      case 'plot':
      case 'locality':
      case 'square':
      {
        if (!featureProperties.city) {
          featureProperties.city = featureProperties.name;
        }
        break;
      }
      default: {
        break;
      }
    }

    if (featureProperties.osm_key === 'highway') {
      // If no street yet, then use this as the street name
      if (!featureProperties.street) {
        featureProperties.street = featureProperties.name;
      }
    }

  }




  /**
   * Build a KintLocation from this autocomplete data
   */
  toKintLocation(): KintLocation {
    const kintLocation = new KintLocation(this.name);
    kintLocation.name = this.name;
    kintLocation.address = this.address;
    kintLocation.addressShort = this.addressShort;
    kintLocation.position = this.position;

    return kintLocation;
  }
}

export interface ILocationAutoCompletePhotonResponse {
  features: any[];
}


export class LocationAutoCompletePhotonResponse implements ILocationAutoCompletePhotonResponse {
  features = [];
}


/**
 * Sample Response:
 *
 * {
   "features":[
      {
         "geometry":{
            "coordinates":[
               -93.24211951022394,
               44.8550204
            ],
            "type":"Point"
         },
         "type":"Feature",
         "properties":{
            "osm_id":2240382,
            "osm_type":"R",
            "extent":[
               -93.2446045,
               44.8567096,
               -93.2396337,
               44.8532413
            ],
            "country":"United States of America",
            "osm_key":"shop",
            "housenumber":"60",
            "city":"Bloomington",
            "street":"Lindau Lane",
            "osm_value":"mall",
            "postcode":"55425",
            "name":"Mall of America",
            "state":"Minnesota"
         }
      },
      {
         "geometry":{
            "coordinates":[
               2.2944990543196795,
               48.858260200000004
            ],
            "type":"Point"
         },
         "type":"Feature",
         "properties":{
            "osm_id":5013364,
            "osm_type":"W",
            "extent":[
               2.2933084,
               48.8590465,
               2.2956897,
               48.8574753
            ],
            "country":"France",
            "osm_key":"tourism",
            "housenumber":"5",
            "city":"Paris",
            "street":"Avenue Anatole France",
            "osm_value":"attraction",
            "postcode":"75007",
            "name":"Eiffel Tower",
            "state":"Ile-de-France"
         }
      }
      ...
      ...
      ...
 *
 *
 *
 */
