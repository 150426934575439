import { OnInit } from '@angular/core';
import { KintTimeline } from '../../kint-timeline';
import { downgradeComponent } from '@angular/upgrade/static';
var TimelineLayoutHorizontalComponent = /** @class */ (function () {
    function TimelineLayoutHorizontalComponent() {
    }
    TimelineLayoutHorizontalComponent.prototype.ngOnInit = function () {
    };
    return TimelineLayoutHorizontalComponent;
}());
export { TimelineLayoutHorizontalComponent };
// Provide a downgraded version of this to run with AngularJS
angular.module('KintributeApp')
    .directive('timelineLayoutHorizontal', downgradeComponent({
    component: TimelineLayoutHorizontalComponent,
    inputs: ['timeline']
}));
