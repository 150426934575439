import { KintPersonalPage } from '../KintObject/kint-personal-page';
import { UtilService } from '../Util/util/util.service';

export class PersonalPageEditor {

  //
  // Member Variables
  //
  public isEditMode = false;
  public isSaving = false;
  private personalPage: KintPersonalPage = null;
  private apiService: any = null;
  private UtilService = new UtilService();
  private backupPageEditor = null;

  /**
   * Constructor
   */
  constructor() {
    console.log('PersonalPageEditor.isSaving: ' + this.isSaving);
  }


  /**
   * Sets the Profile Page that we want to edit
   */
  setPersonalPage(personalPage: KintPersonalPage): void {
    this.personalPage = personalPage;
  }

  /**
   * Sets the API Service
   */
  setApiService(apiService: any) {
    this.apiService = apiService;
  }


  userCanEdit(): boolean {
    return this.personalPage && this.personalPage.userCanEdit;
  }

  /**
   * Enter Edit mode
   */
  startEditMode(): void {
    if (this.isEditMode) {
      // Already in edit mode. Carry on...
      return;
    }

    this.backupPageEditor = this.UtilService.deepCopy(this.personalPage);
    this.isEditMode = true;
  }

  /**
   * Exit Edit mode
   */
  exitEditMode(): void {
    this.isEditMode = false;
  }



  /**
   * Cancel editting and restore everything to previous state
   */
  cancel(): void {
    this.restoreBackupPage();
    this.exitEditMode();
  }


  /**
   * Restore the PersonalPage to the backup (pre-editting)
   */
  restoreBackupPage(): void {
    this.personalPage = this.backupPageEditor;
    this.backupPageEditor = null;
  }


  /**
   * Save the updated version of this home page
   */
  save(): void {

    const self = this;
    this.isSaving = true;
    console.log('Saving profile page');

    const promise = this.apiService.saveHomePage(this.personalPage);
    promise.then(
      function() {
        console.log('Saved successfully');
      },
      function(err) {
        console.error('Error saving profile page: ' + JSON.stringify(err));
      }
    ).finally(function() {
      self.isSaving = false;
      self.exitEditMode();
    });
  }

}
