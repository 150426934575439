<div  *ngIf="shouldShow()"
      class="photo-essay-row-row"
      [ngClass]="{'photo-essay-row-edit-mode': editor.isEditMode,
                  'photo-essay-row-caption-row': row.type === 'CAPTION',
                  'photo-essay-row-edit-mode-focused': hasFocus()}"
      >

  <!-- Delete Row button -->
  <div *ngIf="editor.isEditMode"
       class="photo-essay-row-edit-tools"
       [ngClass]="{'photo-essay-row-edit-tools-show': hasFocus()}">
    <img src="/img/trash.svg" (click)="deleteRow()" class="photo-essay-row-delete" tabindex>

  </div>

  <!-- CAPTION -->
  <div *ngIf="row.type == 'CAPTION'" class="photo-essay-row-content-caption">
    <photo-essay-tile [tile]="row.tiles[0]" [editor]="editor">
    </photo-essay-tile>
  </div>

  
  <!-- Photos -->
  <div *ngIf="row.type !== 'CAPTION'"  class="photo-essay-row-content-photos">

    <!-- Spacer for our absolutely-posititioned tiles in collages (if any) -->
    <div *ngIf="isCollage()"
      style="position:relative"
      [style.height]="rowCss.height"
      [style.width]="rowCss.width">
    </div>

    <!-- The Actual Tiles -->
    <div *ngFor="let tile of row.tiles"
      [style.position]="tile.css.position"
      [style.top]="tile.css.top"
      [style.bottom]="tile.css.bottom"
      [style.left]="tile.css.left"
      [style.right]="tile.css.right"
      [style.width]="tile.css.width"
      [style.height]="tile.css.height"
      [style.margin]="tile.css.margin"
      [style.padding]="tile.css.padding"
      style="display:inline-block;">

      <photo-essay-tile [tile]="tile" [editor]="editor">
      </photo-essay-tile>
    </div>

  </div>

</div>

<!-- Spacing when we are hidden -->
<div *ngIf="!shouldShow()" class="photo-essay-row-default-text-spacing">
</div>

<!-- Add Row Button -->
<photo-essay-add-row
  *ngIf="(row.type !== 'CAPTION') && (editor.isEditMode)"
  (addRowFunc)="addRow(rowType)"
  [editor]="editor">
</photo-essay-add-row>