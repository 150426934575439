import * as i0 from "@angular/core";
/**
 * Message Box Service responsible for putting up Info / Error / Confirm
 * message boxes to the user.
 *
 * Currently leverages the AngularJS version of MessageBoxService
 *
 * @export
 * @class MessageBox
 */
var MessageBox = /** @class */ (function () {
    /**
     * Constructor
     *
     * @param {*} ng1MessageBoxService
     * @memberof MessageBox
     */
    function MessageBox(ng1MessageBoxService) {
        this.ng1MessageBoxService = ng1MessageBoxService;
        console.error('Constructor for MessageBox!');
    }
    /**
     * Confirm Prompt
     *
     * @static
     * @param {string} title
     * @param {string} message
     * @param {string} confirmMessage
     * @param {string} cancelMessage
     * @param {boolean} [isDangerous]
     * @param {boolean} [isSmall]
     * @returns {*}
     * @memberof MessageBox
     */
    MessageBox.prototype.confirmPromptAdvanced = function (title, message, confirmMessage, cancelMessage, isDangerous, isSmall) {
        // Load the AngularJS version of MessageBoxService and call it
        return this.ng1MessageBoxService.confirmPromptAdvanced(title, message, confirmMessage, cancelMessage, isDangerous, isSmall);
    };
    /**
     * Info Message
     *
     * @static
     * @param {string} message
     * @param {string} title
     * @returns {*}
     * @memberof MessageBox
     */
    MessageBox.prototype.infoMessage = function (message, title) {
        // Load the AngularJS version of MessageBoxService and call it
        return this.ng1MessageBoxService.infoMessage(message, title);
    };
    /**
     * Error Message
     *
     * @static
     * @param {string} message
     * @returns {*}
     * @memberof MessageBox
     */
    MessageBox.prototype.errorMessage = function (message) {
        // Load the AngularJS version of MessageBoxService and call it
        return this.ng1MessageBoxService.errorMessage(message);
    };
    MessageBox.ngInjectableDef = i0.defineInjectable({ factory: function MessageBox_Factory() { return new MessageBox(i0.inject("MessageBoxService")); }, token: MessageBox, providedIn: "root" });
    return MessageBox;
}());
export { MessageBox };
