<div class="save-bar">

  <!-- Reserve space at the bottom of the screen (where this should be placed -->
  <div class="save-bar-placeholder">
  </div>
  
  <!-- Save bar -->
  <div class="save-bar-fixed">

    <!-- Fill up all space to the left -->
    <div class="save-bar-spacer"></div>


    <!-- Cancel -->
    <div class="save-bar-section">  
      <kint-button  [hidden]="isSaving"
                    text="Cancel"
                    type="DEFAULT"
                    size="LARGE"
                    [disable]="isSaving"
                    (clickHandler)="cancel()">
      </kint-button>
    </div>

    <!-- Space between buttons -->
    <div class="save-bar-button-spacer">
    </div>

    <!-- Save -->
    <div class="save-bar-section">  
      <kint-button text="Save"
                   type="PRIMARY"
                   size="LARGE"
                   [spin]="isSaving"
                   [disable]="isSaving"
                   (clickHandler)="save()"
                   >
      </kint-button>
    </div>

  </div>

</div>