import { OnInit } from '@angular/core';
import { switchMap, debounceTime, tap, finalize } from 'rxjs/operators';
import { LocationLookupTomTomService } from '../Autocomplete/TomTom/location-lookup-tomtom.service';
import { LocationLookupPhotonService } from '../Autocomplete/Photon/location-lookup-photon.service';
import { FormBuilder } from '@angular/forms';
import { downgradeComponent } from '@angular/upgrade/static';
import { EventEmitter } from '@angular/core';
import { KintLocation, KintGeo, KintAddressDisplayInfo } from 'src/app/KintObject/kint-location';
var LocationEditComponent = /** @class */ (function () {
    function LocationEditComponent(fb, locationLookupTomTomService, locationLookupPhotonService) {
        this.fb = fb;
        this.locationLookupTomTomService = locationLookupTomTomService;
        this.locationLookupPhotonService = locationLookupPhotonService;
        // Whenever the location text changes
        this.textChanged = new EventEmitter();
        // Whenever user selects a location from auto-complete
        this.locationSelected = new EventEmitter();
        // Locations matching (for auto-complete)
        this.filteredLocations = [];
        this.isLoading = false;
    }
    /**
     * Initialize this component
     *
     * @memberof LocationEditComponent
     */
    LocationEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.locationForm = this.fb.group({
            locationInput: null
        });
        console.log('SpecificClasses: ' + this.specificClasses);
        // Optional initial value
        if (this.location) {
            this.locationForm.get('locationInput').setValue(this.location.name);
        }
        this.locationForm.get('locationInput').valueChanges
            .pipe(debounceTime(300), tap(function () { return _this.isLoading = true; }), tap(function () { return _this.emitTextChanged(); }), switchMap(function (value) {
            // If the "value" of our input is actually an object (instead of
            // a string), then don't do any autocompletes
            var dataToPipe = _this.locationLookupTomTomService.buildEmptyResults();
            if (!value.name) {
                dataToPipe = _this.locationLookupTomTomService.search(value, _this.geoCenter);
            }
            return dataToPipe.pipe(finalize(function () {
                _this.isLoading = false;
            }));
        }))
            .subscribe(function (locationResponse) { return _this.filteredLocations = locationResponse.results; });
        if (this.shouldFocus) {
            // Ensure we have focus. Yes, I know jQuery is naughty
            setTimeout(function () {
                $('.location-edit-field').focus();
            }, 100);
        }
    };
    /**
     * Determine if specific classes were passed in by the parent
     *
     * @return  boolean
     */
    LocationEditComponent.prototype.hasSpecificClasses = function () {
        if (this.specificClasses && this.specificClasses.length > 0) {
            return true;
        }
        return false;
    };
    /**
     * Gets the address to display alongside the 'name'
     */
    LocationEditComponent.prototype.getDisplayableAddress = function () {
        if (this.location) {
            var displayInfo = KintLocation.getAddressDisplayInfo(this.location);
            return displayInfo;
        }
        return null;
    };
    /**
     * Display function for displaying the given autocomplete data
     *
     * @param kintLocationOrString
     * @returns
     * @memberof LocationEditComponent
     */
    LocationEditComponent.prototype.displayFn = function (locationOrString) {
        if (locationOrString && locationOrString.name) {
            var displayInfo = KintLocation.getAddressDisplayInfo(locationOrString);
            return displayInfo.primary;
        }
        else {
            return locationOrString;
        }
    };
    /**
     * Emits the 'textChanged' event to the parent
     *
     * @memberof LocationEditComponent
     */
    LocationEditComponent.prototype.emitTextChanged = function () {
        console.log('emitTextChanged');
        var inputString = '';
        // When our Input value changes, sent an event with the new string
        // Input value may be a Location OR a string
        var inputValue = this.locationForm.get('locationInput').value;
        if (inputValue) {
            if (inputValue.name) {
                inputString = inputValue.name;
            }
            else {
                inputString = inputValue;
            }
        }
        this.textChanged.emit(inputString);
    };
    /**
     * Autocomplete was selected
     *
     * @param {LocationAutoCompleteResult} selection
     * @memberof LocationEditComponent
     */
    LocationEditComponent.prototype.autocompleteSelected = function (selection) {
        console.log('autocompleteSelected');
        var kintLocation = selection.toKintLocation();
        this.locationSelected.emit(kintLocation);
    };
    return LocationEditComponent;
}());
export { LocationEditComponent };
// Provide a downgraded version of this to run with AngularJS
angular.module('KintributeApp')
    .directive('locationEdit', downgradeComponent({
    component: LocationEditComponent,
    inputs: ['shouldFocus', 'rounded', 'specificClasses', 'location'],
    outputs: ['textChanged', 'locationSelected']
}));
