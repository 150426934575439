import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { PersonalPageRow } from 'src/app/KintObject/kint-personal-page';
import { PersonalPageEditor } from '../personal-page-editor';
import { ViewCheckHelper } from 'src/app/Util/viewCheck/view-check-helper';

// jQuery... naughty...
declare var $: any;

@Component({
  selector: 'app-personal-page-text-row',
  templateUrl: './personal-page-text-row.component.html',
  styleUrls: ['./personal-page-text-row.component.css']
})
export class PersonalPageTextRowComponent extends ViewCheckHelper implements OnInit {

  @Input('row') row: PersonalPageRow;
  @Input('isMobile') isMobile: boolean;
  @Input('editor') editor: PersonalPageEditor;

  @ViewChild('headlineField') headlineField: ElementRef;
  @ViewChild('textField') textField: ElementRef;

  constructor(private elementRef: ElementRef) {
    super();
  }

  ngOnInit() {
  }



  clickedTextField(): void {
    this.startEditMode('textField');
  }
  clickedHeadlineField(): void {
    this.startEditMode('headlineField');
  }

  startEditMode(fieldToSelect: string): void {

    // Get the top of this element pre-edit-mode
    const oldElementTop = this.elementRef.nativeElement.getBoundingClientRect().top;

     // Actually start the edit mode
     this.editor.startEditMode();

     // Fix the scroll position after we re-render
     this.onNextViewCheck().then(() => {
       const updatedElementTop = this.elementRef.nativeElement.getBoundingClientRect().top;

       // Scroll the element to the right spot
       const currentScrollPos = $(window).scrollTop();
       const newScrollPos = currentScrollPos - (oldElementTop - updatedElementTop);
       $(window).scrollTop(newScrollPos);

       // Put focus on the field
       if (fieldToSelect === 'headlineField') {
        this.headlineField.nativeElement.focus();
      } else if (fieldToSelect === 'textField') {
        this.textField.nativeElement.focus();
      }
     });

  }
}
