import { Injectable, Inject } from '@angular/core';

interface MessageBoxResult {
  result: any;
}

/**
 * Message Box Service responsible for putting up Info / Error / Confirm
 * message boxes to the user.
 *
 * Currently leverages the AngularJS version of MessageBoxService
 *
 * @export
 * @class MessageBox
 */
@Injectable({
  providedIn: 'root'
})
export class MessageBox {

  /**
   * Constructor
   *
   * @param {*} ng1MessageBoxService
   * @memberof MessageBox
   */
  constructor(@Inject('MessageBoxService') private ng1MessageBoxService: any) {
    console.error('Constructor for MessageBox!');
  }



  /**
   * Confirm Prompt
   *
   * @static
   * @param {string} title
   * @param {string} message
   * @param {string} confirmMessage
   * @param {string} cancelMessage
   * @param {boolean} [isDangerous]
   * @param {boolean} [isSmall]
   * @returns {*}
   * @memberof MessageBox
   */
  confirmPromptAdvanced(
    title: string,
    message: string,
    confirmMessage: string,
    cancelMessage: string,
    isDangerous?: boolean,
    isSmall?: boolean
  ): MessageBoxResult {

    // Load the AngularJS version of MessageBoxService and call it
    return this.ng1MessageBoxService.confirmPromptAdvanced(
      title,
      message,
      confirmMessage,
      cancelMessage,
      isDangerous,
      isSmall
    );
  }



  /**
   * Info Message
   *
   * @static
   * @param {string} message
   * @param {string} title
   * @returns {*}
   * @memberof MessageBox
   */
  infoMessage(message: string, title: string): MessageBoxResult {

    // Load the AngularJS version of MessageBoxService and call it
    return this.ng1MessageBoxService.infoMessage(message, title);
  }


  /**
   * Error Message
   *
   * @static
   * @param {string} message
   * @returns {*}
   * @memberof MessageBox
   */
  errorMessage(message: string): MessageBoxResult {

    // Load the AngularJS version of MessageBoxService and call it
    return this.ng1MessageBoxService.errorMessage(message);
  }

}
