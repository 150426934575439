import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { KintPhoto, PersonTagAction, PersonTagActionType, PersonTagActionConstraints, PersonTag} from 'src/app/KintObject/kint-photo';
import { UtilService } from 'src/app/Util/util/util.service';

// AngularJS is loaded separately
declare var angular: any;

@Injectable({
  providedIn: 'root'
})
export class PersonTaggingService {

  constructor(private utilService: UtilService) { }


  /**
   * Figure out which Person tags have been added/removed from the given photo
   *
   * This function generates a list of PersonTagActions which can be reviewed by the user
   * before submitting a changed photo back to the server
   */
  getPersonTagChanges(oldPhoto: KintPhoto, newPhoto: KintPhoto): PersonTagAction[] {

    // Sanity check!
    if (!oldPhoto.people) {
      oldPhoto.people = [];
    }
    if (!newPhoto.people) {
      newPhoto.people = [];
    }

    const generatedActions = [];
    const tagsAdded = [];
    const tagsRemoved = [];

    // Do a Diff of the People in the photos
    this.comparePersonTagArrays(oldPhoto.people, newPhoto.people, tagsAdded, tagsRemoved);

    // Figure out what actions were taken (add/remove) and return
    // the list of those actions
    tagsRemoved.forEach(addedTag => {
      const personTagAction = new PersonTagAction(addedTag, PersonTagActionType.REMOVE, PersonTagActionConstraints.SINGLE);
      generatedActions.push(personTagAction);
    });
    tagsAdded.forEach(addedTag => {
      const personTagAction = new PersonTagAction(addedTag, PersonTagActionType.ADD, PersonTagActionConstraints.SINGLE);
      generatedActions.push(personTagAction);
    });

    return generatedActions;
  }



  /**
   * Determine if 2 person-tags are equal or not (same person, same coordinates)
   *
   * @param {*} personTag1
   * @param {*} personTag2
   * @returns {boolean}
   * @memberof PersonTaggingService
   */
  arePersonTagsEqual(personTag1: PersonTag, personTag2: PersonTag): boolean {

    if ((personTag1.id === personTag2.id) &&
        (personTag1.coords && personTag2.coords) &&
        (personTag1.coords.x === personTag2.coords.x) &&
        (personTag1.coords.y === personTag2.coords.y) &&
        (personTag1.coords.width === personTag2.coords.width) &&
        (personTag1.coords.height === personTag2.coords.height)) {
      return true;
    }

    return false;
  }


  /**
   * Compares Two arrays and figures out what has been added/removed
   *
   * @param {any[]} oldPersonList
   * @param {any[]} newPersonList
   * @param {any[]} elementsAdded  (Initially empty, results returned in this array)
   * @param {any[]} elementsRemoved (Initially empty, results returned in this array)
   * @memberof PersonTaggingService
   */
  comparePersonTagArrays(oldArray: PersonTag[], newArray: PersonTag[], elementsAdded: PersonTag[], elementsRemoved: PersonTag[]) {

    // Clear the existing arrays
    elementsAdded.length = 0;
    elementsRemoved.length = 0;

    // See which elements have been added
    oldArray.forEach(oldElement => {
      let stillExists = false;
      newArray.forEach(newElement => {

        if (this.arePersonTagsEqual(oldElement, newElement)) {
          stillExists = true;
        }
      });

      if (!stillExists) {
        elementsRemoved.push(oldElement);
      }
    });

    // See which elements have been removed
    newArray.forEach(newElement => {
      let alreadyExisted = false;
      oldArray.forEach(oldElement => {
        if (this.arePersonTagsEqual(oldElement, newElement)) {
          alreadyExisted = true;
        }
      });

      if (!alreadyExisted) {
        elementsAdded.push(newElement);
      }
    });
  }
}


// Register an AngularJS service, whose value is the "downgraded" Angular injectable.
angular.module('KintributeApp').factory(
  'PersonTaggingService', downgradeInjectable(PersonTaggingService) as any);
