<div class="location-edit-input-wrapper" 
     [ngClass]="{'location-edit-input-wrapper-rounded': rounded}">

    <form [formGroup]='locationForm'>
      <!-- User Input-->
      <input
            class="location-edit-field location-edit-borderless-text"
            placeholder="Where was this?" 
            autocomplete="off"
            formControlName='locationInput'
            [matAutocomplete]="auto">
  
      <!-- Selected location directly under (no interaction) -->
      <div class="location-edit-location-summary"
           *ngIf="getDisplayableAddress().secondary">
        {{getDisplayableAddress().secondary}}
      </div>
  
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" [panelWidth]=250
                        (optionSelected)='autocompleteSelected($event.option.value)'>
        <mat-option *ngIf="isLoading" class="is-loading"><mat-spinner diameter="30"></mat-spinner></mat-option>
        <ng-container *ngIf="!isLoading">
          <mat-option *ngFor="let locationResult of filteredLocations" 
                      [value]="locationResult"
                      class="location-edit-autocomplete">
            <div class="location-edit-autocomplete-content">
              {{ locationResult.name }}
            </div>
            <div *ngIf="locationResult.name != locationResult.addressShort"
                 class="location-edit-autocomplete-extra location-edit-autocomplete-content" > 
                {{locationResult.addressShort}}
            </div>
          </mat-option>
        </ng-container>
      </mat-autocomplete>
  
    </form>
  </div>