import * as i0 from "@angular/core";
var UtilService = /** @class */ (function () {
    function UtilService() {
    }
    /**
     * Perform a deep-copy of any Javascript object
     *
     * @param obj Any Javascript object that we want to copy
     */
    UtilService.prototype.deepCopy = function (obj) {
        return this.deepClone(obj);
        /*
        return JSON.parse(JSON.stringify(obj));
        */
    };
    UtilService.prototype.deepClone = function (obj) {
        // return value is input is not an Object or Array.
        if (typeof (obj) !== 'object' || obj === null) {
            return obj;
        }
        var clone;
        if (Array.isArray(obj)) {
            clone = obj.slice(); // unlink Array reference.
        }
        else {
            clone = Object.assign({}, obj); // Unlink Object reference.
        }
        var keys = Object.keys(clone);
        for (var i = 0; i < keys.length; i++) {
            clone[keys[i]] = this.deepClone(clone[keys[i]]); // recursively unlink reference to nested objects.
        }
        return clone; // return unlinked clone.
    };
    /**
     * Splits comma-separated terms into an array of terms
     *
     * @param   commaSeparatedTerms   list of comma-separated terms
     *
     * @return  [terms]
     */
    UtilService.prototype.splitTerms = function (commaSeparatedTerms) {
        var filteredTermList = [];
        var termList = commaSeparatedTerms.trim().split(/[,\n]+/);
        for (var i = 0; i < termList.length; i++) {
            var term = termList[i].trim();
            if (term !== '') {
                filteredTermList.push(term);
            }
        }
        return filteredTermList;
    };
    UtilService.ngInjectableDef = i0.defineInjectable({ factory: function UtilService_Factory() { return new UtilService(); }, token: UtilService, providedIn: "root" });
    return UtilService;
}());
export { UtilService };
