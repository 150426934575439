var AlternateImageSize = /** @class */ (function () {
    function AlternateImageSize(width, height, link) {
        this.link = link;
        this.height = height;
        this.width = width;
    }
    return AlternateImageSize;
}());
export { AlternateImageSize };
var PersonTag = /** @class */ (function () {
    function PersonTag() {
    }
    return PersonTag;
}());
export { PersonTag };
var PersonTagAction = /** @class */ (function () {
    function PersonTagAction(tag, action, constraints) {
        this.personTag = tag;
        this.action = action;
        this.constraints = constraints;
        this.confirmed = false;
    }
    return PersonTagAction;
}());
export { PersonTagAction };
var KintAutoTag = /** @class */ (function () {
    function KintAutoTag() {
    }
    return KintAutoTag;
}());
export { KintAutoTag };
export var PersonTagActionType;
(function (PersonTagActionType) {
    PersonTagActionType["REMOVE"] = "REMOVE";
    PersonTagActionType["ADD"] = "ADD";
})(PersonTagActionType || (PersonTagActionType = {}));
export var PersonTagActionConstraints;
(function (PersonTagActionConstraints) {
    PersonTagActionConstraints["GLOBAL"] = "GLOBAL";
    PersonTagActionConstraints["ALL_MINE"] = "ALL_MINE";
    PersonTagActionConstraints["SINGLE"] = "SINGLE";
})(PersonTagActionConstraints || (PersonTagActionConstraints = {}));
