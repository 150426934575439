import { OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { MessageBox } from 'src/app/MessageBox/message-box.service';
import { UtilService } from 'src/app/Util/util/util.service';
var PartyComponent = /** @class */ (function () {
    function PartyComponent(mesageBox, utilService) {
        this.mesageBox = mesageBox;
        this.utilService = utilService;
    }
    PartyComponent.prototype.ngOnInit = function () {
        this.partyName = 'Tom\'s Party';
        this.sliderValue = true;
    };
    PartyComponent.prototype.dataChangeCallback = function () {
        this.sliderValue = !this.sliderValue;
        console.log('Data Changed! Now is: ' + this.sliderValue);
        this.utilService.deepCopy({ bob: true });
        this.mesageBox.infoMessage('Tom\'s Message!', 'Tom\'s title');
    };
    return PartyComponent;
}());
export { PartyComponent };
// Provide a downgraded version of this to run with AngularJS
angular.module('KintributeApp')
    .directive('ng2Party', downgradeComponent({ component: PartyComponent }));
