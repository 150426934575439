/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./timeline-supplement.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../timeline-supplement-story/timeline-supplement-story.component.ngfactory";
import * as i3 from "../timeline-supplement-story/timeline-supplement-story.component";
import * as i4 from "../timeline-supplement-map/timeline-supplement-map.component.ngfactory";
import * as i5 from "../timeline-supplement-map/timeline-supplement-map.component";
import * as i6 from "../timeline-supplement-link/timeline-supplement-link.component.ngfactory";
import * as i7 from "../timeline-supplement-link/timeline-supplement-link.component";
import * as i8 from "../timeline-supplement-audio/timeline-supplement-audio.component.ngfactory";
import * as i9 from "../timeline-supplement-audio/timeline-supplement-audio.component";
import * as i10 from "@angular/common";
import * as i11 from "./timeline-supplement.component";
var styles_TimelineSupplementComponent = [i0.styles];
var RenderType_TimelineSupplementComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TimelineSupplementComponent, data: {} });
export { RenderType_TimelineSupplementComponent as RenderType_TimelineSupplementComponent };
function View_TimelineSupplementComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-timeline-supplement-story", [], null, null, null, i2.View_TimelineSupplementStoryComponent_0, i2.RenderType_TimelineSupplementStoryComponent)), i1.ɵdid(1, 114688, null, 0, i3.TimelineSupplementStoryComponent, [], { supplement: [0, "supplement"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.supplement; _ck(_v, 1, 0, currVal_0); }, null); }
function View_TimelineSupplementComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-timeline-supplement-map", [], null, null, null, i4.View_TimelineSupplementMapComponent_0, i4.RenderType_TimelineSupplementMapComponent)), i1.ɵdid(1, 114688, null, 0, i5.TimelineSupplementMapComponent, [], { supplement: [0, "supplement"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.supplement; _ck(_v, 1, 0, currVal_0); }, null); }
function View_TimelineSupplementComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-timeline-supplement-link", [], null, null, null, i6.View_TimelineSupplementLinkComponent_0, i6.RenderType_TimelineSupplementLinkComponent)), i1.ɵdid(1, 114688, null, 0, i7.TimelineSupplementLinkComponent, [], { supplement: [0, "supplement"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.supplement; _ck(_v, 1, 0, currVal_0); }, null); }
function View_TimelineSupplementComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-timeline-supplement-audio", [], null, null, null, i8.View_TimelineSupplementAudioComponent_0, i8.RenderType_TimelineSupplementAudioComponent)), i1.ɵdid(1, 114688, null, 0, i9.TimelineSupplementAudioComponent, [], { supplement: [0, "supplement"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.supplement; _ck(_v, 1, 0, currVal_0); }, null); }
function View_TimelineSupplementComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Unknown Supplement "]))], null, null); }
export function View_TimelineSupplementComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "timeline-supplement"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 11, null, null, null, null, null, null, null)), i1.ɵdid(2, 16384, null, 0, i10.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimelineSupplementComponent_1)), i1.ɵdid(4, 278528, null, 0, i10.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i10.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimelineSupplementComponent_2)), i1.ɵdid(6, 278528, null, 0, i10.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i10.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimelineSupplementComponent_3)), i1.ɵdid(8, 278528, null, 0, i10.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i10.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimelineSupplementComponent_4)), i1.ɵdid(10, 278528, null, 0, i10.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i10.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimelineSupplementComponent_5)), i1.ɵdid(12, 16384, null, 0, i10.NgSwitchDefault, [i1.ViewContainerRef, i1.TemplateRef, i10.NgSwitch], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.supplement.type; _ck(_v, 2, 0, currVal_0); var currVal_1 = "TEXT"; _ck(_v, 4, 0, currVal_1); var currVal_2 = "MAP"; _ck(_v, 6, 0, currVal_2); var currVal_3 = "LINK"; _ck(_v, 8, 0, currVal_3); var currVal_4 = "AUDIO"; _ck(_v, 10, 0, currVal_4); }, null); }
export function View_TimelineSupplementComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-timeline-supplement", [], null, null, null, View_TimelineSupplementComponent_0, RenderType_TimelineSupplementComponent)), i1.ɵdid(1, 114688, null, 0, i11.TimelineSupplementComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TimelineSupplementComponentNgFactory = i1.ɵccf("app-timeline-supplement", i11.TimelineSupplementComponent, View_TimelineSupplementComponent_Host_0, { supplement: "supplement" }, {}, []);
export { TimelineSupplementComponentNgFactory as TimelineSupplementComponentNgFactory };
