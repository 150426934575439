import { downgradeComponent } from '@angular/upgrade/static';
import { OnInit, ElementRef, DoCheck } from '@angular/core';
import { MessageBox } from 'src/app/MessageBox/message-box.service';
import { PhotoEssayRow, PhotoEssayRowTypes } from '../photo-essay';
import { PhotoEssayLayoutService } from '../photo-essay-layout/photo-essay-layout.service';
import { LogService } from 'src/app/Log/log.service';
import { PhotoService } from 'src/app/Photo/photo.service';
var PhotoEssayRowComponent = /** @class */ (function () {
    /**
     * Constructor
     *
     * @param elementRef
     * @param logService
     * @param photoService
     * @param photoEssayLayoutService
     * @param messageBox
     */
    function PhotoEssayRowComponent(elementRef, logService, photoService, photoEssayLayoutService, messageBox, appDataModelService) {
        this.elementRef = elementRef;
        this.logService = logService;
        this.photoService = photoService;
        this.photoEssayLayoutService = photoEssayLayoutService;
        this.messageBox = messageBox;
        this.appDataModelService = appDataModelService;
        /**
         * Member Variables
         */
        this.MAX_ROW_WIDTH = 800;
        this.MAX_ROW_HEIGHT = 700;
        this.isMobile = false;
        this.rowCss = {
            height: '0px',
            width: '0px'
        };
        this.tilePadding = 28; // 2em
        this.serializedPhotoIdsString = '';
        this.logService.info('PhotoEssayRowComponent Constructor');
    }
    /**
     * Initialize the component
     */
    PhotoEssayRowComponent.prototype.ngOnInit = function () {
        // TODO:  Figure out how to arrange all of the tiles within this row!
        this.logService.info('PhotoEssayRowComponent ngOnInit');
        this.resizeTiles();
        this.serializedPhotoIdsString = this.buildSerializedPhotoIdsString();
    };
    /**
     * Monitor for Photo changes within this row.  If the photo changes, then
     * make sure to rebuild the row
     */
    PhotoEssayRowComponent.prototype.ngDoCheck = function () {
        var newSerializedPhotoIdsString = this.buildSerializedPhotoIdsString();
        if (newSerializedPhotoIdsString !== this.serializedPhotoIdsString) {
            this.resizeTiles();
            this.serializedPhotoIdsString = newSerializedPhotoIdsString;
        }
    };
    /**
     * Listen for screen size changes
     *
     * TODO:  Watch these screen events somewhere else and consolidate them!
     *        Having everyone subscribe to the actual window resize is not recommended!
     *
     * @param event
     */
    PhotoEssayRowComponent.prototype.onResize = function (event) {
        var _this = this;
        clearTimeout(this.resizeId);
        this.resizeId = setTimeout(function () {
            _this.resizeTiles();
        }, 500);
    };
    /**
     * Make sure all of our tiles have 'css' properties on them
     * so we can bind to them directly
     *
     * @memberof PhotoEssayRowComponent
     */
    PhotoEssayRowComponent.prototype.addCssToTiles = function () {
        for (var _i = 0, _a = this.row.tiles; _i < _a.length; _i++) {
            var tile = _a[_i];
            tile.css = {
                position: 'relative',
                top: '0px',
                left: '0px',
                width: '0px',
                height: '0px',
                margin: 'auto',
                padding: ''
            };
        }
        this.rowCss = {
            width: '0px',
            height: '0px'
        };
    };
    /**
     * Resize the tiles within the row so that they all fit properly
     */
    PhotoEssayRowComponent.prototype.resizeTiles = function () {
        this.isMobile = this.appDataModelService.isMobile();
        this.tilePadding = 14; // 1em
        if (this.isMobile) {
            this.tilePadding = 3;
        }
        // Compensate for less space on mobile
        this.MAX_ROW_WIDTH = 800;
        if (window.innerWidth < 800) {
            this.MAX_ROW_WIDTH = window.innerWidth - 8; // full-width with a little padding
        }
        this.addCssToTiles();
        // Captions don't need sizing
        if (this.row.type === PhotoEssayRowTypes.CAPTION) {
            return;
        }
        // Calculate the different stylings for our photos
        switch (this.row.type) {
            case PhotoEssayRowTypes.SINGLE_PHOTO:
            case PhotoEssayRowTypes.SINGLE_PORTRAIT:
            case PhotoEssayRowTypes.SINGLE_LANDSCAPE:
            case PhotoEssayRowTypes.SIDE_BY_SIDE_PHOTOS:
            case PhotoEssayRowTypes.SIDE_BY_SIDE_LANDSCAPE:
            case PhotoEssayRowTypes.SIDE_BY_SIDE_PORTRAIT:
            case PhotoEssayRowTypes.THREE_COLUMN_PHOTOS: {
                var totalWidth = 0;
                // Size all of the tiles to the max size (700px)
                for (var _i = 0, _a = this.row.tiles; _i < _a.length; _i++) {
                    var tile = _a[_i];
                    var photo = tile.photo;
                    var calculatedWidth = this.photoService.calculateWidth(photo, this.MAX_ROW_HEIGHT);
                    tile.css.height = this.MAX_ROW_HEIGHT;
                    tile.css.width = calculatedWidth;
                    totalWidth += calculatedWidth;
                }
                // Account for spacing between the tiles (2em between each photo)
                var paddingSize = (this.row.tiles.length - 1) * (this.tilePadding * 2);
                // Shrink the content down so that it all fits in the row
                if ((totalWidth + paddingSize) > this.MAX_ROW_WIDTH) {
                    var scaleBy = (this.MAX_ROW_WIDTH - paddingSize) / totalWidth;
                    for (var _b = 0, _c = this.row.tiles; _b < _c.length; _b++) {
                        var tile = _c[_b];
                        tile.css.height = tile.css.height * scaleBy;
                        tile.css.width = tile.css.width * scaleBy;
                    }
                }
                // Cleanup on all tiles (apply units and padding)
                for (var i = 0; i < this.row.tiles.length; i++) {
                    var tile = this.row.tiles[i];
                    tile.css.height = tile.css.height + 'px';
                    tile.css.width = tile.css.width + 'px';
                    tile.css.margin = '0px';
                }
                // Add Padding to all of the tiles (if needed)
                if (this.row.tiles.length === 1) {
                    this.row.tiles[0].css.margin = '0px';
                }
                else if (this.row.tiles.length === 2) {
                    this.row.tiles[0].css.margin = "0px " + this.tilePadding / 2.0 + "px 0px 0px";
                    this.row.tiles[1].css.margin = "0px 0px 0px " + this.tilePadding / 2.0 + "px ";
                }
                else if (this.row.tiles.length === 3) {
                    this.row.tiles[0].css.margin = "0px " + this.tilePadding / 2.0 + "px  0px 0px";
                    this.row.tiles[1].css.margin = "0px " + this.tilePadding / 2.0 + "px  0px " + this.tilePadding / 2.0 + "px";
                    this.row.tiles[2].css.margin = "0px 0px 0px " + this.tilePadding / 2.0 + "px";
                }
                break;
            }
            case PhotoEssayRowTypes.COLLAGE_THREE_PHOTOS:
            case PhotoEssayRowTypes.COLLAGE_TOP_HEAVY:
            case PhotoEssayRowTypes.COLLAGE_LEFT_HEAVY: {
                this.arrangeCollage(this.row.tiles);
                break;
            }
            default: {
                this.logService.error('Error displaying photo essay row. Type: ' + this.row.type);
            }
        }
    };
    /**
     * Returns true if this row is a "collage" type
     */
    PhotoEssayRowComponent.prototype.isCollage = function () {
        return ((this.row.type === PhotoEssayRowTypes.COLLAGE_THREE_PHOTOS) ||
            (this.row.type === PhotoEssayRowTypes.COLLAGE_LEFT_HEAVY) ||
            (this.row.type === PhotoEssayRowTypes.COLLAGE_TOP_HEAVY));
    };
    /**
     * Arrange the photo tiles into a collage
     * @param tiles
     */
    PhotoEssayRowComponent.prototype.arrangeCollage = function (tiles) {
        if (tiles.length !== 3) {
            this.logService.error('Error arranging photo collage. 3 photos needed');
            return;
        }
        var leftTile = tiles[0];
        var rightTopTile = tiles[1];
        var rightBottomTile = tiles[2];
        var leftTileSize = this.getPhotoSizeFromTile(leftTile);
        var rightTopTileSize = this.getPhotoSizeFromTile(rightTopTile);
        var rightBottomTileSize = this.getPhotoSizeFromTile(rightBottomTile);
        var leftTileScale = 1.0;
        var rightTopTileScale = 1.0;
        var rightBottomTileScale = 1.0;
        // First tile goes on the left
        var firstPhotoWidth = this.photoService.calculateWidth(leftTile.photo, this.MAX_ROW_HEIGHT);
        leftTileScale = firstPhotoWidth / leftTileSize.width;
        // Second two tiles need to be equal width. Scale to the top-right's width
        rightBottomTileScale = rightTopTileSize.width / rightBottomTileSize.width;
        // Next, see how tall the right-column will be currently.
        var rightColumnHeight = rightTopTileSize.height * rightTopTileScale + rightBottomTileSize.height * rightBottomTileScale;
        // The right column needs to be MAX_ROW_HEIGHT px tall, just like the left tile.
        var rightColumnScale = this.MAX_ROW_HEIGHT / rightColumnHeight;
        rightTopTileScale = rightTopTileScale * rightColumnScale;
        rightBottomTileScale = rightBottomTileScale * rightColumnScale;
        // Now, both columns are scaled to MAX_ROW_HEIGHT height. However, now, the full width
        // might be larger than this.MAX_ROW_WIDTH
        var totalWidth = leftTileSize.width * leftTileScale + rightBottomTileSize.width * rightBottomTileScale;
        // If we are too wide, scale everything down until we fit
        var scaleEverythingScale = 1.0;
        if (totalWidth > this.MAX_ROW_WIDTH) {
            scaleEverythingScale = this.MAX_ROW_WIDTH / totalWidth;
            leftTileScale = leftTileScale * scaleEverythingScale;
            rightTopTileScale = rightTopTileScale * scaleEverythingScale;
            rightBottomTileScale = rightBottomTileScale * scaleEverythingScale;
        }
        // Our scaling is all set up. Now apply the absolute positioning on the elements
        leftTile.css.position = 'absolute';
        leftTile.css.top = '0px';
        leftTile.css.left = '0px';
        leftTile.css.right = '';
        leftTile.css.width = (leftTileSize.width * leftTileScale) + 'px';
        leftTile.css.height = (leftTileSize.height * leftTileScale) + 'px';
        leftTile.css.padding = this.tilePadding / 2.0 + "px";
        rightTopTile.css.position = 'absolute';
        rightTopTile.css.top = '0px';
        rightTopTile.css.left = '';
        rightTopTile.css.right = '0px';
        rightTopTile.css.width = (rightTopTileSize.width * rightTopTileScale) + 'px';
        rightTopTile.css.height = (rightTopTileSize.height * rightTopTileScale) + 'px';
        rightTopTile.css.padding = this.tilePadding / 2.0 + "px";
        rightBottomTile.css.position = 'absolute';
        rightBottomTile.css.top = '';
        rightBottomTile.css.left = '';
        rightBottomTile.css.right = '0px';
        rightBottomTile.css.bottom = '0px';
        rightBottomTile.css.width = (rightBottomTileSize.width * rightBottomTileScale) + 'px';
        rightBottomTile.css.height = (rightBottomTileSize.height * rightBottomTileScale) + 'px';
        rightBottomTile.css.padding = this.tilePadding / 2.0 + "px";
        this.rowCss.width = totalWidth * scaleEverythingScale + 'px';
        this.rowCss.height = leftTile.css.height;
    };
    /**
     * Get the non-scaled photo size from the given tile
     *
     * @param tile
     */
    PhotoEssayRowComponent.prototype.getPhotoSizeFromTile = function (tile) {
        // Note: Treat any missing photos as 500x500 squares
        var kintSize = {
            height: 500,
            width: 500
        };
        if (tile.photo && tile.photo.size) {
            kintSize.height = tile.photo.size.height;
            kintSize.width = tile.photo.size.width;
        }
        return kintSize;
    };
    /**
     * Check to see if this is a row that should be displayed or not
     *
     * @returns {boolean}
     * @memberof PhotoEssayRowComponent
     */
    PhotoEssayRowComponent.prototype.shouldShow = function () {
        if (!this.editor.isEditMode && this.photoEssayLayoutService.isEmptyCaptionRow(this.row)) {
            return false;
        }
        return true;
    };
    /**
     * Check to see if this row (or a child) has a focus
     *
     * @returns {boolean}
     * @memberof PhotoEssayRowComponent
     */
    PhotoEssayRowComponent.prototype.hasFocus = function () {
        // TODO: Probably shouldn't have jquery calls here, but not sure on other options
        return $(this.elementRef.nativeElement).find('*:focus').length > 0;
    };
    /**
     * Add a new row of the given type
     *
     * @param {PhotoEssayRowTypes} rowType
     * @memberof PhotoEssayRowComponent
     */
    PhotoEssayRowComponent.prototype.addRow = function (rowType) {
        var insertAfterRowIndex = this.editor.getIndexOfRow(this.row);
        this.editor.addRow(rowType, insertAfterRowIndex);
    };
    /**
     * Delete the whole row. Delegate this to the parent!
     *
     * @returns {void}
     * @memberof PhotoEssayRowComponent
    /**
     *
     *
     * @returns {void}
     * @memberof PhotoEssayRowComponent
     */
    PhotoEssayRowComponent.prototype.deleteRow = function () {
        var photosInRow = this.photoEssayLayoutService.getPhotosFromRow(this.row);
        var photoOrPhotos = 'Photos';
        if (photosInRow.length === 1) {
            photoOrPhotos = 'Photo';
        }
        // Confirmation dialog!
        var title = 'Delete photo row?';
        var warning = photosInRow.length + ' ' + photoOrPhotos + ' will be deleted. This action cannot be undone.';
        // Different confirmation for text-only
        if (this.row.type === PhotoEssayRowTypes.CAPTION) {
            title = 'Delete caption?';
            warning = 'This caption will be deleted. This action cannot be undone.';
        }
        else if (photosInRow.length === 0) {
            // Photo row with no photos. Go ahead and delete it!
            this.deleteRowConfirmed();
            return;
        }
        var confirmMessage = 'Delete';
        var cancelMessage = 'Cancel';
        var component = this;
        var confirmDlg = this.messageBox.confirmPromptAdvanced(title, warning, confirmMessage, cancelMessage);
        confirmDlg.result.then(function () {
            component.deleteRowConfirmed();
        }, function () {
            // tslint:disable-next-line:no-console
            console.info('Cancelled row deletion');
        });
    };
    /**
     * Deletion has been confirmed. Perform the deletion
     *
     * @memberof PhotoEssayRowComponent
     */
    PhotoEssayRowComponent.prototype.deleteRowConfirmed = function () {
        var _this = this;
        // Add a max-height of full-height so that we can animate down to max-height of 0!
        var elementHeightString = $(this.elementRef.nativeElement)[0].getBoundingClientRect().height + 'px';
        $(this.elementRef.nativeElement).find('.photo-essay-row-row').css({ 'max-height': elementHeightString });
        // Wait just a moment for the DOM to catch up, and the mark it as deleted
        // After animating, remove the row!
        setTimeout(function () {
            $(_this.elementRef.nativeElement).find('.photo-essay-row-row').addClass('photo-essay-row-row-deleted');
            setTimeout(function () {
                _this.editor.deleteRow(_this.row);
            }, 500);
        }, 100);
    };
    /**
     * Build a serialized string representing all of the photo ids in this row
     *
     * @returns {string}
     * @memberof PhotoEssayRowComponent
     */
    PhotoEssayRowComponent.prototype.buildSerializedPhotoIdsString = function () {
        var serializedPhotoIdsString = '';
        for (var _i = 0, _a = this.row.tiles; _i < _a.length; _i++) {
            var tile = _a[_i];
            if (tile.photo && tile.photo.id) {
                serializedPhotoIdsString += ',' + tile.photo.id;
            }
        }
        return serializedPhotoIdsString;
    };
    return PhotoEssayRowComponent;
}());
export { PhotoEssayRowComponent };
// Provide a downgraded version of this to run with AngularJS
angular.module('KintributeApp')
    .directive('photoEssayRow', downgradeComponent({
    component: PhotoEssayRowComponent,
    inputs: ['row', 'editor']
}));
