import { Component, OnInit, Input, Output } from '@angular/core';
import {switchMap, debounceTime, tap, finalize} from 'rxjs/operators';
import { LocationLookupTomTomService } from '../Autocomplete/TomTom/location-lookup-tomtom.service';
import { LocationLookupPhotonService } from '../Autocomplete/Photon/location-lookup-photon.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { downgradeComponent } from '@angular/upgrade/static';
import { EventEmitter } from '@angular/core';
import { KintLocation, KintGeo, KintAddress, KintAddressDisplayInfo } from 'src/app/KintObject/kint-location';
import { LocationAutoCompleteResult } from '../Autocomplete/LocationAutoCompleteResult';

// Angular is loaded separately
declare var angular: any;

// Naughty jquery usage
declare var $: any;

@Component({
  selector: 'app-location-edit',
  templateUrl: './location-edit.component.html',
  styleUrls: ['./location-edit.component.css']
})
export class LocationEditComponent implements OnInit {

  // If we should acquire focus immediately or not
  @Input('shouldFocus') shouldFocus: boolean;

  // If we should show the rounded-version instead
  @Input('rounded') rounded: boolean;

  // Specific classes we should apply to the input box
  @Input('specificClasses') specificClasses: string;

  // Location currently selected
  @Input('location') location: KintLocation;

  // Location to center results around (if any)
  @Input('geoCenter') geoCenter: KintGeo;

  // Whenever the location text changes
  @Output('textChanged') textChanged = new EventEmitter<string>();

  // Whenever user selects a location from auto-complete
  @Output('locationSelected') locationSelected = new EventEmitter<KintLocation>();

  // Locations matching (for auto-complete)
  filteredLocations: LocationAutoCompleteResult[] = [];
  locationForm: FormGroup;
  isLoading = false;

  constructor(private fb: FormBuilder,
              private locationLookupTomTomService: LocationLookupTomTomService,
              private locationLookupPhotonService: LocationLookupPhotonService) { }


  /**
   * Initialize this component
   *
   * @memberof LocationEditComponent
   */
  ngOnInit() {
    this.locationForm = this.fb.group({
      locationInput: null
    });

    console.log('SpecificClasses: ' + this.specificClasses);

    // Optional initial value
    if (this.location) {
      this.locationForm.get('locationInput').setValue(this.location.name);
    }

    this.locationForm.get('locationInput').valueChanges
      .pipe(
        debounceTime(300),
        tap(() => this.isLoading = true),
        tap(() => this.emitTextChanged()),
        switchMap(value => {

          // If the "value" of our input is actually an object (instead of
          // a string), then don't do any autocompletes
          let dataToPipe = this.locationLookupTomTomService.buildEmptyResults();
          if (!value.name) {
            dataToPipe = this.locationLookupTomTomService.search(value, this.geoCenter);
          }

          return dataToPipe.pipe(
              finalize(() => {
                this.isLoading = false;
              })
            );
          }
        )
      )
      .subscribe(locationResponse => this.filteredLocations = locationResponse.results);


    if (this.shouldFocus) {
      // Ensure we have focus. Yes, I know jQuery is naughty
      setTimeout(function() {
        $('.location-edit-field').focus();
      }, 100);
    }
  }


  /**
   * Determine if specific classes were passed in by the parent
   *
   * @return  boolean
   */
  hasSpecificClasses(): boolean {
    if (this.specificClasses && this.specificClasses.length > 0) {
      return true;
    }
    return false;
  }


  /**
   * Gets the address to display alongside the 'name'
   */
  getDisplayableAddress(): KintAddressDisplayInfo {
    if (this.location) {
      const displayInfo = KintLocation.getAddressDisplayInfo(this.location);
      return displayInfo;
    }
    return null;
  }


  /**
   * Display function for displaying the given autocomplete data
   *
   * @param kintLocationOrString
   * @returns
   * @memberof LocationEditComponent
   */
  displayFn(locationOrString: any) {
    if (locationOrString && locationOrString.name) {
      const displayInfo = KintLocation.getAddressDisplayInfo(locationOrString);
      return displayInfo.primary;
    } else {
      return locationOrString;
    }
  }


  /**
   * Emits the 'textChanged' event to the parent
   *
   * @memberof LocationEditComponent
   */
  emitTextChanged(): void {
    console.log('emitTextChanged');
    let inputString = '';
    // When our Input value changes, sent an event with the new string
    // Input value may be a Location OR a string
    const inputValue = this.locationForm.get('locationInput').value;
    if (inputValue) {
      if (inputValue.name) {
        inputString = inputValue.name;
      } else {
        inputString = inputValue;
      }
    }

    this.textChanged.emit(inputString);
  }


  /**
   * Autocomplete was selected
   *
   * @param {LocationAutoCompleteResult} selection
   * @memberof LocationEditComponent
   */
  autocompleteSelected(selection: LocationAutoCompleteResult): void {
    console.log('autocompleteSelected');
    const kintLocation = selection.toKintLocation();
    this.locationSelected.emit(kintLocation);
  }
}


// Provide a downgraded version of this to run with AngularJS
angular.module('KintributeApp')
  .directive('locationEdit', downgradeComponent(
    {
      component: LocationEditComponent,
      inputs: ['shouldFocus', 'rounded', 'specificClasses', 'location'],
      outputs: ['textChanged', 'locationSelected']
    }
  ));
