/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./personal-page.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../personal-page-header/personal-page-header.component.ngfactory";
import * as i3 from "../personal-page-header/personal-page-header.component";
import * as i4 from "../personal-page-content/personal-page-content.component.ngfactory";
import * as i5 from "../personal-page-content/personal-page-content.component";
import * as i6 from "./personal-page.component";
var styles_PersonalPageComponent = [i0.styles];
var RenderType_PersonalPageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PersonalPageComponent, data: {} });
export { RenderType_PersonalPageComponent as RenderType_PersonalPageComponent };
export function View_PersonalPageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "personal-page"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-personal-page-header", [], null, null, null, i2.View_PersonalPageHeaderComponent_0, i2.RenderType_PersonalPageHeaderComponent)), i1.ɵdid(2, 8765440, null, 0, i3.PersonalPageHeaderComponent, [i1.ElementRef], { header: [0, "header"], isMobile: [1, "isMobile"], editor: [2, "editor"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "app-personal-page-content", [], null, null, null, i4.View_PersonalPageContentComponent_0, i4.RenderType_PersonalPageContentComponent)), i1.ɵdid(4, 114688, null, 0, i5.PersonalPageContentComponent, [], { page: [0, "page"], isMobile: [1, "isMobile"], editor: [2, "editor"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.page.header; var currVal_1 = _co.isMobile(); var currVal_2 = _co.editor; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _co.page; var currVal_4 = _co.isMobile(); var currVal_5 = _co.editor; _ck(_v, 4, 0, currVal_3, currVal_4, currVal_5); }, null); }
export function View_PersonalPageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-personal-page", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_PersonalPageComponent_0, RenderType_PersonalPageComponent)), i1.ɵdid(1, 114688, null, 0, i6.PersonalPageComponent, ["AppDataModelService"], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PersonalPageComponentNgFactory = i1.ɵccf("app-personal-page", i6.PersonalPageComponent, View_PersonalPageComponent_Host_0, { page: "page", editor: "editor" }, {}, []);
export { PersonalPageComponentNgFactory as PersonalPageComponentNgFactory };
