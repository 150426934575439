import { OnInit } from '@angular/core';
import { KintTimelineSupplementLink } from '../../timeline-supplement';
var TimelineSupplementLinkComponent = /** @class */ (function () {
    function TimelineSupplementLinkComponent() {
    }
    TimelineSupplementLinkComponent.prototype.ngOnInit = function () {
    };
    return TimelineSupplementLinkComponent;
}());
export { TimelineSupplementLinkComponent };
