<div class="timeline-layout-vertical"
     [ngClass]="{'timeline-layout-vertical-mobile': isMobile()}">

  <!-- Display all of the timeline blocks -->
  <!-- <div> blocks: {{timeline.timelineBlocks.length}}</div> -->
  <div *ngFor="let timelineBlock of timeline.timelineBlocks">
    <app-timeline-block-vertical [timelineBlock]="timelineBlock" [timeline]="timeline">
    </app-timeline-block-vertical>
  </div>


  <div *ngIf="timeline.undatedPhotos.length > 0">
    <div class="timeline-layout-vertical-undated-photos">
      + {{timeline.undatedPhotos.length}} Undated Photos 
    </div>

    <div class="clearfix"></div>
  </div>

</div>