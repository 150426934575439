import { Component, OnInit, Input, Inject, HostListener } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';

import { KintPersonalPage } from 'src/app/KintObject/kint-personal-page';
import { PersonalPageEditor } from '../personal-page-editor';


// Angular is loaded separately
declare var angular: any;


@Component({
  selector: 'app-personal-page',
  templateUrl: './personal-page.component.html',
  styleUrls: ['./personal-page.component.css']
})
export class PersonalPageComponent implements OnInit {

  @Input('page') page: KintPersonalPage;
  @Input('editor') editor: PersonalPageEditor;

  constructor(@Inject('AppDataModelService') private appDataModelService: any) { }

  ngOnInit() {
  }

  isMobile(): boolean {
    return this.appDataModelService.isMobile();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isMobile = this.appDataModelService.isMobile();
  }

}


// Provide a downgraded version of this to run with AngularJS
angular.module('KintributeApp')
  .directive('personalPage', downgradeComponent(
    {
      component: PersonalPageComponent,
      inputs: ['page']
    }
  ));
