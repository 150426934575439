import { KintLocation, KintGeo, KintAddress } from 'src/app/KintObject/kint-location';
var LocationAutoCompleteTomTomResult = /** @class */ (function () {
    function LocationAutoCompleteTomTomResult(location) {
        this.location = location;
        this.name = '';
        this.addressShort = '';
        // Prefer the Point of Interest
        if (location.poi) {
            this.name = location.poi.name;
        }
        if (location.address) {
            this.address = new KintAddress(this.name, location.address.streetNumber, location.address.streetName, location.address.municipality, location.address.countrySubdivisionName, location.address.country, location.address.postalCode);
            // Build a short address
            this.addressShort = location.address.freeformAddress;
            // If no name, then use the number+street as the name and the rest as 'extra'
            if (!this.name) {
                if (this.address.houseNumber && this.address.street) {
                    this.name = this.address.houseNumber + ' ' + this.address.street;
                }
            }
            // If still no name, then use the whole address
            if (!this.name) {
                this.name = this.addressShort;
            }
        }
        this.poi = location.poi;
        this.position = new KintGeo(location.position.lat, location.position.long);
    }
    /**
     * Build a KintLocation from this autocomplete data
     */
    LocationAutoCompleteTomTomResult.prototype.toKintLocation = function () {
        var kintLocation = new KintLocation(this.name);
        kintLocation.name = this.name;
        kintLocation.address = this.address;
        kintLocation.addressShort = this.addressShort;
        kintLocation.position = this.position;
        return kintLocation;
    };
    return LocationAutoCompleteTomTomResult;
}());
export { LocationAutoCompleteTomTomResult };
var LocationAutoCompleteTomTomResponse = /** @class */ (function () {
    function LocationAutoCompleteTomTomResponse() {
        this.results = [];
    }
    return LocationAutoCompleteTomTomResponse;
}());
export { LocationAutoCompleteTomTomResponse };
