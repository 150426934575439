import { Component, OnInit, Input, Inject, EventEmitter, Output } from '@angular/core';
import { KintFaceComparePrompt, KintFace } from 'src/app/KintObject/kint-faces';
import { downgradeComponent } from '@angular/upgrade/static';

// Angular is loaded separately
declare var angular: any;

// Externally-loaded jQuery (yes, I know this is bad mojo)
declare var $: any;


@Component({
  selector: 'app-face-compare',
  templateUrl: './face-compare.component.html',
  styleUrls: ['./face-compare.component.css']
})
export class FaceCompareComponent implements OnInit {

  constructor(@Inject('FaceComparePromptService') private faceComparePromptService: any,
              @Inject('DOMService') private domService: any) { }

  @Input('faceComparePrompts') faceComparePrompts: KintFaceComparePrompt[];
  @Output('closeCallback') closeCallback = new EventEmitter<any>();

  // Current FaceComparePrompt that we are displaying
  public currentFaceComparePrompt: KintFaceComparePrompt;
  private promptingForPersonId = null;
  public isProcessing = false;

  private FACE_SLIDE_ANIMATION_DURATION = '0.75s';
  private FACE_SLIDE_ANIMATION_DISTANCE = '700px';
  private FACE_THUMBNAIL_HOLDER_SELECTOR = '.face-compare-thumbnail-holder';


  /**
   * Initialize Component
   */
  ngOnInit() {
    if (this.faceComparePrompts.length < 1) {
      console.error('FaceCompareComponent may not be loaded without a list of faceComparPrompts. Closing');
      return;
    }
    this.currentFaceComparePrompt = this.faceComparePrompts[0];
    this.promptingForPersonId = this.currentFaceComparePrompt.personFace.person.id;
    this.zoomToFace();
  }


  /**
   * Close the dialog
   */
  closeDialog(): void {
    if (this.closeCallback) {
      this.closeCallback.emit();
    }
  }


  /**
   * Move to the next prompt in the list
   */
  moveToNextFace(): void {

    // Get a new array from FaceComparePromptService (if it exists)
    // This helps us adapt if a single answer results in new/different FaceComparePrompts
    this.faceComparePrompts = this.faceComparePromptService.getFaceComparePromptsForPerson(this.promptingForPersonId);

    // Find the next, unanswered faceComparePrompt
    for (let i = 0; i < this.faceComparePrompts.length; i++) {
      const nextFaceComparePrompt = this.faceComparePrompts[i];
      if (!nextFaceComparePrompt.answeredLocally && (nextFaceComparePrompt.id !== this.currentFaceComparePrompt.id)) {
        this.moveToSpecificFace(nextFaceComparePrompt);
        return;
      }
    }

    // We've reached the end. Close this dialog
    this.closeDialog();
  }

  moveToSpecificFace(nextFace: KintFaceComparePrompt): void {
    this.currentFaceComparePrompt = nextFace;
    this.animateFaceSliding();
    this.zoomToFace();
  }

  zoomToFace(): void {

    /*

    ** Skip this for now **

    // Make sure the source image is centered/zoomed around the newFaceId
    if (!this.currentFaceComparePrompt.newFace.photo || !this.currentFaceComparePrompt.newFace.photo.faces) {
      return;
    }
    this.currentFaceComparePrompt.newFace.photo.faces.forEach((face) => {
      if (face.faceId === this.currentFaceComparePrompt.newFace.faceId) {
        console.log('face.coordinates: ', face.coordinates);
        const percentX = (face.coordinates.left + face.coordinates.width / 2.0) * 100;
        const percentY = (face.coordinates.top + face.coordinates.height / 2.0) * 100;

        console.log('percentX: ', percentX);
        console.log('percentY: ', percentY);

        setTimeout(() => {
          $('.face-compare-top').css({
            backgroundPositionX: '-' + percentX + '%',
            backgroundPositionY: '-' + percentY + '%'
          });
        }, 2000);
      }
    });
    */

    return;
  }

  confirmFace(): void {
    const response = this.faceComparePromptService.FACE_COMPARE_PROMPT_RESPONSE.MATCH;
    this.respondToFace(this.currentFaceComparePrompt, response);
  }

  rejectFace(): void {
    const response = this.faceComparePromptService.FACE_COMPARE_PROMPT_RESPONSE.NOT_MATCH;
    this.respondToFace(this.currentFaceComparePrompt, response);
  }

  skipFace(): void {
    const response = this.faceComparePromptService.FACE_COMPARE_PROMPT_RESPONSE.SKIP;
    this.respondToFace(this.currentFaceComparePrompt, response);
  }


  animateFaceSliding(): void {

    // Slide away right
    $(this.FACE_THUMBNAIL_HOLDER_SELECTOR).css({
      marginLeft: this.FACE_SLIDE_ANIMATION_DISTANCE
    });

    setTimeout(() => {

      // Disable the transition and slide them way-left again
      $(this.FACE_THUMBNAIL_HOLDER_SELECTOR).css({
        transition: 'none'
      });
      $(this.FACE_THUMBNAIL_HOLDER_SELECTOR).css({
        marginLeft: '-' + this.FACE_SLIDE_ANIMATION_DISTANCE
      });

      // Re-enable the animation and animate it in.
      setTimeout( () => {
        $(this.FACE_THUMBNAIL_HOLDER_SELECTOR).css({
          transition: 'margin-left ' + this.FACE_SLIDE_ANIMATION_DURATION
        });
        $(this.FACE_THUMBNAIL_HOLDER_SELECTOR).css({
          marginLeft: '0px'
        });
      });

    }, 600);

  }


  /**
   * Handle the user's input and then advance to the next prompt (if any)
   */
  respondToFace(faceComparePrompt: KintFaceComparePrompt, response: string): void {

    const self = this;

    const faceComparePromptId = faceComparePrompt.id;
    faceComparePrompt.answeredLocally = true;

    this.isProcessing = true;
    this.faceComparePromptService.respondToFaceComparePrompt(faceComparePromptId, response).then(
      function() {
        console.log('Successfully matched face');
      },
      function(err) {
        console.error('Error responding to face: ', err);
      }
    ).finally(function() {
      console.log('response processed. Waiting...');

      // Artificial delay...
      self.moveToNextFace();
      self.isProcessing = false;

    });
  }

}


// Provide a downgraded version of this to run with AngularJS
angular.module('KintributeApp')
  .directive('faceCompareComponent', downgradeComponent(
    {
      component: FaceCompareComponent,
      inputs: []
    }
  ));
