import { DateHelper } from '../Util/Date/DateHelper';

/**
 * Kintribute Date class that we use. This supports optional days and months
 *
 * Note: day: -1 means "unknown day"
 * Note: month: -1 means "unknown month"
 */

export class KintDate {
  day: number;      // 1-based Day number.
  month: number;    // 1-based Month number (Jan = 1, Feb = 2, etc)
  year: number;     // 4-digit year number
  isoDate: string;  // ISO Date string: 2019-01-01T06:00:00.000Z  (expected to be in Zulu-time)

  constructor(day: number, month: number, year: number, isoDate: string) {
    this.day = day;
    this.month = month;
    this.year = year;
    this.isoDate = isoDate;
    if (!isoDate && year) {
      const dayToUse = (this.day != null) && (this.day >= 0) ? this.day : 0;
      const monthToUse = (this.month > 0) ? (this.month - 1) : 0;  // (Javascript months are 0-based, thus the 'month -1')
      const yearToUse = (this.year >= 0) ? this.year : 0;

      // manually build the ISO Date so we don't bias it with our current timezone
      const generatedIsoDate = DateHelper.buildUnbiasZeroBasedISODateString(yearToUse, monthToUse, dayToUse);
      this.isoDate = generatedIsoDate;
    }
  }


  /**
   * Builds a new KintDate object from the KintDate object which may or may not exist
   *
   * Note: May return null
   */
  public static fromDate(kintDate: KintDate): KintDate {
    if (!kintDate) {
      return null;
    }

    return new KintDate(kintDate.day, kintDate.month, kintDate.year, kintDate.isoDate);
  }


  /**
   * See if the given date is equivilent to "not dated"
   * @param date
   */
  public static isUndated(date): boolean {
    if (!date) {
      return true;
    }

    // If no year, then it's undated
    if (date.year == null) {
      return true;
    }

    if ((date.day < 0) && (date.month < 0) && (date.year < 0)) {
      return true;
    }
    return false;
  }
}
