import { Component, OnInit, Input, Inject } from '@angular/core';
import { PersonalPageTileObject } from 'src/app/KintObject/kint-personal-page';
import { KintPhoto } from 'src/app/KintObject/kint-photo';
import { PersonalPageEditor } from '../personal-page-editor';

@Component({
  selector: 'app-personal-page-tile',
  templateUrl: './personal-page-tile.component.html',
  styleUrls: ['./personal-page-tile.component.css']
})
export class PersonalPageTileComponent implements OnInit {

  @Input('tile') tile: PersonalPageTileObject;
  @Input('isMobile') isMobile: boolean;
  @Input('editor') editor: PersonalPageEditor;

  constructor(@Inject('LinkHelperService') private linkHelperService: any) { }

  ngOnInit() {
    console.log('personal-page-tiles: ' + JSON.stringify(this.tile));
  }


  getTileLink(): string {
    if (this.tile.collection) {
      return this.linkHelperService.buildCollectionLink(this.tile.collection);
    }
    if (this.tile.essay) {
      return this.linkHelperService.buildEssayLink(this.tile.essay);
    }
    console.error('Error getting link for unknown tile type');
  }

  getCover(): KintPhoto {
    if (this.tile.collection) {
      return this.tile.collection.cover;
    } else {
      return this.tile.essay.cover;
    }
  }


  getTileType(): string {
    if (this.tile.collection) {
      return 'Collection';
    } else {
      return 'Photo Essay';
    }
  }


  getTileName(): string {
    if (this.tile.collection) {
      return this.tile.collection.name;
    } else {
      return this.tile.essay.name;
    }
  }

}
