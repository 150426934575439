/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./personal-page-header.component.css.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../Photo/photo-display.directive";
import * as i4 from "../../Log/log.service";
import * as i5 from "../../Photo/photo-selection.service";
import * as i6 from "../../ng1/General/kint-icon-component";
import * as i7 from "./personal-page-header.component";
var styles_PersonalPageHeaderComponent = [i0.styles];
var RenderType_PersonalPageHeaderComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_PersonalPageHeaderComponent, data: {} });
export { RenderType_PersonalPageHeaderComponent as RenderType_PersonalPageHeaderComponent };
function View_PersonalPageHeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["appPhotoDisplay", ""], ["class", "personal-page-header-image"]], null, [["window", "resize"], [null, "load"], [null, "error"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onResize($event) !== false);
        ad = (pd_0 && ad);
    } if (("load" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onImageLoaded($event) !== false);
        ad = (pd_1 && ad);
    } if (("error" === en)) {
        var pd_2 = (i1.ɵnov(_v, 3).onImageLoadError($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "personal-page-header-image-fade-in": 0, "personal-page-header-image-fade-out": 1 }), i1.ɵdid(3, 344064, null, 0, i3.PhotoDisplayDirective, [i1.ElementRef, i4.LogService, i5.PhotoSelectionService], { photoData: [0, "photoData"], isBackgroundImage: [1, "isBackgroundImage"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "personal-page-header-image"; var currVal_1 = _ck(_v, 2, 0, _co.image1IsActive, !_co.image1IsActive); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.image1; var currVal_3 = true; _ck(_v, 3, 0, currVal_2, currVal_3); }, null); }
function View_PersonalPageHeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["appPhotoDisplay", ""], ["class", "personal-page-header-image personal-page-header-large"]], null, [["window", "resize"], [null, "load"], [null, "error"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onResize($event) !== false);
        ad = (pd_0 && ad);
    } if (("load" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onImageLoaded($event) !== false);
        ad = (pd_1 && ad);
    } if (("error" === en)) {
        var pd_2 = (i1.ɵnov(_v, 3).onImageLoadError($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "personal-page-header-image-fade-in": 0, "personal-page-header-image-fade-out": 1 }), i1.ɵdid(3, 344064, null, 0, i3.PhotoDisplayDirective, [i1.ElementRef, i4.LogService, i5.PhotoSelectionService], { photoData: [0, "photoData"], isBackgroundImage: [1, "isBackgroundImage"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "personal-page-header-image personal-page-header-large"; var currVal_1 = _ck(_v, 2, 0, _co.image2IsActive, !_co.image2IsActive); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.image2; var currVal_3 = true; _ck(_v, 3, 0, currVal_2, currVal_3); }, null); }
function View_PersonalPageHeaderComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "personal-page-header-edit-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "personal-page-edit-icon-holder"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openSettingsDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "kint-icon", [["class", "header-photo-add-picture-icon"], ["data-icon", "pencil"]], null, null, null, null, null)), i1.ɵdid(3, 999424, null, 0, i6.KintIconDirective, [i1.ElementRef, i1.Injector], { icon: [0, "icon"] }, null)], function (_ck, _v) { var currVal_0 = "pencil"; _ck(_v, 3, 0, currVal_0); }, null); }
function View_PersonalPageHeaderComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "personal-page-header-headline"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "personal-page-header-subline"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PersonalPageHeaderComponent_4)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = (!_co.editor.isEditMode && _co.userCanEdit()); _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.header.headline; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.header.subline; _ck(_v, 4, 0, currVal_1); }); }
export function View_PersonalPageHeaderComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { headlineField: 0 }), i1.ɵqud(402653184, 2, { sublineField: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 10, "div", [["class", "personal-page-header"]], null, null, null, null, null)), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "personal-page-header-large": 0, "personal-page-header-mobile": 1, "personal-page-header-can-edit": 2 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PersonalPageHeaderComponent_1)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PersonalPageHeaderComponent_2)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 0, "div", [["class", "personal-page-header-image-dimmer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "personal-page-header-content"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PersonalPageHeaderComponent_3)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "personal-page-header"; var currVal_1 = _ck(_v, 4, 0, 1, _co.isMobile, _co.userCanEdit()); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _co.image1; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.image2; _ck(_v, 8, 0, currVal_3); var currVal_4 = _co.header; _ck(_v, 12, 0, currVal_4); }, null); }
export function View_PersonalPageHeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-personal-page-header", [], null, null, null, View_PersonalPageHeaderComponent_0, RenderType_PersonalPageHeaderComponent)), i1.ɵdid(1, 8765440, null, 0, i7.PersonalPageHeaderComponent, [i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PersonalPageHeaderComponentNgFactory = i1.ɵccf("app-personal-page-header", i7.PersonalPageHeaderComponent, View_PersonalPageHeaderComponent_Host_0, { header: "header", isMobile: "isMobile", editor: "editor" }, {}, []);
export { PersonalPageHeaderComponentNgFactory as PersonalPageHeaderComponentNgFactory };
