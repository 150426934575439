import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { LocationAutoCompletePhotonResult, LocationAutoCompletePhotonResponse } from './location-autocomplete-photon-result';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var LocationLookupPhotonService = /** @class */ (function () {
    function LocationLookupPhotonService(http) {
        this.http = http;
        this.browserGeoLocation = null;
    }
    LocationLookupPhotonService.prototype.setBrowserLocation = function (position) {
        this.browserGeoLocation = {
            lat: position.latitude,
            long: position.longitude
        };
    };
    /**
     * Build Photon Location lookup URL
     *
     * @param {string}    searchString    String we are searching for
     * @param {boolean}   isForTypahead   (whether or not this is for typeahead or not)
     * @param {KintGeo}   geoCenter       (optional for centering around the photo's coords)
     * @returns {string}
     * @memberof LocationLookupService
     */
    LocationLookupPhotonService.prototype.buildLookupUrl = function (searchString, isForTypahead, geoCenter) {
        // Get the location of *this* browser (if available).
        // Note:  This may result in a prompt being put up to the user to allow geo-location
        if (navigator.geolocation) {
            var self_1 = this;
            navigator.geolocation.getCurrentPosition(function (position) {
                if (position && position.coords) {
                    self_1.setBrowserLocation(position.coords);
                }
            });
        }
        else {
            console.error('Geolocation no supported by this browser');
        }
        // Using Photon as a location provider. Build up the params for the fuzzy-search.
        // Important flags:
        //  - GEO and POI  so we get "Las Vegas, NV" and "MGM Grand, Las Vegas, NV"
        //  - typeahead = true (so it knows we may be mid-typing)
        //  - lat/long for the browser's location
        var locationLookupUrl = 'https://photon.komoot.de/api/?q=NAME_REPLACE&lang=en';
        locationLookupUrl = locationLookupUrl.replace('NAME_REPLACE', searchString);
        /*
        locationLookupUrl = locationLookupUrl.replace('KEY_REPLACE', 'DQ5NfVmexIYSt3YGlJDrArNw1pI1ashW');
        if (isForTypahead) {
          locationLookupUrl = locationLookupUrl + '&typeahead=true';
        }
        */
        // Add a centering bias for the lookup results
        if (geoCenter) {
            // Center around a specific set of coordinates (probably because that was on a photo)
            var locationBias = '&lat=' + this.browserGeoLocation.lat + '&lon=' + this.browserGeoLocation.long;
            locationLookupUrl = locationLookupUrl + locationBias;
        }
        else if (this.browserGeoLocation) {
            // Center around whatever the browser tells us our location is
            var locationBias = '&lat=' + this.browserGeoLocation.lat + '&lon=' + this.browserGeoLocation.long;
            locationLookupUrl = locationLookupUrl + locationBias;
        }
        return locationLookupUrl;
    };
    /**
     * Searches for the given location. This is a 'final' result and not to
     * be used for auto-complete use cases
     *
     * @param {''} searchString
     * @param {geoCenter} geoCenter (optional 'center' for auto-complete results)
     * @returns {Promise<ILocationAutoCompletePhotonResponse>}
     * @memberof LocationLookupService
     */
    LocationLookupPhotonService.prototype.searchFinal = function (searchString, geoCenter) {
        // If no search terms, just return immediately with an empty search result
        if (!searchString) {
            var promise = new Promise(function (resolve, reject) {
                resolve(new LocationAutoCompletePhotonResponse());
            });
            return promise;
        }
        var isForTypeahead = false;
        var locationLookupUrl = this.buildLookupUrl(searchString, isForTypeahead, geoCenter);
        return this.http.get(locationLookupUrl).toPromise();
    };
    /**
     * Runs the given search and returns an observable containin the response
     *
     * @param {string} searchString
     * @param {KintGeo} geoCenter  (optional location to center around for auto-complete)
     * @returns {Observable<ILocationAutoCompletePhotonResponse>}
     * @memberof LocationLookupService
     */
    LocationLookupPhotonService.prototype.search = function (searchString, geoCenter) {
        // If no search string, just return an empty response (pretend it never happend)
        if (!searchString) {
            return this.buildEmptyResults();
        }
        var isForTypeahead = true;
        var locationLookupUrl = this.buildLookupUrl(searchString, isForTypeahead, geoCenter);
        return this.http.get(locationLookupUrl)
            .pipe(tap(function (response) {
            response.features = response.features
                .map(function (feature) { return new LocationAutoCompletePhotonResult(feature); });
            return response;
        }), catchError(function (err) {
            return new Observable();
        }));
    };
    /**
     * Build an empty ILocationAutoCompletePhotonResponse. This is useful to fake
     * an 'empty' result from the server
     */
    LocationLookupPhotonService.prototype.buildEmptyResults = function () {
        var response = new Observable(function (observer) {
            observer.next(new LocationAutoCompletePhotonResponse());
            observer.complete();
        });
        return response;
    };
    LocationLookupPhotonService.ngInjectableDef = i0.defineInjectable({ factory: function LocationLookupPhotonService_Factory() { return new LocationLookupPhotonService(i0.inject(i1.HttpClient)); }, token: LocationLookupPhotonService, providedIn: "root" });
    return LocationLookupPhotonService;
}());
export { LocationLookupPhotonService };
