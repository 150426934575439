var KintGeo = /** @class */ (function () {
    function KintGeo(lat, long) {
        this.lat = lat;
        this.long = long;
    }
    return KintGeo;
}());
export { KintGeo };
var KintLocation = /** @class */ (function () {
    function KintLocation(name) {
        if (!name) {
            name = '';
        }
        this.name = name;
        this.address = null;
        this.addressShort = '';
        this.position = null;
    }
    /**
     * Gets the data that we want to display for the given location
     *
     * @static
     * @param {KintLocation} location
     * @returns {KintAddressDisplayInfo}
     * @memberof KintLocation
     */
    KintLocation.getAddressDisplayInfo = function (location) {
        if (!location) {
            return new KintAddressDisplayInfo('', '');
        }
        var addressDisplayInfo = new KintAddressDisplayInfo(location.name, '');
        // Name is the most important (and will always be here)
        if (location.name) {
            addressDisplayInfo.primary = location.name;
            addressDisplayInfo.secondary = KintAddress.getShortAddress(location.address);
        }
        // Handle all the fields of the address
        if (location.address) {
            if (!addressDisplayInfo.primary) {
                if (location.address.houseNumber && location.address.street) {
                    addressDisplayInfo.primary = location.address.houseNumber + ' ' + location.address.street;
                    addressDisplayInfo.secondary = KintAddress.getShortAddress(location.address);
                }
            }
            // Remove the 'name' from the beginning of the address (if it starts with that)
            if (addressDisplayInfo.primary && addressDisplayInfo.secondary) {
                if (0 === addressDisplayInfo.secondary.indexOf(addressDisplayInfo.primary)) {
                    addressDisplayInfo.secondary = addressDisplayInfo.secondary.substr(addressDisplayInfo.primary.length + 1);
                }
            }
            // Remove "United States of America" (someday we can do some international support)
            if (addressDisplayInfo.secondary) {
                addressDisplayInfo.secondary = addressDisplayInfo.secondary.replace(', United States of America', '');
                addressDisplayInfo.secondary = addressDisplayInfo.secondary.replace(', United States Of America', '');
            }
        }
        return addressDisplayInfo;
    };
    return KintLocation;
}());
export { KintLocation };
/**
 * Displayable lines for an address (primary/secondary)
 *
 * @export
 * @class KintAddressDisplayInfo
 */
var KintAddressDisplayInfo = /** @class */ (function () {
    function KintAddressDisplayInfo(primary, secondary) {
        this.primary = primary;
        this.secondary = secondary;
    }
    return KintAddressDisplayInfo;
}());
export { KintAddressDisplayInfo };
var KintAddress = /** @class */ (function () {
    function KintAddress(name, houseNumber, street, city, state, country, postalCode) {
        this.name = name;
        this.houseNumber = houseNumber;
        this.street = street;
        this.city = city;
        this.state = state,
            this.country = country;
        this.postalCode = postalCode;
    }
    KintAddress.getShortAddress = function (address) {
        if (!address) {
            return null;
        }
        var addressShort = '';
        if (address.houseNumber) {
            addressShort = addressShort + address.houseNumber;
        }
        if (address.street) {
            addressShort = addressShort + ' ' + address.street;
        }
        if (address.city) {
            if (addressShort !== '') {
                addressShort += ',';
            }
            addressShort = addressShort + ' ' + address.city;
        }
        if (address.state) {
            if (addressShort !== '') {
                addressShort += ',';
            }
            addressShort = addressShort + ' ' + address.state;
        }
        if (address.country) {
            if (addressShort !== '') {
                addressShort += ',';
            }
            addressShort = addressShort + ' ' + address.country;
        }
        addressShort = addressShort.trim();
        // Replace any duplicate whitespace with a single space
        addressShort.replace(/\s+/, ' ');
        return addressShort;
    };
    return KintAddress;
}());
export { KintAddress };
