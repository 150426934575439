import { ElementRef, Injector, Input, Output, Directive } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

export enum KintButtonType {
    PRIMARY = 'PRIMARY',
    DANGER = 'DANGER',
    DEFAULT = 'DEFAULT',
    LINK = 'LINK'
}

export enum KintButtonSize {
    SMALL = 'SMALL',
    LARGE = 'LARGE',
    LARGE_WIDE = 'LARGE_WIDE'
}

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: 'kint-button'
})

export class KintButtonDirective extends UpgradeComponent {

    @Input() text: string;
    @Input() type: KintButtonType;
    @Input() size: KintButtonSize;
    @Input() fullWidth: any;
    @Input() spin: boolean;
    @Input() disable: boolean;
    @Input() showCheckmark: boolean;
    @Output() clickHandler: Function;


    constructor(elementRef: ElementRef, injector: Injector) {
        console.log('kint-button constructor!');
        super('kintButton', elementRef, injector);
    }
}
