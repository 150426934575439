/**
 *
 * This class can be used to wait for the next UI update of the
 * given component.
 *
 * For example, maybe I execute a command and want to run something
 * else after the DOM is updated. For that, I can simply:
 *
 * this.onNextViewCheck().then( () => {
 *   // do stuff here
 * })
 *
 */
var ViewCheckHelper = /** @class */ (function () {
    function ViewCheckHelper() {
        this.waitingForNextCheck = false;
    }
    /**
     * Called every time the view is re-drawn/checked
     */
    ViewCheckHelper.prototype.ngAfterViewChecked = function () {
        if (this.waitingForNextCheck) {
            this.waitingForNextCheck = false;
        }
    };
    /**
     * Returns a promise which resolves after the next ngAfterViewChecked
     */
    ViewCheckHelper.prototype.onNextViewCheck = function () {
        this.waitingForNextCheck = true;
        var promise = new Promise(function (resolve, reject) {
            function waitAndCheck() {
                setTimeout(function () {
                    if (this.waitingForNextCheck) {
                        waitAndCheck();
                    }
                    else {
                        resolve(true);
                    }
                }, 50);
            }
            waitAndCheck();
        });
        return promise;
    };
    return ViewCheckHelper;
}());
export { ViewCheckHelper };
