import { KintTimelineSupplementAudio,
          KintTimelineSupplementText,
          KintTimelineSupplementMap,
          KintTimelineSupplementLink,
          KintTimelineSupplement } from './timeline-supplement';

import { KintPhoto } from '../KintObject/kint-photo';

export class KintTimelineEntry {
  photo: KintPhoto;
  supplements: KintTimelineSupplement[];

  constructor(photo: KintPhoto) {
    this.photo = photo;
    this.supplements = [];

    // Map
    if (photo.location && photo.location.position) {
      const mapSupplement = new KintTimelineSupplementMap(photo.location);
      this.supplements.push(mapSupplement);
    }
    if (photo.title && photo.title.trim().length > 0) {
      const backstorySupplement = new KintTimelineSupplementText(photo.title);
      this.supplements.push(backstorySupplement);
    }

    // Backstory
    if (photo.story && photo.story.trim().length > 0) {
      const backstorySupplement = new KintTimelineSupplementText(photo.story);
      this.supplements.push(backstorySupplement);
    }

    // Audio
    if (photo.audioStories && photo.audioStories.length > 0) {
      const audioSupplement = new KintTimelineSupplementAudio(photo.audioStories);
      this.supplements.push(audioSupplement);
    }

    // Links
    if (photo.externalLinks && photo.externalLinks.length > 0) {
      photo.externalLinks.forEach((externalLink) => {
        const linkSupplement = new KintTimelineSupplementLink(externalLink);
        this.supplements.push(linkSupplement);
      });
    }

  }
}
