import { Directive, ElementRef, Injector, Input, Output } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';

@Directive({
    // tslint:disable-next-line:directive-selector
    selector: 'photo-essay-add-row'
})
export class PhotoEssayAddRowDirective extends UpgradeComponent {

    @Input('editor') editor: any;

    constructor(elementRef: ElementRef, injector: Injector) {
        console.log('photo-essay-add-row constructor!');
        super('photoEssayAddRow', elementRef, injector);
    }
}
