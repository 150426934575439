/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./timeline-supplement-map.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./timeline-supplement-map.component";
var styles_TimelineSupplementMapComponent = [i0.styles];
var RenderType_TimelineSupplementMapComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TimelineSupplementMapComponent, data: {} });
export { RenderType_TimelineSupplementMapComponent as RenderType_TimelineSupplementMapComponent };
export function View_TimelineSupplementMapComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "tile-supplement-map"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.supplement.location.name; _ck(_v, 1, 0, currVal_0); }); }
export function View_TimelineSupplementMapComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-timeline-supplement-map", [], null, null, null, View_TimelineSupplementMapComponent_0, RenderType_TimelineSupplementMapComponent)), i1.ɵdid(1, 114688, null, 0, i2.TimelineSupplementMapComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TimelineSupplementMapComponentNgFactory = i1.ɵccf("app-timeline-supplement-map", i2.TimelineSupplementMapComponent, View_TimelineSupplementMapComponent_Host_0, { supplement: "supplement" }, {}, []);
export { TimelineSupplementMapComponentNgFactory as TimelineSupplementMapComponentNgFactory };
