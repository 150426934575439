import { Component, OnInit, Input } from '@angular/core';
import { PersonalPageRow } from 'src/app/KintObject/kint-personal-page';
import { PersonalPageEditor } from '../personal-page-editor';

@Component({
  selector: 'app-personal-page-row',
  templateUrl: './personal-page-row.component.html',
  styleUrls: ['./personal-page-row.component.css']
})
export class PersonalPageRowComponent implements OnInit {

  @Input('row') row: PersonalPageRow;
  @Input('isMobile') isMobile: boolean;
  @Input('editor') editor: PersonalPageEditor;

  constructor() { }

  ngOnInit() {
  }

}
