<div class="timeline-entry-horizontal">

  <!-- Date/Time on the side -->
  <div class="timeline-entry-horizontal-label">
    <div class="timeline-entry-horizontal-label-top">
      {{labelTop}}
    </div>
    <div class="timeline-entry-horizontal-label-bottom">
      {{labelBottom}}
    </div>
  </div>


  <div class="timeline-entry-horizontal-content">

    <!-- Full-height photo on top -->
    <div class="timeline-entry-horizontal-photo">
      <div class="timeline-entry-horizontal-photo-img"
           (click)="openPhotoPreview()"
            appPhotoDisplay
            [photoData]="timelineEntry.photo"
            [isBackgroundImage]=true
            [sizeToHeight]=true>
      </div>
    </div>
    
    <!-- Supplemental data on the right -->
    <div class="timeline-entry-horizontal-supplements" *ngIf="timelineEntry.supplements.length > 0">
      <div *ngFor="let supplement of timelineEntry.supplements">
        <app-timeline-supplement [supplement]="supplement">
        </app-timeline-supplement>
      </div>
    </div>
  </div>

</div>