var KintTimelineSupplementAudio = /** @class */ (function () {
    function KintTimelineSupplementAudio(audioStories) {
        this.type = KintTimelineSupplementTypes.AUDIO;
        this.audioStories = audioStories;
    }
    return KintTimelineSupplementAudio;
}());
export { KintTimelineSupplementAudio };
var KintTimelineSupplementText = /** @class */ (function () {
    function KintTimelineSupplementText(text) {
        this.type = KintTimelineSupplementTypes.TEXT;
        this.text = text;
    }
    return KintTimelineSupplementText;
}());
export { KintTimelineSupplementText };
var KintTimelineSupplementLink = /** @class */ (function () {
    function KintTimelineSupplementLink(externalLink) {
        this.type = KintTimelineSupplementTypes.LINK;
        this.externalLink = externalLink;
    }
    return KintTimelineSupplementLink;
}());
export { KintTimelineSupplementLink };
var KintTimelineSupplementMap = /** @class */ (function () {
    function KintTimelineSupplementMap(location) {
        this.type = KintTimelineSupplementTypes.MAP;
        this.location = location;
    }
    return KintTimelineSupplementMap;
}());
export { KintTimelineSupplementMap };
export var KintTimelineSupplementTypes;
(function (KintTimelineSupplementTypes) {
    KintTimelineSupplementTypes["TEXT"] = "TEXT";
    KintTimelineSupplementTypes["LINK"] = "LINK";
    KintTimelineSupplementTypes["AUDIO"] = "AUDIO";
    KintTimelineSupplementTypes["MAP"] = "MAP";
})(KintTimelineSupplementTypes || (KintTimelineSupplementTypes = {}));
