import { KintDate } from '../KintObject/kint-date';
import { KintTimelineType, KintTimelineFormat, KintTimelineBlocksBuilder } from './kint-timeline-block';
/**
 * Strategy for building the timeline:
 *
 * Block types:
 *   - Decade
 *   - Year
 *   - Month
 *   - Day
 *   - Day-segment (morning/afternoon/evening)
 *   - Time
 *
 * - For any Block of photos at a given "level":
 *    - Get start and end-date of photos photos in the given block.
 *    - If duration is >= 20 years, create block of decades (so we'd have at least 2 decade blocks)
 *    - Else if duration is >= 1 year, create a block for each of the years
 *    - Else if duration is >= 1 month, create a block for each of the months
 *    - Else if duration is >= 1 day, create a block for each day
 *      - Only do this if the "initial" block type was "YEAR" or lower
 *    - If duration < 1 day, create a block for every block of time "morning", "afternoon", "evening"??
 *      - Only do this if the "initial" block type was "MONTH" or lower
 *    - Keep track of the "INITIAL" block type.
 *    - After breaking this block into sub-blocks, attempt to break that sub-block
 *      into additional blocks. Those sub-blocks are based on the 'parent' block types
 *      they do NOT use the algorithm above. They simple break down to the next-level type
 *    - If "INITIAL" block type is "DECADE", then allow for 4 levels of block
 *    - If "INITIAL" block type is < "DECADE" then allow for 3 levels of blocks
 *
 *
 * Future Enhancement: Don't create blocks with < X photos. Instead, keep them as the higher-blocks
 *
 *
 *                   BLOCK LEVEL 1
 * ----------------- BLOCK LEVEL 2 -------------------
 * BLK LVL 3
 *
 *    BLK4 [ PHOTO ]
 *
 *
 *
 * Example #1:
 *                   1990-1999
 * ------------------- 1992 -------------------
 * AUGUST
 *
 *    AUG [ PHOTO ]
 *     28 [ PHOTO ]
 *        [ PHOTO ]
 *        [ PHOTO ]
 *
 * SEPTEMPER
 *
 *    SEP [ PHOTO ]
 *      3 [ PHOTO ]
 *        [ PHOTO ]
 *        [ PHOTO ]
 *
 *
 *
 *
 * Example #2
 *                     1992
 * ------------------- MARCH -------------------
 * MAR3  [ PHOTO ]
 *       [ PHOTO ]
 *
 * MAR5  [ PHOTO ]
 *       [ PHOTO ]
 *
 * MAR25 [ PHOTO ]
 *       [ PHOTO ]
 *
 *
 *
 *
 *
 * Example #3:
 *                     March 2008
 * -------------------  March 3  -------------------
 * 9:00  [ PHOTO ]
 * 9:10  [ PHOTO ]
 * 11:30 [ PHOTO ]
 *
 * -------------------  March 4  -------------------
 * 7:30 [ PHOTO ]
 * 9:10 [ PHOTO ]
 *
 *
 *
 *
 *
 * Example #4:
 *                   March 3, 2019
 * -------------------  Morning  -------------------
 * 9:00  [ PHOTO ]
 * 9:10  [ PHOTO ]
 * 11:30 [ PHOTO ]
 *
 * -------------------  Afternoon  -------------------
 * 12:30 [ PHOTO ]
 * 2:00  [ PHOTO ]
 *
 * -------------------  Evening  -------------------
 * 6:30  [ PHOTO ]
 * 7:45  [ PHOTO ]
 *
 */
/**
 * Kintribute Timeline object. Takes care of building all of the components.
 * Currently builds from a Collection of photos.
 *
 * @export
 * @class KintTimeline
 */
var KintTimeline = /** @class */ (function () {
    // Builds a Timeline from a collection
    function KintTimeline(collection) {
        this.collection = collection;
        this.timelineBlocks = [];
        this.timelineFormat = new KintTimelineFormat(KintTimelineType.YEAR_MONTH_DAY);
        this.sortPhotos();
        this.buildTimelineBlocks();
    }
    /**
     * Builds the Timeline Blocks based on the sorted photos
     *
     * @memberof KintTimeline
     */
    KintTimeline.prototype.buildTimelineBlocks = function () {
        // Get our Timeline-type based on the sorted photos
        if (this.sortedPhotos.length === 0) {
            console.error('Cannot generate timeline without photos');
            return;
        }
        var startIsoDate = this.sortedPhotos[0].date.isoDate;
        var endIsoDate = this.sortedPhotos[this.sortedPhotos.length - 1].date.isoDate;
        var startDate = new Date(startIsoDate);
        var endDate = new Date(endIsoDate);
        var timelineType = this.getTimelineTypeForDateRange(startIsoDate, endIsoDate);
        this.timelineFormat = new KintTimelineFormat(timelineType);
        var firstBlockType = this.timelineFormat.timelineBlockTypes[0];
        this.timelineBlocks = KintTimelineBlocksBuilder.buildTimelineBlocks(firstBlockType, startDate, endDate, this.sortedPhotos, this.timelineFormat, 0);
        console.log('Total Top-Level Blocks: ' + this.timelineBlocks.length);
    };
    /**
     * Checks to see if we have any dated photos
     */
    KintTimeline.prototype.hasDatedPhotos = function () {
        return this.sortedPhotos.length !== 0;
    };
    /**
     * Sort the photos into dates and groupings
     */
    KintTimeline.prototype.sortPhotos = function () {
        var _this = this;
        this.undatedPhotos = [];
        // First, sort all photos by date (absolute dates)
        var allPhotos = this.collection.photos.slice();
        // Make sure all date location is ok
        allPhotos.forEach(function (photo) {
            photo.date = KintDate.fromDate(photo.date);
        });
        // Sort away!
        allPhotos.sort(this.photoDateSortFunc);
        // Build the "Undated" section of photos
        var sortedDatedPhotos = [];
        allPhotos.forEach(function (photo) {
            // Move undated photos to their own section
            if (KintDate.isUndated(photo.date)) {
                _this.undatedPhotos.push(photo);
                return;
            }
            else {
                sortedDatedPhotos.push(photo);
            }
        });
        console.log('Total Dated Photos: ' + sortedDatedPhotos.length);
        this.sortedPhotos = sortedDatedPhotos;
    };
    /**
     * Sort function for sorting a list of Photos by date
     *
     * @param {*} photoA
     * @param {*} photoB
     * @returns {number}
     * @memberof KintTimeline
     */
    KintTimeline.prototype.photoDateSortFunc = function (photoA, photoB) {
        // Missing "date" field (shouldn't happen)
        if (!photoA.date && !photoB.date) {
            return 0;
        }
        // No date for A. We treat this as "lower"
        if (!photoA.date) {
            return -1;
        }
        // No date for B. We treat A as "higher"
        if (!photoB.date) {
            return 1;
        }
        // Mising isoDate
        if (!photoA.date.isoDate && !photoB.date.isoDate) {
            return 0;
        }
        // No isoDate for A. We treat this as "lower"
        if (!photoA.date.isoDate) {
            return -1;
        }
        // No isoDate for B. We treat this as "higher"
        if (!photoB.date.isoDate) {
            return 1;
        }
        // Directly compare the isoDate (since they are all zulu-time)
        if (photoA.date.isoDate < photoB.date.isoDate) {
            return -1;
        }
        else if (photoA.date.isoDate > photoB.date.isoDate) {
            return 1;
        }
        else {
            return 0;
        }
    };
    /**
     * Get the Timeline-Type which we are trying to generate
     *
     * @param {*} startIsoDate
     * @param {*} endIsoDate
     * @returns {KintTimelineType}
     * @memberof KintTimeline
     */
    KintTimeline.prototype.getTimelineTypeForDateRange = function (startIsoDate, endIsoDate) {
        // Try to break these photos down into smaller groupings
        var startDate = new Date(startIsoDate);
        var endDate = new Date(endIsoDate);
        var yearDiff = endDate.getFullYear() - startDate.getFullYear();
        var monthDiff = endDate.getMonth() - startDate.getMonth();
        if (monthDiff < 0) {
            // Feb - Dec = -10. Add 12 to get a span of 2.
            monthDiff = monthDiff + 12;
        }
        var durationMs = endDate.getTime() - startDate.getTime();
        // If spans 20+ years, build "decades" blocks
        if (yearDiff > 20) {
            return KintTimelineType.DECADE_YEAR_DAY;
        }
        else if (yearDiff > 1) {
            return KintTimelineType.YEAR_MONTH_DAY;
        }
        else if (monthDiff > 0) {
            return KintTimelineType.MONTH_DAY_TIME;
        }
        else if ((durationMs <= KintTimelineBlocksBuilder.ONE_WEEK) && (durationMs >= KintTimelineBlocksBuilder.ONE_DAY)) {
            return KintTimelineType.WEEK_DAY_TIME;
        }
        else if (durationMs <= KintTimelineBlocksBuilder.ONE_DAY) {
            return KintTimelineType.DAY_TIMEOFDAY_TIME;
        }
        else {
            console.error('Unknown duration for timeline. Defaulting to DAY_TIMEOFDAY_TIME');
            return KintTimelineType.DAY_TIMEOFDAY_TIME;
        }
    };
    return KintTimeline;
}());
export { KintTimeline };
