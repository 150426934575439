<div class="personal-page">
  
  <!-- Header First -->
  <app-personal-page-header [header]="page.header"
                            [isMobile]="isMobile()"
                            [editor]="editor">
  </app-personal-page-header>

  <!-- Page Content -->
  <app-personal-page-content [page]="page"
                             [isMobile]="isMobile()"
                             [editor]="editor">
  </app-personal-page-content>

</div>
