/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./timeline-supplement-audio.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../ng1/General/kint-audio-player-component";
import * as i3 from "@angular/common";
import * as i4 from "./timeline-supplement-audio.component";
var styles_TimelineSupplementAudioComponent = [i0.styles];
var RenderType_TimelineSupplementAudioComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TimelineSupplementAudioComponent, data: {} });
export { RenderType_TimelineSupplementAudioComponent as RenderType_TimelineSupplementAudioComponent };
function View_TimelineSupplementAudioComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "timeline-supplement-audio-transcript"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "span", [["class", "glyphicon glyphicon-volume-up"], ["style", "margin-right: 5px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.transcript; _ck(_v, 2, 0, currVal_0); }); }
function View_TimelineSupplementAudioComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "timeline-supplement-audio-story"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "ng1-kint-audio-player", [], null, null, null, null, null)), i1.ɵdid(2, 999424, null, 0, i2.KintAudioPlayerDirective, [i1.ElementRef, i1.Injector], { audio: [0, "audio"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimelineSupplementAudioComponent_2)), i1.ɵdid(4, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); var currVal_1 = (_v.context.$implicit.transcript && (_v.context.$implicit.transcript.length > 0)); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_TimelineSupplementAudioComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimelineSupplementAudioComponent_1)), i1.ɵdid(2, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.supplement.audioStories; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_TimelineSupplementAudioComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-timeline-supplement-audio", [], null, null, null, View_TimelineSupplementAudioComponent_0, RenderType_TimelineSupplementAudioComponent)), i1.ɵdid(1, 114688, null, 0, i4.TimelineSupplementAudioComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TimelineSupplementAudioComponentNgFactory = i1.ɵccf("app-timeline-supplement-audio", i4.TimelineSupplementAudioComponent, View_TimelineSupplementAudioComponent_Host_0, { supplement: "supplement" }, {}, []);
export { TimelineSupplementAudioComponentNgFactory as TimelineSupplementAudioComponentNgFactory };
