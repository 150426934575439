import { OnInit } from '@angular/core';
import { KintTimeline } from '../../kint-timeline';
import { downgradeComponent } from '@angular/upgrade/static';
var TimelineLayoutVerticalComponent = /** @class */ (function () {
    function TimelineLayoutVerticalComponent(ng1AppDataModelService) {
        this.ng1AppDataModelService = ng1AppDataModelService;
    }
    TimelineLayoutVerticalComponent.prototype.ngOnInit = function () {
    };
    TimelineLayoutVerticalComponent.prototype.isMobile = function () {
        return this.ng1AppDataModelService.isMobile();
    };
    return TimelineLayoutVerticalComponent;
}());
export { TimelineLayoutVerticalComponent };
// Provide a downgraded version of this to run with AngularJS
angular.module('KintributeApp')
    .directive('timelineLayoutVertical', downgradeComponent({
    component: TimelineLayoutVerticalComponent,
    inputs: ['timeline']
}));
