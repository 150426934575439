import { Component, OnInit} from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { MessageBox } from 'src/app/MessageBox/message-box.service';
import { UtilService } from 'src/app/Util/util/util.service';

// Angular is loaded separately
declare var angular: any;

@Component({
  selector: 'app-party',
  templateUrl: './party.component.html',
  styleUrls: ['./party.component.css']
})
export class PartyComponent implements OnInit {

  sliderValue: any;
  partyName: string;

  constructor(private mesageBox: MessageBox, private utilService: UtilService) {
  }

  ngOnInit() {
    this.partyName = 'Tom\'s Party';
    this.sliderValue = true;
  }

  dataChangeCallback() {
    this.sliderValue = !this.sliderValue;
    console.log('Data Changed! Now is: ' + this.sliderValue);

    this.utilService.deepCopy({bob: true});

    this.mesageBox.infoMessage('Tom\'s Message!', 'Tom\'s title');

  }

}


// Provide a downgraded version of this to run with AngularJS
angular.module('KintributeApp')
  .directive('ng2Party', downgradeComponent({component: PartyComponent}));
