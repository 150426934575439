<div>
  <div *ngFor="let audio of supplement.audioStories" class="timeline-supplement-audio-story">

    <!-- Audio -->
    <ng1-kint-audio-player [audio]="audio">
    </ng1-kint-audio-player>


    <!-- Transcript -->
    <div *ngIf="audio.transcript && audio.transcript.length > 0" class="timeline-supplement-audio-transcript">
      <span class="glyphicon glyphicon-volume-up" style="margin-right: 5px;"></span> {{audio.transcript}}
    </div>

  </div>

</div>