import { OnInit } from '@angular/core';
var TimelineSupplementComponent = /** @class */ (function () {
    function TimelineSupplementComponent() {
    }
    TimelineSupplementComponent.prototype.ngOnInit = function () {
        console.log('Supplement of type: ' + this.supplement.type);
    };
    return TimelineSupplementComponent;
}());
export { TimelineSupplementComponent };
