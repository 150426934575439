import { OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { PersonalPageEditor } from '../personal-page-editor';
var PersonalPageComponent = /** @class */ (function () {
    function PersonalPageComponent(appDataModelService) {
        this.appDataModelService = appDataModelService;
    }
    PersonalPageComponent.prototype.ngOnInit = function () {
    };
    PersonalPageComponent.prototype.isMobile = function () {
        return this.appDataModelService.isMobile();
    };
    PersonalPageComponent.prototype.onResize = function (event) {
        this.isMobile = this.appDataModelService.isMobile();
    };
    return PersonalPageComponent;
}());
export { PersonalPageComponent };
// Provide a downgraded version of this to run with AngularJS
angular.module('KintributeApp')
    .directive('personalPage', downgradeComponent({
    component: PersonalPageComponent,
    inputs: ['page']
}));
