import { Injectable } from '@angular/core';
import { KintPhoto } from '../KintObject/kint-photo';
import { KintSize } from '../KintObject/kint-size';
import { LogService } from '../Log/log.service';

@Injectable({
  providedIn: 'root'
})
export class PhotoService {

  constructor(private logService: LogService) { }

  // Default size to show for empty/messed up photos
  DEFAULT_PHOTO_SIZE = 500;


  /**
   * Checks to see if the given photo is Landscape or Portrait
   * @param photo
   */
  isPortrait(photo: KintPhoto): boolean {
    return true;
  }


  /**
   * Checks to see if the given photo is Landscape or Portrait
   * @param photo
   */
  isLandscape(photo: KintPhoto): boolean {
    if (photo && photo.size) {
      if (photo.size.height > photo.size.width) {
        return true;
      }
    } else {
      console.error('NO SIZE FOR PHOTO!!!!');
    }
    return false;
  }


  /**
   * Calculate how tall a given photo should be given a specific width
   *
   * @param photo
   * @param desiredWidth
   */
  calculateHeight(photo: KintPhoto, desiredWidth: number): number {
    if (photo && photo.size) {
      return (desiredWidth * photo.size.height) / photo.size.width;
    } else {
      // THIS IS BAD!!! MISSING SIZE!
      return desiredWidth;
      // return this.DEFAULT_PHOTO_SIZE;
    }
  }


  /**
   * Calculate the width that a photo should be, given it's current height
   * @param photo
   * @param desiredHeight
   */
  calculateWidth(photo: KintPhoto, desiredHeight: number): number {
    if (photo && photo.size) {
      return (desiredHeight * photo.size.width) / photo.size.height;
    } else {
      // THIS IS BAD!!! MISSING SIZE!
      return desiredHeight;
      // return this.DEFAULT_PHOTO_SIZE;
    }
  }


  /**
	 * Get a suggested size for displaying the image. This function takes the available
	 * space into consideration and then generates a size that fits appropriately in that space
	 *
	 * @param		maxHeight			maximum height the image could be
	 * @param		maxWidth			maximum width that the image could be
	 * @param		photoObject		Photo object (must include 'size' data)
	 *
	 * @return	{height: YYYY, width: XXXX}
	 */
  getSuggestedSize(maxHeight: number, maxWidth: number, photoObject: KintPhoto): KintSize {
    // The entire photo MUST fit within the viewable maxHeight & maxWidth.
    // To do this, we need to look at the aspect-ratio of the photo to decide what to do
    //
    // When calculating the frame for the photo, we need to account for:
    // Total height of the box should be no more than (screenHeight - 245px)
    // Total width cannot exceed width of the parent
    // Total width cannot be less than 300px

    const suggestedSize: KintSize = {
      height: 0,
      width: 0
    };

    if (!photoObject || !photoObject.size) {
      this.logService.error('Error resizing photo-frame. Size is missing');
      return suggestedSize;
    }


    // Start at hightest possible size and work our way down...
    let height = maxHeight;
    let width = maxWidth;

    // Now...check each of those height/width to make sure the calculated values for the
    // other dimension does not exceed the maximum
    const calculatedHeight = photoObject.size.height * width / photoObject.size.width;
    if (calculatedHeight > height) {
      // Too big!  Scale the width down to whatever it needs to be to fit!
      width = this.calculateWidth(photoObject, height);
    }

    const calculatedWidth = photoObject.size.width * height / photoObject.size.height;
    if (calculatedWidth > width) {
      // Too big!  Scale the width down to whatever it needs to be to fit!
      height = this.calculateHeight(photoObject, width);
    }


    suggestedSize.height = height;
    suggestedSize.width = width;
    return suggestedSize;
  }



  /**
   * Checks to see if the photo has any tags (Auto + manual)
   *
   * @param {KintPhoto} photo
   * @returns {boolean}
   * @memberof PhotoService
   */
  hasTags(photo: KintPhoto): boolean {
    return this.hasKeywords(photo) || this.hasVisibleAutoTags(photo);
  }


  /**
   * Checks to see if the photo has any keywords (manual tags)
   *
   * @param {KintPhoto} photo
   * @returns {boolean}
   * @memberof PhotoService
   */
  hasKeywords(photo: KintPhoto): boolean {
    return photo && photo.keywords && photo.keywords.length > 0;
  }


  /**
   * Checks to see if the photo has any automatic tags
   *
   * @param {KintPhoto} photo
   * @returns {boolean}
   * @memberof PhotoService
   */
  hasVisibleAutoTags(photo: KintPhoto): boolean {
    if (photo && photo.autoTags) {
      for (let i = 0; i < photo.autoTags.length; i++) {
        const autoTag = photo.autoTags[i];
        if (autoTag.display) {
          return true;
        }
      }
    }
    return false;
  }

}
