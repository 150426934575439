import { PhotoEssay, PhotoEssayRow, PhotoEssayRowTypes, PhotoEssayTileTypes, PhotoEssayTile } from '../photo-essay';
import { UtilService } from 'src/app/Util/util/util.service';
import { downgradeInjectable } from '@angular/upgrade/static';
import { LogService } from 'src/app/Log/log.service';
import * as i0 from "@angular/core";
import * as i1 from "../../Util/util/util.service";
import * as i2 from "../../Log/log.service";
var PhotoEssayLayoutService = /** @class */ (function () {
    /**
     * Creates an instance of PhotoEssayLayoutService.
     *
     * @param {UtilService} utilService
     * @param {LogService} logService
     * @memberof PhotoEssayLayoutService
     */
    function PhotoEssayLayoutService(utilService, logService) {
        this.utilService = utilService;
        this.logService = logService;
    }
    /**
     * Builds a default Photo Essay object from a bunch of photos
     * Note: Each photo can have an optional ".groupNext" boolean which tells the system
     * that the photo should ideally be grouped with the next item in the list
     *
     * @param originalPhotos    List of all photos that we want to include
     * @param enableAutoGrouping Whether or not we want to allow the system to group photos for us
     */
    PhotoEssayLayoutService.prototype.buildPhotoEssay = function (originalPhotos, enableAutoGrouping) {
        var photoEssay = new PhotoEssay();
        var rows = [];
        var photos = this.utilService.deepCopy(originalPhotos);
        // Always Lead with a caption
        rows.push(this.buildCaptionRow(''));
        var lastRowType = PhotoEssayRowTypes.CAPTION;
        while (photos.length > 0) {
            // Build the new row and a caption to go after it
            var photoEssayRow = this.getRandomPhotoEssayRow(lastRowType, photos, enableAutoGrouping);
            rows.push(photoEssayRow);
            // Always have an empty caption after the row!
            rows.push(this.buildCaptionRow(''));
            // Remove the used photos from the list of photos
            var photoCount = this.getPhotoCount(photoEssayRow);
            lastRowType = photoEssayRow.type;
            photos.splice(0, photoCount);
        }
        photoEssay.name = '';
        photoEssay.cover = originalPhotos.length > 0 ? originalPhotos[0] : null;
        photoEssay.layout.rows = rows;
        return photoEssay;
    };
    /**
     * Generates a random row-type, consuming the next X photos from the photo list
     * This handles all of the special auto-grouping vs manual-grouping logic!
     *
     * @param lastRowType         Row type that we used on the previous row
     * @param photoList           Remaining photos to pick from
     * @param enableAutoGrouping  Whether or not to auto-group
     */
    PhotoEssayLayoutService.prototype.getRandomPhotoEssayRow = function (lastRowType, photoList, enableAutoGrouping) {
        this.logService.info('Generating new row. Trying not to duplicate row type: ' + lastRowType);
        if (photoList.length === 0) {
            return this.buildCaptionRow('');
        }
        // See if we have 3 grouped photos
        if ((photoList.length >= 3) && this.isGrouped(photoList[0]) && this.isGrouped(photoList[1])) {
            // User-Mandated: 3-photo grouping (randomize between columns & collage)
            var nextRowType = PhotoEssayRowTypes.THREE_COLUMN_PHOTOS;
            if (Math.random() >= 0.5) {
                nextRowType = PhotoEssayRowTypes.THREE_COLUMN_PHOTOS;
            }
            else {
                nextRowType = PhotoEssayRowTypes.COLLAGE_THREE_PHOTOS;
            }
            if (lastRowType === nextRowType) {
                if (nextRowType === PhotoEssayRowTypes.THREE_COLUMN_PHOTOS) {
                    nextRowType = PhotoEssayRowTypes.COLLAGE_THREE_PHOTOS;
                }
                else {
                    nextRowType = PhotoEssayRowTypes.THREE_COLUMN_PHOTOS;
                }
            }
            var threePhotos = [photoList[0], photoList[1], photoList[2]];
            return this.generateRowOfType(nextRowType, threePhotos);
        }
        else if ((photoList.length >= 2) && this.isGrouped(photoList[0])) {
            // User-Mandated: 2-photo grouping
            var twoPhotos = [photoList[0], photoList[1]];
            return this.generateRowOfType(PhotoEssayRowTypes.SIDE_BY_SIDE_PHOTOS, twoPhotos);
        }
        else if (!enableAutoGrouping) {
            // User-Mandated: Single Photo
            this.logService.info('AutoGrouping is disabled. Continuing with single photos');
            var singlePhoto = [photoList[0]];
            return this.generateRowOfType(PhotoEssayRowTypes.SINGLE_PHOTO, singlePhoto);
        }
        else {
            // Auto-grouping is enabled. We are free to create whatever row we want!
            // Just need to make sure all random selections are valid and don't violoate
            // any grouping rules (or get too repetative)
            var validSelection = false;
            var val = Math.random() * 100;
            this.logService.info('Generating new row. Random value: ' + val);
            var newRowType = PhotoEssayRowTypes.SINGLE_PHOTO;
            if (val < 25) {
                // Single photo row is always OK
                newRowType = PhotoEssayRowTypes.SINGLE_PHOTO;
                validSelection = true;
            }
            else if (val < 50) {
                // Two-photo row. Only allow the two-photo grouping if the photos aren't
                // manually being grouped though
                newRowType = PhotoEssayRowTypes.SIDE_BY_SIDE_PHOTOS;
                if ((photoList.length >= 2) &&
                    (lastRowType !== PhotoEssayRowTypes.SIDE_BY_SIDE_PHOTOS) &&
                    !this.isGrouped(photoList[0]) &&
                    !this.isGrouped(photoList[1])) {
                    validSelection = true;
                }
            }
            else if (val < 75) {
                // Three-photo row. Only allow the two-photo grouping if the photos aren't
                // manually being grouped though
                newRowType = PhotoEssayRowTypes.THREE_COLUMN_PHOTOS;
                if ((photoList.length >= 3) &&
                    (lastRowType !== PhotoEssayRowTypes.THREE_COLUMN_PHOTOS) &&
                    !this.isGrouped(photoList[0]) &&
                    !this.isGrouped(photoList[1]) &&
                    !this.isGrouped(photoList[2])) {
                    validSelection = true;
                }
            }
            else {
                newRowType = PhotoEssayRowTypes.COLLAGE_THREE_PHOTOS;
                if ((photoList.length >= 3) &&
                    (lastRowType !== PhotoEssayRowTypes.COLLAGE_THREE_PHOTOS) &&
                    !this.isGrouped(photoList[0]) &&
                    !this.isGrouped(photoList[1]) &&
                    !this.isGrouped(photoList[2])) {
                    validSelection = true;
                }
            }
            this.logService.info('Generating new row. Selected : ' + newRowType);
            this.logService.info('Generating new row. validSelection : ' + validSelection);
            // If this selection is valid, go ahead and generate the row
            if (validSelection) {
                return this.generateRowOfType(newRowType, photoList);
            }
            // If we didn't get a valid selection (either because we would be repeating a row type
            // or because we don't have enough photos), then try again (will generate a new random row)
            // Eventually, this will resolve itself, in the worst-case as extra single-photo rows
            this.logService.info('Generating new row: LAST WAS INVALID. Trying again...');
            return this.getRandomPhotoEssayRow(lastRowType, photoList, enableAutoGrouping);
        }
    };
    /**
     * Checks to see if the given photo object should be "grouped" with the next photo in the list
     *
     * @param photo Photo object with an optional 'groupNext" field indicating if we want to
     *              group this photo with the next one or not
     */
    PhotoEssayLayoutService.prototype.isGrouped = function (photo) {
        return photo && photo.groupNext;
    };
    /**
     * Generates an essay-row of the tiven type
     *
     * @param rowType   PhotoEssayRowType to generate
     * @param photoList List of remaining photos to pick from. MUST contain sufficient photos for the row!
     */
    PhotoEssayLayoutService.prototype.generateRowOfType = function (rowType, photoList) {
        switch (rowType) {
            case PhotoEssayRowTypes.SINGLE_PHOTO: {
                var singlePhoto = [photoList[0]];
                return this.buildPhotoRow(rowType, singlePhoto);
            }
            case PhotoEssayRowTypes.SIDE_BY_SIDE_PHOTOS: {
                var twoPhotos = [photoList[0], photoList[1]];
                return this.buildPhotoRow(rowType, twoPhotos);
            }
            case PhotoEssayRowTypes.THREE_COLUMN_PHOTOS: {
                var threePhotos = [photoList[0], photoList[1], photoList[2]];
                return this.buildPhotoRow(rowType, threePhotos);
            }
            case PhotoEssayRowTypes.COLLAGE_THREE_PHOTOS: {
                var threePhotos = [photoList[0], photoList[1], photoList[2]];
                return this.buildPhotoRow(rowType, threePhotos);
            }
            default: {
                console.error('Unhandled Photo Essay Row type: ' + rowType);
                return this.buildCaptionRow('');
            }
        }
    };
    /**
     * Builds a Row with the given photos
     *
     * @param rowType Row type
     * @param photos Photos we want to use
     */
    PhotoEssayLayoutService.prototype.buildPhotoRow = function (rowType, photos) {
        var tiles = [];
        var caption = '';
        for (var i = 0; i < photos.length; i++) {
            var photo = photos[i];
            var tile = new PhotoEssayTile(PhotoEssayTileTypes.PHOTO, photo, caption);
            tiles.push(tile);
        }
        return new PhotoEssayRow(rowType, tiles);
    };
    /**
     * Counts the number of photos in the given PhotoEssayRow
     */
    PhotoEssayLayoutService.prototype.getPhotoCount = function (row) {
        // Count the photo-tiles count
        var count = 0;
        for (var _i = 0, _a = row.tiles; _i < _a.length; _i++) {
            var tile = _a[_i];
            if (tile.type === PhotoEssayTileTypes.PHOTO) {
                count++;
            }
        }
        return count;
    };
    /**
     * Builds a new Row with the given caption
     *
     * @param caption
     */
    PhotoEssayLayoutService.prototype.buildCaptionRow = function (caption) {
        var tile = new PhotoEssayTile(PhotoEssayTileTypes.CAPTION, null, caption);
        var singleTile = [tile];
        return new PhotoEssayRow(PhotoEssayRowTypes.CAPTION, singleTile);
    };
    /**
     * Gets all of the photos from the given row
     */
    PhotoEssayLayoutService.prototype.getPhotosFromRow = function (row) {
        var photos = [];
        for (var _i = 0, _a = row.tiles; _i < _a.length; _i++) {
            var tile = _a[_i];
            if (tile.photo) {
                photos.push(tile.photo);
            }
        }
        return photos;
    };
    /**
     * Get all of the photos from the given essay
     *
     * @param {PhotoEssay} essay
     * @returns {KintPhoto[]}
     * @memberof PhotoEssayLayoutService
     */
    PhotoEssayLayoutService.prototype.getPhotosFromEssay = function (essay) {
        var photos = [];
        if (essay.layout && essay.layout.rows) {
            for (var _i = 0, _a = essay.layout.rows; _i < _a.length; _i++) {
                var row = _a[_i];
                var photosFromRow = this.getPhotosFromRow(row);
                photos = photos.concat(photosFromRow);
            }
        }
        return photos;
    };
    /**
     * Builds a Photo Essay tile of the given type
     *
     * @param {KintPhoto} photo
     * @returns {PhotoEssayTile}
     * @memberof PhotoEssayLayoutService
     */
    PhotoEssayLayoutService.prototype.buildPhotoTile = function (photo) {
        return new PhotoEssayTile(PhotoEssayTileTypes.PHOTO, photo, null);
    };
    /**
     * Builds and empty row of the given type
     *
     * @param {PhotoEssayRowTypes} rowType
     * @returns {PhotoEssayRow}
     * @memberof PhotoEssayLayoutService
     */
    PhotoEssayLayoutService.prototype.buildEmptyRowOfType = function (rowType) {
        var tiles = [];
        switch (rowType) {
            case PhotoEssayRowTypes.CAPTION: {
                tiles.push(new PhotoEssayTile(PhotoEssayTileTypes.CAPTION, null, ''));
                break;
            }
            case PhotoEssayRowTypes.SINGLE_PHOTO: {
                tiles.push(new PhotoEssayTile(PhotoEssayTileTypes.PHOTO, null, null));
                break;
            }
            case PhotoEssayRowTypes.SIDE_BY_SIDE_PHOTOS: {
                tiles.push(new PhotoEssayTile(PhotoEssayTileTypes.PHOTO, null, null));
                tiles.push(new PhotoEssayTile(PhotoEssayTileTypes.PHOTO, null, null));
                break;
            }
            case PhotoEssayRowTypes.THREE_COLUMN_PHOTOS:
            case PhotoEssayRowTypes.COLLAGE_THREE_PHOTOS: {
                tiles.push(new PhotoEssayTile(PhotoEssayTileTypes.PHOTO, null, null));
                tiles.push(new PhotoEssayTile(PhotoEssayTileTypes.PHOTO, null, null));
                tiles.push(new PhotoEssayTile(PhotoEssayTileTypes.PHOTO, null, null));
                break;
            }
            default: {
                this.logService.error('Unknown photo essay row type: ' + rowType);
            }
        }
        return new PhotoEssayRow(rowType, tiles);
    };
    /**
     * Builds a new row based on the given photos
     *
     * @param {KintPhoto[]} photos
     * @returns {PhotoEssayRow}
     * @memberof PhotoEssayLayoutService
     */
    PhotoEssayLayoutService.prototype.rebuildRowForPhotos = function (photos) {
        if (photos.length === 0) {
            this.logService.info('Row no longer needed. Photos have been removed.');
            return null;
        }
        var photoEssayRows = [];
        // Build tiles for all of the photos
        var photoTiles = [];
        for (var _i = 0, photos_1 = photos; _i < photos_1.length; _i++) {
            var photo = photos_1[_i];
            photoTiles.push(new PhotoEssayTile(PhotoEssayTileTypes.PHOTO, photo, null));
        }
        // Build rows of the correct types
        if (photos.length === 0) {
            return new PhotoEssayRow(PhotoEssayRowTypes.SINGLE_PHOTO, photoTiles);
        }
        else if (photos.length === 1) {
            return new PhotoEssayRow(PhotoEssayRowTypes.SINGLE_PHOTO, photoTiles);
        }
        else if (photos.length === 2) {
            return new PhotoEssayRow(PhotoEssayRowTypes.SIDE_BY_SIDE_PHOTOS, photoTiles);
        }
        else {
            if (photos.length > 3) {
                this.logService.error('Cannot build rows for more than 3 photos. Truncating.');
            }
            return new PhotoEssayRow(PhotoEssayRowTypes.THREE_COLUMN_PHOTOS, photoTiles);
        }
    };
    /**
     * Checks to see if the given row is a Caption row or not
     *
     * @param {PhotoEssayRow} row
     * @returns {boolean}
     * @memberof PhotoEssayLayoutService
     */
    PhotoEssayLayoutService.prototype.isCaptionRow = function (row) {
        if (row.type === PhotoEssayRowTypes.CAPTION) {
            return true;
        }
        return false;
    };
    /**
     * Checks to see if the given row is an empty caption row (caption with no text)
     *
     * @static
     * @param {PhotoEssayRow} row
     * @returns {boolean}
     * @memberof PhotoEssayLayoutService
     */
    PhotoEssayLayoutService.prototype.isEmptyCaptionRow = function (row) {
        if (row.type === PhotoEssayRowTypes.CAPTION &&
            row.tiles &&
            row.tiles.length > 0 &&
            row.tiles[0].caption.trim() === '') {
            return true;
        }
        return false;
    };
    PhotoEssayLayoutService.ngInjectableDef = i0.defineInjectable({ factory: function PhotoEssayLayoutService_Factory() { return new PhotoEssayLayoutService(i0.inject(i1.UtilService), i0.inject(i2.LogService)); }, token: PhotoEssayLayoutService, providedIn: "root" });
    return PhotoEssayLayoutService;
}());
export { PhotoEssayLayoutService };
// Register an AngularJS service, whose value is the "downgraded" Angular injectable.
angular.module('KintributeApp').factory('PhotoEssayLayoutService', downgradeInjectable(PhotoEssayLayoutService));
