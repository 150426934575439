import { Directive, ElementRef, Injector, Input, Output } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
@Directive({
    // tslint:disable-next-line:directive-selector
    selector: 'ng1-kint-slider-toggle'
})
export class KintSliderToggleDirective extends UpgradeComponent {

    @Input('dataToggle') dataToggle: boolean;
    @Output('callback') callback: Function;

    constructor(elementRef: ElementRef, injector: Injector) {
        console.log('kint-slider-toggle constructor!');
        super('kintSliderToggle', elementRef, injector);
    }
}
