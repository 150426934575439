import { Component, OnInit, Input } from '@angular/core';
import { KintTimelineSupplementMap } from '../../timeline-supplement';

@Component({
  selector: 'app-timeline-supplement-map',
  templateUrl: './timeline-supplement-map.component.html',
  styleUrls: ['./timeline-supplement-map.component.css']
})
export class TimelineSupplementMapComponent implements OnInit {

  @Input('supplement') supplement: KintTimelineSupplementMap;

  gpsCenter: number[];

  constructor() { }

  ngOnInit() {
    this.gpsCenter = [
      this.supplement.location.position.lat,
      this.supplement.location.position.long
    ];

    /**
     *  CodePen
     *

     // Define your product name and version
    tomtom.setProductInfo('<your-product-name>', '<your-product-version>');
    tomtom.L.map('map', {
        key: '<your-tomtom-API-key>'
    });

     */
  }

}
