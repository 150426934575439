import * as i0 from "@angular/core";
var PhotoSampleServiceService = /** @class */ (function () {
    function PhotoSampleServiceService() {
        this.samplePhotoVertical1 = {
            id: 'XXXXXXXXX',
            alternateSizes: [
                {
                    height: 662,
                    width: 512,
                    link: '/img/samples/mlk_portrait1_512.jpg'
                },
                {
                    height: 1323,
                    width: 1024,
                    link: '/img/samples/mlk_portrait1_1024.jpg'
                },
                {
                    height: 2674,
                    width: 2048,
                    link: '/img/samples/mlk_portrait1_2048.jpg'
                },
                {
                    height: 3696,
                    width: 2860,
                    link: '/img/samples/mlk_portrait1_full.jpg'
                }
            ],
            size: {
                height: 3696,
                width: 2860
            },
            link: '/img/samples/mlk_portrait1_full.jpg',
            date: {
                day: 3,
                month: 5,
                year: 1964,
                isoDate: '1964-05-03T16:24:22.000Z'
            },
            story: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod                 tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,                 quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.                 Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu                 fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa                 qui officia deserunt mollit anim id est laborum.",
            location: {
                name: 'Lincoln Memorial',
                address: {
                    houseNumber: 2,
                    street: 'Lincoln Memorial Cir NW',
                    city: 'Washington DC',
                    state: '',
                    country: 'United States of America'
                },
                position: {
                    long: -77.0502,
                    lat: 38.8893
                }
            },
            externalLinks: [
                {
                    text: 'Wikimedia Entry',
                    url: 'https://commons.wikimedia.org/wiki/File:Martin_Luther_King_Jr_with_medallion_NYWTS.jpg'
                }
            ]
        };
        this.samplePhotoVertical2 = {
            id: 'XXXXXXXXX',
            alternateSizes: [
                {
                    height: 764,
                    width: 512,
                    link: '/img/samples/mlk_portrait2_512.jpg'
                },
                {
                    height: 1528,
                    width: 1024,
                    link: '/img/samples/mlk_portrait2_1024.jpg'
                },
                {
                    height: 3000,
                    width: 2010,
                    link: '/img/samples/mlk_portrait2_full.jpg'
                }
            ],
            size: {
                height: 3000,
                width: 2010
            },
            link: '/img/samples/mlk_portrait2_full.jpg',
            date: {
                day: 9,
                month: 2,
                year: 1963,
                isoDate: '1963-09-02T19:12:22.000Z'
            },
            story: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod                 tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,                 quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.                 Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu                 fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa                 qui officia deserunt mollit anim id est laborum.",
            location: {
                name: 'Lincoln Memorial',
                address: {
                    houseNumber: 2,
                    street: 'Lincoln Memorial Cir NW',
                    city: 'Washington DC',
                    state: '',
                    country: 'United States of America'
                },
                position: {
                    long: -77.0502,
                    lat: 38.8893
                }
            },
            externalLinks: [
                {
                    text: 'Wikimedia Entry',
                    url: 'https://commons.wikimedia.org/wiki/File:Martin_Luther_King_Jr_with_medallion_NYWTS.jpg'
                }
            ]
        };
        this.samplePhotoHorizontal1 = {
            id: 'XXXXXXXXX',
            alternateSizes: [
                {
                    height: 352,
                    width: 512,
                    link: '/img/samples/mlk_landscape1_512.jpg'
                },
                {
                    height: 704,
                    width: 1024,
                    link: '/img/samples/mlk_landscape1_1024.jpg'
                },
                {
                    height: 1407,
                    width: 2048,
                    link: '/img/samples/mlk_landscape1_2048.jpg'
                },
                {
                    height: 2479,
                    width: 3608,
                    link: '/img/samples/mlk_landscape1_full.jpg'
                }
            ],
            size: {
                height: 2479,
                width: 3608
            },
            link: '/img/samples/mlk_landscape1_full.jpg',
            date: {
                day: 28,
                month: 11,
                year: 1962,
                isoDate: '1962-11-28T15:54:22.000Z'
            },
            story: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod                 tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,                 quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.                 Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu                 fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa                 qui officia deserunt mollit anim id est laborum.",
            location: {
                name: 'Lincoln Memorial',
                address: {
                    houseNumber: 2,
                    street: 'Lincoln Memorial Cir NW',
                    city: 'Washington DC',
                    state: '',
                    country: 'United States of America'
                },
                position: {
                    long: -77.0502,
                    lat: 38.8893
                }
            },
            externalLinks: [
                {
                    text: 'Wikimedia Entry',
                    url: 'https://commons.wikimedia.org/wiki/File:Martin_Luther_King_Jr_with_medallion_NYWTS.jpg'
                }
            ]
        };
        this.samplePhotoHorizontal2 = {
            id: 'XXXXXXXXX',
            alternateSizes: [
                {
                    height: 343,
                    width: 512,
                    link: '/img/samples/mlk_landscape2_512.jpg'
                },
                {
                    height: 686,
                    width: 1024,
                    link: '/img/samples/mlk_landscape2_1024.jpg'
                },
                {
                    height: 1371,
                    width: 2047,
                    link: '/img/samples/mlk_landscape2_2048.jpg'
                },
                {
                    height: 4687,
                    width: 7000,
                    link: '/img/samples/mlk_landscape2_full.jpg'
                }
            ],
            size: {
                height: 4687,
                width: 7000,
            },
            link: '/img/samples/mlk_landscape2_full.jpg',
            date: {
                day: 19,
                month: 8,
                year: 1962,
                isoDate: '1962-08-19T08:36:22.000Z'
            },
            story: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod                 tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,                 quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.                 Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu                 fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa                 qui officia deserunt mollit anim id est laborum.",
            location: {
                name: 'Lincoln Memorial',
                address: {
                    houseNumber: 2,
                    street: 'Lincoln Memorial Cir NW',
                    city: 'Washington DC',
                    state: '',
                    country: 'United States of America'
                },
                position: {
                    long: -77.0502,
                    lat: 38.8893
                }
            },
            externalLinks: [
                {
                    text: 'Wikimedia Entry',
                    url: 'https://commons.wikimedia.org/wiki/File:Martin_Luther_King_Jr_with_medallion_NYWTS.jpg'
                }
            ]
        };
    }
    PhotoSampleServiceService.prototype.castToPhoto = function (input) {
        return input;
    };
    PhotoSampleServiceService.prototype.getVerticalPhoto1 = function () {
        return this.castToPhoto(this.samplePhotoVertical1);
    };
    PhotoSampleServiceService.prototype.getVerticalPhoto2 = function () {
        return this.castToPhoto(this.samplePhotoVertical2);
    };
    PhotoSampleServiceService.prototype.getHorizontalPhoto1 = function () {
        return this.castToPhoto(this.samplePhotoHorizontal1);
    };
    PhotoSampleServiceService.prototype.getHorizontalPhoto2 = function () {
        return this.castToPhoto(this.samplePhotoHorizontal2);
    };
    PhotoSampleServiceService.ngInjectableDef = i0.defineInjectable({ factory: function PhotoSampleServiceService_Factory() { return new PhotoSampleServiceService(); }, token: PhotoSampleServiceService, providedIn: "root" });
    return PhotoSampleServiceService;
}());
export { PhotoSampleServiceService };
