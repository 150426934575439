import { Component, OnInit, Input } from '@angular/core';
import { KintTimelineSupplementLink } from '../../timeline-supplement';

@Component({
  selector: 'app-timeline-supplement-link',
  templateUrl: './timeline-supplement-link.component.html',
  styleUrls: ['./timeline-supplement-link.component.css']
})
export class TimelineSupplementLinkComponent implements OnInit {

  @Input('supplement') supplement: KintTimelineSupplementLink;

  constructor() { }

  ngOnInit() {
  }

}
