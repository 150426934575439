import { Component, OnInit, Input, Inject } from '@angular/core';
import { KintTimelineBlock } from '../../kint-timeline-block';
import { KintTimeline } from '../../kint-timeline';

@Component({
  selector: 'app-timeline-block-vertical',
  templateUrl: './timeline-block-vertical.component.html',
  styleUrls: ['./timeline-block-vertical.component.css']
})
export class TimelineBlockVerticalComponent implements OnInit {

  @Input('timelineBlock') timelineBlock: KintTimelineBlock;
  @Input('timeline') timeline: KintTimeline;

  constructor(@Inject('AppDataModelService') private ng1AppDataModelService: any) {
  }

  ngOnInit() {
  }

  isMobile(): boolean {
    return this.ng1AppDataModelService.isMobile();
  }

}
