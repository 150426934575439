import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';

import { CommonModule } from '@angular/common';
import { MessageBox } from './MessageBox/message-box.service';
import { UtilService } from './Util/util/util.service';
import { PhotoSelectionService } from './Photo/photo-selection.service';
import { BuilderService } from './Util/builder/builder.service';
import { PersonTaggingService } from './Photo/Tagging/person-tagging.service';

@NgModule({
  imports:      [
    CommonModule,
    ],
  declarations: [ ],
  exports:      [ ],
  providers:    [ BuilderService, MessageBox, PersonTaggingService, PhotoSelectionService, UtilService ]
})
export class CoreModule {
  constructor (@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreModule,
      providers: [
        BuilderService,
        MessageBox,
        PersonTaggingService,
        PhotoSelectionService,
        UtilService
      ]
    };
  }
}
