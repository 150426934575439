<div class="timeline-supplement">

  <ng-container [ngSwitch]="supplement.type">

    <app-timeline-supplement-story *ngSwitchCase="'TEXT'"
                                   [supplement]="supplement">
    </app-timeline-supplement-story>

    <app-timeline-supplement-map *ngSwitchCase="'MAP'"
                                 [supplement]="supplement">
    </app-timeline-supplement-map>

    <app-timeline-supplement-link *ngSwitchCase="'LINK'"
                                  [supplement]="supplement">
    </app-timeline-supplement-link>

    <app-timeline-supplement-audio *ngSwitchCase="'AUDIO'"
                                  [supplement]="supplement">
    </app-timeline-supplement-audio>


    <!--default case when there are no matches -->
    <div *ngSwitchDefault>
      Unknown Supplement
    </div>

  </ng-container>

</div>