import { KintTimelineSupplementAudio, KintTimelineSupplementText, KintTimelineSupplementMap, KintTimelineSupplementLink } from './timeline-supplement';
var KintTimelineEntry = /** @class */ (function () {
    function KintTimelineEntry(photo) {
        var _this = this;
        this.photo = photo;
        this.supplements = [];
        // Map
        if (photo.location && photo.location.position) {
            var mapSupplement = new KintTimelineSupplementMap(photo.location);
            this.supplements.push(mapSupplement);
        }
        if (photo.title && photo.title.trim().length > 0) {
            var backstorySupplement = new KintTimelineSupplementText(photo.title);
            this.supplements.push(backstorySupplement);
        }
        // Backstory
        if (photo.story && photo.story.trim().length > 0) {
            var backstorySupplement = new KintTimelineSupplementText(photo.story);
            this.supplements.push(backstorySupplement);
        }
        // Audio
        if (photo.audioStories && photo.audioStories.length > 0) {
            var audioSupplement = new KintTimelineSupplementAudio(photo.audioStories);
            this.supplements.push(audioSupplement);
        }
        // Links
        if (photo.externalLinks && photo.externalLinks.length > 0) {
            photo.externalLinks.forEach(function (externalLink) {
                var linkSupplement = new KintTimelineSupplementLink(externalLink);
                _this.supplements.push(linkSupplement);
            });
        }
    }
    return KintTimelineEntry;
}());
export { KintTimelineEntry };
