import { Component, OnInit, Inject } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';

// Angular is loaded separately
declare var angular: any;


@Component({
  selector: 'app-face-debugger',
  templateUrl: './face-debugger.component.html',
  styleUrls: ['./face-debugger.component.css']
})
export class FaceDebuggerComponent implements OnInit {

  constructor(@Inject('APIService') private apiService: any) { }

  public faceCorrelations: any[] = [];
  public specificFaceId = '';
  public mode = 'SPECIFIC_FACE';

  ngOnInit() {
  }


  fetchSpecificFace(): void {
    this.mode = 'SPECIFIC_FACE';

    const self = this;

    const promise = this.apiService.getFaceDebugDataForFace(this.specificFaceId);
    promise.then(
      function(response) {
        self.faceCorrelations = response.matches;
        console.log('Fetched face correlations');
      },
      function(err) {
        console.error('Error fetching faces for face id: ' + JSON.stringify(err));
      }
    );
  }


  fetchAllFaces(): void {

    this.mode = 'ALL_FACES';

    const self = this;

    const promise = this.apiService.getFaceDebugData();
    promise.then(
      function(response) {
        self.faceCorrelations = response.matches;
        console.log('Fetched face correlations');
      },
      function(err) {
        console.error('Error fetching faces (ALL): ' + JSON.stringify(err));
      }
    );
  }


  fetchPersonFace(): void {
    this.mode = 'ALL_FACES';

    const self = this;

    const promise = this.apiService.getFaceDebugDataForPersonFaceFace(this.specificFaceId);
    promise.then(
      function(response) {
        self.faceCorrelations = response.matches;
        console.log('Fetched face correlations  for PersonFace');
      },
      function(err) {
        console.error('Error fetching faces for personface: ' + JSON.stringify(err));
      }
    );
  }

}


// Provide a downgraded version of this to run with AngularJS
angular.module('KintributeApp')
  .directive('faceDebugger', downgradeComponent(
    {
      component: FaceDebuggerComponent,
      inputs: []
    }
  ));
