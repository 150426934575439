import { OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { PhotoSampleServiceService } from '../photo-sample-service.service';
import { KintTimeline } from 'src/app/Timeline/kint-timeline';
import { KintTimelineEntry } from 'src/app/Timeline/kint-timeline-entry';
var KintNg2SamplesComponent = /** @class */ (function () {
    function KintNg2SamplesComponent(photoSampleService) {
        this.photoSampleService = photoSampleService;
        this.photos = [];
        this.photos.push(photoSampleService.getVerticalPhoto1());
        this.photos.push(photoSampleService.getVerticalPhoto2());
        this.photos.push(photoSampleService.getHorizontalPhoto1());
        this.photos.push(photoSampleService.getHorizontalPhoto2());
        this.timelineEntries = [];
        // Timeline Entry #1
        var horizontalPhoto1 = photoSampleService.getHorizontalPhoto1();
        delete horizontalPhoto1.story;
        delete horizontalPhoto1.location;
        delete horizontalPhoto1.audioStories;
        delete horizontalPhoto1.externalLinks;
        var horizontalEntry1 = new KintTimelineEntry(horizontalPhoto1);
        this.timelineEntries.push(horizontalEntry1);
        // Timeline Entry #2
        var verticalPhoto1 = photoSampleService.getVerticalPhoto1();
        var verticlEntry1 = new KintTimelineEntry(verticalPhoto1);
        this.timelineEntries.push(verticlEntry1);
        // Timeline Entry #3
        var horizontalPhoto2 = photoSampleService.getHorizontalPhoto2();
        delete horizontalPhoto2.story;
        var horizontalEntry2 = new KintTimelineEntry(horizontalPhoto2);
        this.timelineEntries.push(horizontalEntry2);
        // Timeline Entry #4
        var verticalPhoto2 = photoSampleService.getVerticalPhoto2();
        delete verticalPhoto2.location;
        delete verticalPhoto2.audioStories;
        delete verticalPhoto2.externalLinks;
        var verticlEntry2 = new KintTimelineEntry(verticalPhoto2);
        this.timelineEntries.push(verticlEntry2);
        // Fake collection
        var timelineCollection = {
            name: 'Timeline Collection',
            photos: [
                horizontalPhoto1,
                verticalPhoto1,
                horizontalPhoto2,
                verticalPhoto2
            ]
        };
        this.timeline = new KintTimeline(timelineCollection);
    }
    KintNg2SamplesComponent.prototype.ngOnInit = function () {
    };
    return KintNg2SamplesComponent;
}());
export { KintNg2SamplesComponent };
// Provide a downgraded version of this to run with AngularJS
angular.module('KintributeApp')
    .directive('kintNg2Samples', downgradeComponent({
    component: KintNg2SamplesComponent
}));
