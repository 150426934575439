import { OnInit } from '@angular/core';
import { KintTimelineBlock } from '../../kint-timeline-block';
import { KintTimeline } from '../../kint-timeline';
var TimelineBlockHorizontalComponent = /** @class */ (function () {
    function TimelineBlockHorizontalComponent() {
    }
    TimelineBlockHorizontalComponent.prototype.ngOnInit = function () {
    };
    return TimelineBlockHorizontalComponent;
}());
export { TimelineBlockHorizontalComponent };
