import { Component, OnInit, Input } from '@angular/core';
import { KintTimelineBlock } from '../../kint-timeline-block';
import { KintTimeline } from '../../kint-timeline';

@Component({
  selector: 'app-timeline-block-horizontal',
  templateUrl: './timeline-block-horizontal.component.html',
  styleUrls: ['./timeline-block-horizontal.component.css']
  /*,
  styles: [
    //':host { display: inline; }'
  ]*/
})
export class TimelineBlockHorizontalComponent implements OnInit {

  @Input('timelineBlock') timelineBlock: KintTimelineBlock;
  @Input('timeline') timeline: KintTimeline;

  constructor() { }

  ngOnInit() {
  }

}
