var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, ElementRef } from '@angular/core';
import { PersonalPageEditor } from '../personal-page-editor';
import { ViewCheckHelper } from 'src/app/Util/viewCheck/view-check-helper';
var PersonalPageTilesRowComponent = /** @class */ (function (_super) {
    __extends(PersonalPageTilesRowComponent, _super);
    function PersonalPageTilesRowComponent(elementRef) {
        var _this = _super.call(this) || this;
        _this.elementRef = elementRef;
        return _this;
    }
    PersonalPageTilesRowComponent.prototype.ngOnInit = function () {
    };
    PersonalPageTilesRowComponent.prototype.clickedTextField = function () {
        this.startEditMode('textField');
    };
    PersonalPageTilesRowComponent.prototype.clickedHeadlineField = function () {
        this.startEditMode('headlineField');
    };
    /**
     * Request starting edit mode with focus on the given field
     */
    PersonalPageTilesRowComponent.prototype.startEditMode = function (fieldToSelect) {
        var _this = this;
        // Get the top of this element pre-edit-mode
        var oldElementTop = this.elementRef.nativeElement.getBoundingClientRect().top;
        // Actually start the edit mode
        this.editor.startEditMode();
        // Fix the scroll position after we re-render
        this.onNextViewCheck().then(function () {
            var updatedElementTop = _this.elementRef.nativeElement.getBoundingClientRect().top;
            var currentScrollPos = $(window).scrollTop();
            var newScrollPos = currentScrollPos - (oldElementTop - updatedElementTop);
            $(window).scrollTop(newScrollPos);
            if (fieldToSelect === 'headlineField') {
                _this.headlineField.nativeElement.focus();
            }
            else if (fieldToSelect === 'textField') {
                _this.textField.nativeElement.focus();
            }
        });
    };
    /**
     * Whether or not to display in 2-column format or not
     */
    PersonalPageTilesRowComponent.prototype.showTwoColumnTiles = function () {
        if (this.isMobile) {
            return false;
        }
        if ((this.row.tiles.length === 1) ||
            (this.row.tiles.length === 2) ||
            (this.row.tiles.length === 4)) {
            return true;
        }
        return false;
    };
    /**
     * Whether or not to display in 3-column format or not
     */
    PersonalPageTilesRowComponent.prototype.showThreeColumnTiles = function () {
        if (this.isMobile) {
            return false;
        }
        if ((this.row.tiles.length === 3) ||
            (this.row.tiles.length > 5)) {
            return true;
        }
        return false;
    };
    return PersonalPageTilesRowComponent;
}(ViewCheckHelper));
export { PersonalPageTilesRowComponent };
