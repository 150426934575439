import { KintPhoto } from '../KintObject/kint-photo';
import { KintAttribution } from '../KintObject/kint-attribution';
import { KintSettings } from '../KintObject/kint-settings';
import { KintPermissions } from '../KintObject/kint-permissions';

/**
 * These interfaces define the data that we recieve from the Kintribute API
 *
 * We are deliberately not creating instance methods because want to be able
 * to use the classes and interfaces interchangibly (without explicitly calling
 * construtors for all of the JSON data. Basically, we just want to avoid
 * converting back and forth between the Javascript and Typescript objects
 */

export class PhotoEssay {
  id: string;
  name: string;
  cover: KintPhoto;
  layout: PhotoEssayLayout;
  owner: KintAttribution;
  settings: KintSettings;
  permissions: KintPermissions;
  userCanEdit: false;

  constructor() {
    this.id = '';
    this.name = '';
    this.layout = {
      rows: []
    };
  }
}

export interface PhotoEssayLayout {
  rows: PhotoEssayRow[];
}

export class PhotoEssayRow {
  type: PhotoEssayRowTypes;
  tiles: PhotoEssayTile[];

  constructor(type: PhotoEssayRowTypes, tiles: PhotoEssayTile[]) {
    this.type = type;
    this.tiles = tiles;
  }
}

export class PhotoEssayTile {
  type: PhotoEssayTileTypes;
  photo: KintPhoto;
  caption: string;
  width: number;
  height: number;
  css: any;

  constructor(type: PhotoEssayTileTypes, photo: KintPhoto, caption: string) {
    this.type = type;
    this.photo = photo;
    this.caption = caption;
  }
}


export enum PhotoEssayRowTypes {
  CAPTION = 'CAPTION',
  SINGLE_PHOTO = 'SINGLE_PHOTO',
  SIDE_BY_SIDE_PHOTOS = 'SIDE_BY_SIDE_PHOTOS',
  THREE_COLUMN_PHOTOS = 'THREE_COLUMN_PHOTOS',
  COLLAGE_THREE_PHOTOS = 'COLLAGE_THREE_PHOTOS',


  // LEGACY TYPES
  SINGLE_LANDSCAPE = 'SINGLE_LANDSCAPE',
  SINGLE_PORTRAIT = 'SINGLE_PORTRAIT',
  SIDE_BY_SIDE_LANDSCAPE = 'SIDE_BY_SIDE_LANDSCAPE',
  SIDE_BY_SIDE_PORTRAIT = 'SIDE_BY_SIDE_PORTRAIT',
  COLLAGE_TOP_HEAVY = 'COLLAGE_TOP_HEAVY',
  COLLAGE_LEFT_HEAVY = 'COLLAGE_LEFT_HEAVY'
}

export enum PhotoEssayTileTypes {
  CAPTION = 'CAPTION',
  PHOTO = 'PHOTO'
}





