import { OnInit } from '@angular/core';
import { KintTimelineSupplementAudio } from '../../timeline-supplement';
var TimelineSupplementAudioComponent = /** @class */ (function () {
    function TimelineSupplementAudioComponent() {
    }
    TimelineSupplementAudioComponent.prototype.ngOnInit = function () {
    };
    return TimelineSupplementAudioComponent;
}());
export { TimelineSupplementAudioComponent };
