import { OnInit } from '@angular/core';
import { PhotoService } from '../../photo.service';
import { downgradeComponent } from '@angular/upgrade/static';
import { UtilService } from 'src/app/Util/util/util.service';
import { MessageBox } from 'src/app/MessageBox/message-box.service';
var TagEditorComponent = /** @class */ (function () {
    function TagEditorComponent(utilService, messageBox, photoService) {
        this.utilService = utilService;
        this.messageBox = messageBox;
        this.photoService = photoService;
        this.newTagText = null;
        this.isFinishing = false;
    }
    TagEditorComponent.prototype.ngOnInit = function () {
        if (this.shouldFocus) {
            this.addTag();
        }
    };
    /**
     * Starts adding a new Keyword field and sets focus in the field
     *
     * @memberof TagEditorComponent
     */
    TagEditorComponent.prototype.addTag = function () {
        this.newTagText = '';
        // Ensure we have focus. Yes, I know jQuery is naughty
        setTimeout(function () {
            $('#tag-editor-new-tag-field').focus();
        }, 100);
    };
    /**
     * Gets a list of all of the tag names the user has entered
     */
    TagEditorComponent.prototype.getNewTagsNames = function () {
        var tagNames = [];
        if (this.newTagText && this.newTagText.trim()) {
            tagNames = this.utilService.splitTerms(this.newTagText);
        }
        return tagNames;
    };
    /**
     * Adds the tag which was being typed (if any)
     */
    TagEditorComponent.prototype.finishNewTag = function () {
        var _this = this;
        // Don't allow these to nest
        if (this.isFinishing) {
            return;
        }
        this.isFinishing = true;
        var allValid = true;
        var newTags = this.getNewTagsNames();
        var _loop_1 = function (i) {
            var newTag = newTags[i];
            var keyword = newTag.trim();
            if (keyword.length > 60) {
                allValid = false;
                var self_1 = this_1;
                this_1.messageBox.infoMessage('Tags may not be more than 60 characters long.', 'Tag too long').result.finally(function () {
                    self_1.isFinishing = false;
                });
                return { value: void 0 };
            }
        };
        var this_1 = this;
        // Sanity check all of the names first
        for (var i = 0; i < newTags.length; i++) {
            var state_1 = _loop_1(i);
            if (typeof state_1 === "object")
                return state_1.value;
        }
        // If no problems, add all of them to the photo
        if (allValid) {
            newTags.forEach(function (newTag) {
                var keyword = newTag.trim();
                if (keyword.length > 0) {
                    _this.photo.keywords.unshift(keyword);
                }
            });
        }
        // Remove the 'new' tag
        this.newTagText = null;
        this.isFinishing = false;
    };
    /**
     * Removes the given Keyword from the list of keywords on the photo
     *
     * @param {string} keyword
     * @memberof TagEditorComponent
     */
    TagEditorComponent.prototype.removeKeyword = function (keyword) {
        var index = this.photo.keywords.indexOf(keyword);
        if (index !== -1) {
            this.photo.keywords.splice(index, 1);
        }
    };
    /**
     * Removes the given AutoTag from the list of auto tags on the photo
     *
     * @param {KintAutoTag} autoTag
     * @memberof TagEditorComponent
     */
    TagEditorComponent.prototype.removeAutoTag = function (autoTag) {
        var index = this.photo.autoTags.indexOf(autoTag);
        if (index !== -1) {
            this.photo.autoTags.splice(index, 1);
        }
    };
    /**
     * Handle KeyDown events for when user presses 'enter'
     *
     * @param {*} $event
     * @memberof TagEditorComponent
     */
    TagEditorComponent.prototype.onKeydown = function ($event) {
        if ($event.keyCode === 13) {
            this.finishNewTag();
        }
    };
    /**
     * Checks to see if the photo has any visible tags
     *
     * @returns {boolean}
     * @memberof TagEditorComponent
     */
    TagEditorComponent.prototype.hasTags = function () {
        return this.photoService.hasTags(this.photo);
    };
    return TagEditorComponent;
}());
export { TagEditorComponent };
// Provide a downgraded version of this to run with AngularJS
angular.module('KintributeApp')
    .directive('tagEditor', downgradeComponent({
    component: TagEditorComponent,
    inputs: ['photo', 'shouldFocus']
}));
