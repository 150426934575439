import { ModuleWithProviders } from '@angular/core';
import { MessageBox } from './MessageBox/message-box.service';
import { UtilService } from './Util/util/util.service';
import { PhotoSelectionService } from './Photo/photo-selection.service';
import { BuilderService } from './Util/builder/builder.service';
import { PersonTaggingService } from './Photo/Tagging/person-tagging.service';
var CoreModule = /** @class */ (function () {
    function CoreModule(parentModule) {
        if (parentModule) {
            throw new Error('CoreModule is already loaded. Import it in the AppModule only');
        }
    }
    CoreModule.forRoot = function () {
        return {
            ngModule: CoreModule,
            providers: [
                BuilderService,
                MessageBox,
                PersonTaggingService,
                PhotoSelectionService,
                UtilService
            ]
        };
    };
    return CoreModule;
}());
export { CoreModule };
