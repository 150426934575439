<div class="personal-page-tiles"
     [ngClass]="{'personal-page-tiles-mobile': isMobile,
                 'personal-page-tiles-row-can-edit': editor.userCanEdit()}"
     >
  
  <!-- Title -->
  <div class="personal-page-tiles-headline"
       [ngClass]="{'personal-page-tiles-row-headline-editor': editor.isEditMode}">
    <div *ngIf="!editor.isEditMode" 
         class="personal-page-hoverable-text"
         (click)="clickedHeadlineField()">
        {{row.headline}}
    </div>
    <div *ngIf="editor.isEditMode">
      <textarea #headlineField
                [(ngModel)]="row.headline" 
                class="personal-page-tiles-row-headline personal-page-tiles-row-textarea"
                rows="1">
      </textarea>
    </div>
  </div>

  <!-- Subline -->
  <div class="personal-page-tiles-row-text">
    <div *ngIf="!editor.isEditMode" 
         class="personal-page-hoverable-text"
         (click)="clickedTextField()">
      {{row.text}}
    </div>
    <div *ngIf="editor.isEditMode">
      <textarea #textField
                [(ngModel)]="row.text" 
                class="personal-page-tiles-row-text personal-page-tiles-row-textarea"
                rows="3">
      </textarea>
    </div>
  </div>


  <!-- Tiles -->
  <div class="personal-page-tiles-container">
    <div *ngFor="let tile of row.tiles"
         class="personal-page-tile-box"
         [ngClass]="{'personal-page-tile-box-three-column': showThreeColumnTiles(),
                     'personal-page-tile-box-two-column': showTwoColumnTiles()}">

      <app-personal-page-tile [tile]="tile"
                              [isMobile]="isMobile"
                              [editor]="editor">
      </app-personal-page-tile>

    </div>
  </div>

</div>
