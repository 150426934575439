import { UtilService } from '../Util/util/util.service';
var PersonalPageEditor = /** @class */ (function () {
    /**
     * Constructor
     */
    function PersonalPageEditor() {
        //
        // Member Variables
        //
        this.isEditMode = false;
        this.isSaving = false;
        this.personalPage = null;
        this.apiService = null;
        this.UtilService = new UtilService();
        this.backupPageEditor = null;
        console.log('PersonalPageEditor.isSaving: ' + this.isSaving);
    }
    /**
     * Sets the Profile Page that we want to edit
     */
    PersonalPageEditor.prototype.setPersonalPage = function (personalPage) {
        this.personalPage = personalPage;
    };
    /**
     * Sets the API Service
     */
    PersonalPageEditor.prototype.setApiService = function (apiService) {
        this.apiService = apiService;
    };
    PersonalPageEditor.prototype.userCanEdit = function () {
        return this.personalPage && this.personalPage.userCanEdit;
    };
    /**
     * Enter Edit mode
     */
    PersonalPageEditor.prototype.startEditMode = function () {
        if (this.isEditMode) {
            // Already in edit mode. Carry on...
            return;
        }
        this.backupPageEditor = this.UtilService.deepCopy(this.personalPage);
        this.isEditMode = true;
    };
    /**
     * Exit Edit mode
     */
    PersonalPageEditor.prototype.exitEditMode = function () {
        this.isEditMode = false;
    };
    /**
     * Cancel editting and restore everything to previous state
     */
    PersonalPageEditor.prototype.cancel = function () {
        this.restoreBackupPage();
        this.exitEditMode();
    };
    /**
     * Restore the PersonalPage to the backup (pre-editting)
     */
    PersonalPageEditor.prototype.restoreBackupPage = function () {
        this.personalPage = this.backupPageEditor;
        this.backupPageEditor = null;
    };
    /**
     * Save the updated version of this home page
     */
    PersonalPageEditor.prototype.save = function () {
        var self = this;
        this.isSaving = true;
        console.log('Saving profile page');
        var promise = this.apiService.saveHomePage(this.personalPage);
        promise.then(function () {
            console.log('Saved successfully');
        }, function (err) {
            console.error('Error saving profile page: ' + JSON.stringify(err));
        }).finally(function () {
            self.isSaving = false;
            self.exitEditMode();
        });
    };
    return PersonalPageEditor;
}());
export { PersonalPageEditor };
