import { Component, OnInit, Input, Inject, ViewEncapsulation } from '@angular/core';
import { KintPhoto, KintAutoTag } from 'src/app/KintObject/kint-photo';
import { PhotoService } from '../../photo.service';
import { downgradeComponent } from '@angular/upgrade/static';
import { UtilService } from 'src/app/Util/util/util.service';
import { MessageBox } from 'src/app/MessageBox/message-box.service';

// Angular is loaded separately
declare var angular: any;

// Naughty jquery usage
declare var $: any;

@Component({
  selector: 'app-tag-editor',
  templateUrl: './tag-editor.component.html',
  styleUrls: ['./tag-editor.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class TagEditorComponent implements OnInit {

  @Input('photo') photo: KintPhoto;
  @Input('shouldFocus') shouldFocus: boolean;

  public newTagText: string = null;
  private isFinishing = false;

  constructor(private utilService: UtilService,
              private messageBox: MessageBox,
              private photoService: PhotoService) { }

  ngOnInit() {
    if (this.shouldFocus) {
      this.addTag();
    }
  }



  /**
   * Starts adding a new Keyword field and sets focus in the field
   *
   * @memberof TagEditorComponent
   */
  addTag(): void {
    this.newTagText = '';

    // Ensure we have focus. Yes, I know jQuery is naughty
    setTimeout(function() {
      $('#tag-editor-new-tag-field').focus();
    }, 100);
  }



  /**
   * Gets a list of all of the tag names the user has entered
   */
  getNewTagsNames(): string[] {
    let tagNames = [];
    if (this.newTagText && this.newTagText.trim()) {
      tagNames = this.utilService.splitTerms(this.newTagText);
    }
    return tagNames;
  }


  /**
   * Adds the tag which was being typed (if any)
   */
  finishNewTag(): void {

    // Don't allow these to nest
    if (this.isFinishing) {
      return;
    }


    this.isFinishing = true;

    let allValid = true;
    const newTags = this.getNewTagsNames();

    // Sanity check all of the names first
    for (let i = 0; i < newTags.length; i++) {
      const newTag = newTags[i];

      const keyword = newTag.trim();
      if (keyword.length > 60) {
        allValid = false;
        const self = this;
        this.messageBox.infoMessage('Tags may not be more than 60 characters long.', 'Tag too long').result.finally(
          function() {
            self.isFinishing = false;
          }
        );
        return;
      }
    }


    // If no problems, add all of them to the photo
    if (allValid) {
      newTags.forEach((newTag) => {
        const keyword = newTag.trim();
        if (keyword.length > 0) {
          this.photo.keywords.unshift(keyword);
        }
      });
    }

    // Remove the 'new' tag
    this.newTagText = null;
    this.isFinishing = false;
  }


  /**
   * Removes the given Keyword from the list of keywords on the photo
   *
   * @param {string} keyword
   * @memberof TagEditorComponent
   */
  removeKeyword(keyword: string): void {
    const index = this.photo.keywords.indexOf(keyword);
    if (index !== -1) {
      this.photo.keywords.splice(index, 1);
    }
  }


  /**
   * Removes the given AutoTag from the list of auto tags on the photo
   *
   * @param {KintAutoTag} autoTag
   * @memberof TagEditorComponent
   */
  removeAutoTag(autoTag: KintAutoTag): void {
    const index = this.photo.autoTags.indexOf(autoTag);
    if (index !== -1) {
      this.photo.autoTags.splice(index, 1);
    }
  }


  /**
   * Handle KeyDown events for when user presses 'enter'
   *
   * @param {*} $event
   * @memberof TagEditorComponent
   */
  onKeydown($event: any): void {
    if ($event.keyCode === 13) {
      this.finishNewTag();
    }
  }


  /**
   * Checks to see if the photo has any visible tags
   *
   * @returns {boolean}
   * @memberof TagEditorComponent
   */
  hasTags(): boolean {
    return this.photoService.hasTags(this.photo);
  }

}


// Provide a downgraded version of this to run with AngularJS
angular.module('KintributeApp')
  .directive('tagEditor', downgradeComponent(
    {
      component: TagEditorComponent,
      inputs: ['photo', 'shouldFocus']
    }
  ));
