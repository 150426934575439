<div>

  <h2> Kintribute Angular 6 Samples </h2>
  <hr>


  <!--
  <div style="display: block; height: 600px; background-color: red">
    <div style="height: 100px; background-color: green">
      Some header stuff
    </div>
    <div style="display: flex; flex-direction: column; background-color: blue;">
      <div style="width: 100%; height: 100%; background-color: pink; width: 300px; flex-grow: 1; flex-shrink: 1;">
      </div>
    </div>
  </div>
  -->


  <!--
  <h2> Horizontal Timeline (Prototype) </h2>
  <app-horizontal-timeline>
  </app-horizontal-timeline>
  -->


  <h2> Horizontal Timeline (Prototype) </h2>
  <app-timeline-layout-horizontal [timeline]="timeline">
  </app-timeline-layout-horizontal>


  <h2> Vertical Timeline </h2>
  <app-timeline-layout-vertical [timeline]="timeline">
  </app-timeline-layout-vertical>
  

</div>