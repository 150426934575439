import { downgradeInjectable } from '@angular/upgrade/static';
import { PersonTagAction, PersonTagActionType, PersonTagActionConstraints } from 'src/app/KintObject/kint-photo';
import { UtilService } from 'src/app/Util/util/util.service';
import * as i0 from "@angular/core";
import * as i1 from "../../Util/util/util.service";
var PersonTaggingService = /** @class */ (function () {
    function PersonTaggingService(utilService) {
        this.utilService = utilService;
    }
    /**
     * Figure out which Person tags have been added/removed from the given photo
     *
     * This function generates a list of PersonTagActions which can be reviewed by the user
     * before submitting a changed photo back to the server
     */
    PersonTaggingService.prototype.getPersonTagChanges = function (oldPhoto, newPhoto) {
        // Sanity check!
        if (!oldPhoto.people) {
            oldPhoto.people = [];
        }
        if (!newPhoto.people) {
            newPhoto.people = [];
        }
        var generatedActions = [];
        var tagsAdded = [];
        var tagsRemoved = [];
        // Do a Diff of the People in the photos
        this.comparePersonTagArrays(oldPhoto.people, newPhoto.people, tagsAdded, tagsRemoved);
        // Figure out what actions were taken (add/remove) and return
        // the list of those actions
        tagsRemoved.forEach(function (addedTag) {
            var personTagAction = new PersonTagAction(addedTag, PersonTagActionType.REMOVE, PersonTagActionConstraints.SINGLE);
            generatedActions.push(personTagAction);
        });
        tagsAdded.forEach(function (addedTag) {
            var personTagAction = new PersonTagAction(addedTag, PersonTagActionType.ADD, PersonTagActionConstraints.SINGLE);
            generatedActions.push(personTagAction);
        });
        return generatedActions;
    };
    /**
     * Determine if 2 person-tags are equal or not (same person, same coordinates)
     *
     * @param {*} personTag1
     * @param {*} personTag2
     * @returns {boolean}
     * @memberof PersonTaggingService
     */
    PersonTaggingService.prototype.arePersonTagsEqual = function (personTag1, personTag2) {
        if ((personTag1.id === personTag2.id) &&
            (personTag1.coords && personTag2.coords) &&
            (personTag1.coords.x === personTag2.coords.x) &&
            (personTag1.coords.y === personTag2.coords.y) &&
            (personTag1.coords.width === personTag2.coords.width) &&
            (personTag1.coords.height === personTag2.coords.height)) {
            return true;
        }
        return false;
    };
    /**
     * Compares Two arrays and figures out what has been added/removed
     *
     * @param {any[]} oldPersonList
     * @param {any[]} newPersonList
     * @param {any[]} elementsAdded  (Initially empty, results returned in this array)
     * @param {any[]} elementsRemoved (Initially empty, results returned in this array)
     * @memberof PersonTaggingService
     */
    PersonTaggingService.prototype.comparePersonTagArrays = function (oldArray, newArray, elementsAdded, elementsRemoved) {
        var _this = this;
        // Clear the existing arrays
        elementsAdded.length = 0;
        elementsRemoved.length = 0;
        // See which elements have been added
        oldArray.forEach(function (oldElement) {
            var stillExists = false;
            newArray.forEach(function (newElement) {
                if (_this.arePersonTagsEqual(oldElement, newElement)) {
                    stillExists = true;
                }
            });
            if (!stillExists) {
                elementsRemoved.push(oldElement);
            }
        });
        // See which elements have been removed
        newArray.forEach(function (newElement) {
            var alreadyExisted = false;
            oldArray.forEach(function (oldElement) {
                if (_this.arePersonTagsEqual(oldElement, newElement)) {
                    alreadyExisted = true;
                }
            });
            if (!alreadyExisted) {
                elementsAdded.push(newElement);
            }
        });
    };
    PersonTaggingService.ngInjectableDef = i0.defineInjectable({ factory: function PersonTaggingService_Factory() { return new PersonTaggingService(i0.inject(i1.UtilService)); }, token: PersonTaggingService, providedIn: "root" });
    return PersonTaggingService;
}());
export { PersonTaggingService };
// Register an AngularJS service, whose value is the "downgraded" Angular injectable.
angular.module('KintributeApp').factory('PersonTaggingService', downgradeInjectable(PersonTaggingService));
