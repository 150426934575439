/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./personal-page-content.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../personal-page-row/personal-page-row.component.ngfactory";
import * as i3 from "../personal-page-row/personal-page-row.component";
import * as i4 from "@angular/common";
import * as i5 from "./personal-page-content.component";
var styles_PersonalPageContentComponent = [i0.styles];
var RenderType_PersonalPageContentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PersonalPageContentComponent, data: {} });
export { RenderType_PersonalPageContentComponent as RenderType_PersonalPageContentComponent };
function View_PersonalPageContentComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "personal-page-content-row-holder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-personal-page-row", [], null, null, null, i2.View_PersonalPageRowComponent_0, i2.RenderType_PersonalPageRowComponent)), i1.ɵdid(2, 114688, null, 0, i3.PersonalPageRowComponent, [], { row: [0, "row"], isMobile: [1, "isMobile"], editor: [2, "editor"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.isMobile; var currVal_2 = _co.editor; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_PersonalPageContentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "personal-page-content"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i4.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "personal-page-content-mobile": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PersonalPageContentComponent_1)), i1.ɵdid(4, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "personal-page-content"; var currVal_1 = _ck(_v, 2, 0, _co.isMobile); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.page.layout.rows; _ck(_v, 4, 0, currVal_2); }, null); }
export function View_PersonalPageContentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-personal-page-content", [], null, null, null, View_PersonalPageContentComponent_0, RenderType_PersonalPageContentComponent)), i1.ɵdid(1, 114688, null, 0, i5.PersonalPageContentComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PersonalPageContentComponentNgFactory = i1.ɵccf("app-personal-page-content", i5.PersonalPageContentComponent, View_PersonalPageContentComponent_Host_0, { page: "page", isMobile: "isMobile", editor: "editor" }, {}, []);
export { PersonalPageContentComponentNgFactory as PersonalPageContentComponentNgFactory };
