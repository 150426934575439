<div>

  <!-- The Timeline itself -->
  <div class="timeline-layout-horizontal">

    <!-- Display all of the timeline blocks -->
    <ng-container *ngFor="let timelineBlock of timeline.timelineBlocks" class="timeline-layout-horizontal-block-holder">
      <app-timeline-block-horizontal [timelineBlock]="timelineBlock" [timeline]="timeline">
      </app-timeline-block-horizontal>
    </ng-container>
  </div>


  <!-- Undated Photos Listing -->
  <div *ngIf="timeline.undatedPhotos.length > 0">
    <div class="timeline-layout-horizontal-undated-photos">
      + {{timeline.undatedPhotos.length}} Undated Photos 
    </div>
  </div>

</div>