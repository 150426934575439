<div class="timeline-block-horizontal"
     [class.timeline-block-horizontal-level-1]="timelineBlock.level == 1"
     [class.timeline-block-horizontal-level-2]="timelineBlock.level == 2"
     [class.timeline-block-horizontal-level-3]="timelineBlock.level == 3">

  <!-- Top-Level block -->
  <div *ngIf="timelineBlock.level == 1" class="timeline-block-horizontal-level-1-header">
    <div class="timeline-block-horiztonal-level-1-header-text">
      {{timelineBlock.header}}
    </div>
  </div>

  <!-- 2nd Level Block -->
  <div *ngIf="timelineBlock.level == 2" class="timeline-block-horizontal-level-2-header">

    <div class="timeline-block-horizontal-level-2-text-holder">
      <div class="timline-block-level-2-text">
        {{ timelineBlock.header }}
      </div>
    </div>

    <div class="timeline-block-horizontal-line"></div>

  </div>
  
  <!-- 3rd Level Block -->
  <div *ngIf="timelineBlock.level == 3" class="timeline-block-horizontal-level-3-header">
    {{ timelineBlock.header }}
  </div>


  <!-- Nested Timeline Blocks -->
  <div class="timeline-block-entry-horizontal-holder">
    <div *ngFor="let timelineBlock of timelineBlock.timelineBlocks; index as index;" 
         class="timeline-block-entry-horizontal-flex"
         [ngClass]="{'timeline-block-entry-horizontal-flex-padded': (index != timelineBlock.timelineBlocks.length -1) }">

      <app-timeline-block-horizontal [timelineBlock]="timelineBlock" [timeline]="timeline">
      </app-timeline-block-horizontal>

    </div>
  </div>

  <!-- Stand-alone Timeline entries -->
  <div class="timeline-block-entry-horizontal-holder">
    <div *ngFor="let timelineEntry of timelineBlock.timelineEntries; index as index" 
          class="timeline-block-entry-horizontal-flex"
          [ngClass]="{'timeline-block-entry-horizontal-flex-padded': (index != timelineBlock.timelineEntries.length - 1) }">

      <app-timeline-entry-horizontal [timelineEntry]="timelineEntry" [timeline]="timeline">
      </app-timeline-entry-horizontal>

    </div>
  </div>

</div>