var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, ElementRef } from '@angular/core';
import { PersonalPageEditor } from '../personal-page-editor';
import { ViewCheckHelper } from 'src/app/Util/viewCheck/view-check-helper';
var PersonalPageTextRowComponent = /** @class */ (function (_super) {
    __extends(PersonalPageTextRowComponent, _super);
    function PersonalPageTextRowComponent(elementRef) {
        var _this = _super.call(this) || this;
        _this.elementRef = elementRef;
        return _this;
    }
    PersonalPageTextRowComponent.prototype.ngOnInit = function () {
    };
    PersonalPageTextRowComponent.prototype.clickedTextField = function () {
        this.startEditMode('textField');
    };
    PersonalPageTextRowComponent.prototype.clickedHeadlineField = function () {
        this.startEditMode('headlineField');
    };
    PersonalPageTextRowComponent.prototype.startEditMode = function (fieldToSelect) {
        var _this = this;
        // Get the top of this element pre-edit-mode
        var oldElementTop = this.elementRef.nativeElement.getBoundingClientRect().top;
        // Actually start the edit mode
        this.editor.startEditMode();
        // Fix the scroll position after we re-render
        this.onNextViewCheck().then(function () {
            var updatedElementTop = _this.elementRef.nativeElement.getBoundingClientRect().top;
            // Scroll the element to the right spot
            var currentScrollPos = $(window).scrollTop();
            var newScrollPos = currentScrollPos - (oldElementTop - updatedElementTop);
            $(window).scrollTop(newScrollPos);
            // Put focus on the field
            if (fieldToSelect === 'headlineField') {
                _this.headlineField.nativeElement.focus();
            }
            else if (fieldToSelect === 'textField') {
                _this.textField.nativeElement.focus();
            }
        });
    };
    return PersonalPageTextRowComponent;
}(ViewCheckHelper));
export { PersonalPageTextRowComponent };
