import { Component, OnInit, Input } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { KintPersonalPage} from 'src/app/KintObject/kint-personal-page';
import { PersonalPageEditor } from '../personal-page-editor';


// Angular is loaded separately
declare var angular: any;

@Component({
  selector: 'app-personal-page-content',
  templateUrl: './personal-page-content.component.html',
  styleUrls: ['./personal-page-content.component.css']
})
export class PersonalPageContentComponent implements OnInit {

  @Input('page') page: KintPersonalPage;
  @Input('isMobile') isMobile: boolean;
  @Input('editor') editor: PersonalPageEditor;

  constructor() { }

  ngOnInit() {
  }


  /*
  getTextBlock(): PersonalPageRow {
    // Find the first text block in the personal page
    // Long-term, we will likely do more customizing, so this
    // API is very open as to what it supports
    for (const row of this.page.rows) {
      if (row.type === PersonalPageRowType.TEXT) {
        return row;
      }
    }
    return null;
  }


  getCollectionsBlock(): PersonalPageRow {
    // Find the first text collection block in the personal page
    // Long-term, we will likely do more customizing, so this
    // API is very open as to what it supports
    for (const row of this.page.rows) {
      if (row.type === PersonalPageRowType.COLLECTION_HIGHLIGHTS) {
        return row;
      }
    }
    return null;
  }


  getEssaysBlock(): PersonalPageRow {
    // Find the first text Essay block in the personal page
    // Long-term, we will likely do more customizing, so this
    // API is very open as to what it supports
    for (const row of this.page.rows) {
      if (row.type === PersonalPageRowType.ESSAY_HIGHLIGHTS) {
        return row;
      }
    }
    return null;
  }
  */

}


// Provide a downgraded version of this to run with AngularJS
angular.module('KintributeApp')
  .directive('appPersonalPageContent', downgradeComponent(
    {
      component: PersonalPageContentComponent,
      inputs: ['page', 'editor']
    }
  ));
