// @angular
import { NgModule} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UpgradeModule } from '@angular/upgrade/static';

// Material Design
import { MatFormFieldModule, MatInputModule, MatAutocompleteModule, MatButtonModule,
         MatCheckboxModule, MatProgressSpinnerModule, MatTooltipModule } from '@angular/material';

import { AppComponent } from './app.component';
import { CoreModule } from './core.modules';
import { UIRouterUpgradeModule } from '@uirouter/angular-hybrid';

// AngularJS
import { KintAudioPlayerDirective } from './ng1/General/kint-audio-player-component';
import { KintIconDirective } from './ng1/General/kint-icon-component';
import { KintSliderToggleDirective } from './ng1/General/kint-slider-toggle.component';
import { PhotoEssayTileDirective } from './ng1/PhotoEssay/photo-essay-tile.component';
import { PhotoEssayAddRowDirective } from './ng1/PhotoEssay/photo-essay-add-row.component';

// Angular6
import { PartyComponent } from './Misc/party/party.component';
import { PhotoDisplayDirective } from './Photo/photo-display.directive';
import { PhotoEssayRowComponent } from './PhotoEssay/photo-essay-row/photo-essay-row.component';
import { PersonalPageComponent } from './PersonalPage/personal-page/personal-page.component';
import { PersonalPageHeaderComponent } from './PersonalPage/personal-page-header/personal-page-header.component';
import { UtilService } from './Util/util/util.service';
import { PersonalPageContentComponent } from './PersonalPage/personal-page-content/personal-page-content.component';
import { PersonalPageRowComponent } from './PersonalPage/personal-page-row/personal-page-row.component';
import { PersonalPageTextRowComponent } from './PersonalPage/personal-page-text-row/personal-page-text-row.component';
import { PersonalPageTilesRowComponent } from './PersonalPage/personal-page-tiles-row/personal-page-tiles-row.component';
import { PersonalPageTileComponent } from './PersonalPage/personal-page-tile/personal-page-tile.component';
import { SaveBarComponent } from './Misc/save-bar/save-bar.component';
import { KintButtonDirective } from './ng1/General/kint-button-component';
import { PersonalPageEditComponent } from './PersonalPage/personal-page-edit/personal-page-edit.component';
import { FaceDebuggerComponent } from './FaceRecognition/face-debugger/face-debugger.component';
import { FaceCompareComponent } from './FaceRecognition/face-compare/face-compare.component';
import { TagEditorComponent } from './Photo/Keywords/tag-editor/tag-editor.component';
import { LocationEditComponent } from './Photo/Location/location-edit/location-edit.component';

// Timeline
import { TimelineSupplementStoryComponent } from './Timeline/Universal/timeline-supplement-story/timeline-supplement-story.component';
import { TimelineSupplementLinkComponent } from './Timeline/Universal/timeline-supplement-link/timeline-supplement-link.component';
import { TimelineSupplementMapComponent } from './Timeline/Universal/timeline-supplement-map/timeline-supplement-map.component';
import { TimelineSupplementAudioComponent } from './Timeline/Universal/timeline-supplement-audio/timeline-supplement-audio.component';
import { TimelineSupplementComponent } from './Timeline/Universal/timeline-supplement/timeline-supplement.component';

// Timeline Vertical vs Horizontal
import { TimelineEntryVerticalComponent } from './Timeline/Vertical/timeline-entry-vertical/timeline-entry-vertical.component';
import { TimelineEntryHorizontalComponent } from './Timeline/Horizontal/timeline-entry-horizontal/timeline-entry-horizontal.component';
import { TimelineLayoutVerticalComponent } from './Timeline/Vertical/timeline-layout-vertical/timeline-layout-vertical.component';
import { TimelineLayoutHorizontalComponent } from './Timeline/Horizontal/timeline-layout-horizontal/timeline-layout-horizontal.component';
import { TimelineBlockVerticalComponent } from './Timeline/Vertical/timeline-block-vertical/timeline-block-vertical.component';
import { TimelineBlockHorizontalComponent } from './Timeline/Horizontal/timeline-block-horizontal/timeline-block-horizontal.component';

// Samples
import { KintNg2SamplesComponent } from './Samples/kint-ng2-samples/kint-ng2-samples.component';
import { HorizontalTimelineComponent } from './Samples/horizontal-timeline/horizontal-timeline.component';


// import { NgxMapboxGLModule } from 'ngx-mapbox-gl';

@NgModule({
  declarations: [
    AppComponent,
    KintAudioPlayerDirective,
    KintButtonDirective,
    KintIconDirective,
    KintSliderToggleDirective,
    PartyComponent,
    PhotoDisplayDirective,
    PhotoEssayAddRowDirective,
    PhotoEssayRowComponent,
    PhotoEssayTileDirective,
    PersonalPageHeaderComponent,
    PhotoDisplayDirective,
    PersonalPageTilesRowComponent,
    PersonalPageComponent,
    PersonalPageContentComponent,
    PersonalPageTextRowComponent,
    PersonalPageRowComponent,
    PersonalPageTileComponent,
    SaveBarComponent,
    PersonalPageEditComponent,
    FaceDebuggerComponent,
    FaceCompareComponent,
    TagEditorComponent,
    LocationEditComponent,
    TimelineLayoutHorizontalComponent,
    TimelineLayoutVerticalComponent,
    TimelineEntryHorizontalComponent,
    TimelineEntryVerticalComponent,
    TimelineSupplementStoryComponent,
    TimelineSupplementLinkComponent,
    TimelineSupplementMapComponent,
    TimelineSupplementAudioComponent,
    TimelineSupplementComponent,
    KintNg2SamplesComponent,
    TimelineBlockVerticalComponent,
    TimelineBlockHorizontalComponent,
    HorizontalTimelineComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    CoreModule.forRoot(),
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    UpgradeModule,
    UIRouterUpgradeModule.forRoot(),

    // Material Design
    MatAutocompleteModule,
    MatButtonModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatTooltipModule,

    /*
    NgxMapboxGLModule.withConfig({
      accessToken: 'pk.eyJ1IjoidGhvbWFzbWFybGluIiwiYSI6ImNqdDhzYXphdTBjMWQzeXA0N2Z5MG92d3UifQ.bVTodAZu2wQh8myqUuPCbg'
    })
    */
  ],
  providers: [
    {
      // AngularJS MessageBox service
      provide: 'MessageBoxService',
      useFactory: ($injector: any) => $injector.get('MessageBoxService'),
      deps: ['$injector']
    },
    {
      // AngularJS AppDataModelService service
      provide: 'AppDataModelService',
      useFactory: ($injector: any) => $injector.get('AppDataModelService'),
      deps: ['$injector']
    },
    {
      // AngularJS APIService service
      provide: 'APIService',
      useFactory: ($injector: any) => $injector.get('APIService'),
      deps: ['$injector']
    },
    {
      // AngularJS DOMService service
      provide: 'DOMService',
      useFactory: ($injector: any) => $injector.get('DOMService'),
      deps: ['$injector']
    },
    {
      // AngularJS FaceComparePromptService service
      provide: 'FaceComparePromptService',
      useFactory: ($injector: any) => $injector.get('FaceComparePromptService'),
      deps: ['$injector']
    },
    {
      // AngularJS LinkHelperService service
      provide: 'LinkHelperService',
      useFactory: ($injector: any) => $injector.get('LinkHelperService'),
      deps: ['$injector']
    },
    {
      // AngularJS ModalService service
      provide: 'ModalService',
      useFactory: ($injector: any) => $injector.get('ModalService'),
      deps: ['$injector']
    },
    UtilService
  ],
  entryComponents: [
    AppComponent,
    FaceCompareComponent,
    FaceDebuggerComponent,
    KintNg2SamplesComponent,
    LocationEditComponent,
    PartyComponent,
    PhotoEssayRowComponent,
    PersonalPageContentComponent,
    PersonalPageComponent,
    PersonalPageHeaderComponent,
    SaveBarComponent,
    TagEditorComponent,
    TimelineLayoutVerticalComponent,
    TimelineLayoutHorizontalComponent
  ]
})
export class AppModule {
  constructor(private upgrade: UpgradeModule) { }

  ngDoBootstrap() {

    // Load the Legacy Kintribute app prior to Angular6
    this.upgrade.bootstrap(document.body, ['KintributeApp']);

  }

}


