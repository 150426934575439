import { Directive, ElementRef, Injector, Input, Output } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
@Directive({
    // tslint:disable-next-line:directive-selector
    selector: 'ng1-kint-audio-player'
})
export class KintAudioPlayerDirective extends UpgradeComponent {

    @Input('audio') audio: string;

    constructor(elementRef: ElementRef, injector: Injector) {
        // console.log('kint-audio-player constructor!');
        super('kintAudioPlayer', elementRef, injector);
    }
}
