<div class="personal-page-highlight"
     [ngClass]="{'personal-page-tile-mobile': isMobile}">

  <a href="{{getTileLink()}}">

    <!-- Display the cover photo -->
    <div class="personal-page-tile-cover">

      <!-- Cover Photo -->
      <div class="personal-page-tile-cover-image"
          appPhotoDisplay 
          [photoData]="getCover()"
          [isBackgroundImage]=true>
      </div>

      <!-- Spacer (gives the element it's height) -->
      <div class="personal-page-tile-cover-spacer">
      </div>

    </div>

    <!-- Type of Highlight -->
    <div class="personal-page-tile-type">
      {{getTileType()}}
    </div>

    <div class="personal-page-tile-title">
      {{getTileName()}}
    </div>

  </a>
</div>