<div class="face-compare">

  <div class="face-compare-top"
       >
    
    <img class="face-compare-source-image"
         appPhotoDisplay 
         [photoData]="currentFaceComparePrompt.newFace.photo">
  </div>

  <div class="face-compare-bottom">

    <!-- Title -->
    <div class="face-compare-title">
      Same or different person?
    </div>

    <!-- Sub-text -->
    <div class="face-compare-subtext">
      Help us learn and make things easier for you
    </div>

    <!-- Thumbnails -->
    <div class="face-compare-thumbnail-holder" *ngIf="currentFaceComparePrompt">

      <div class="face-compare-thumbnail-block">
        <img class="face-compare-thumbnail-img" src="{{currentFaceComparePrompt.personFace.thumbnail}}">
      </div>
      <div class="face-compare-thumbnail-block">
        <img class="face-compare-thumbnail-img" src="{{currentFaceComparePrompt.newFace.thumbnail}}">
      </div>
    </div>

    <!-- Responses -->
    <div class="face-compare-responses-holder">

      <!-- Match -->
      <div *ngIf="!isProcessing" class="face-compare-response-block" (click)="confirmFace()">
        <img class="face-compare-response-icon" src="/img/check.png">
        <div class="face-compare-response-text">Same</div>
      </div>

      <!-- NOT Match -->
      <div *ngIf="!isProcessing" class="face-compare-response-block" (click)="rejectFace()">
        <img class="face-compare-response-icon" src="/img/x.png">
        <div class="face-compare-response-text">Different</div>
      </div>

      <!-- Unsure -->
      <div *ngIf="!isProcessing" class="face-compare-response-block" (click)="skipFace()">
        <img class="face-compare-response-icon" src="/img/questionmark.png">
        <div class="face-compare-response-text">Not Sure</div>
      </div>

      <!-- 'Saving' progress -->
      <div *ngIf="isProcessing" class="face-compare-processing-block">
        Applying results...
      </div>
    </div>

  </div>

</div>