import { OnInit } from '@angular/core';
import { PersonalPageEditor } from '../personal-page-editor';
var PersonalPageTileComponent = /** @class */ (function () {
    function PersonalPageTileComponent(linkHelperService) {
        this.linkHelperService = linkHelperService;
    }
    PersonalPageTileComponent.prototype.ngOnInit = function () {
        console.log('personal-page-tiles: ' + JSON.stringify(this.tile));
    };
    PersonalPageTileComponent.prototype.getTileLink = function () {
        if (this.tile.collection) {
            return this.linkHelperService.buildCollectionLink(this.tile.collection);
        }
        if (this.tile.essay) {
            return this.linkHelperService.buildEssayLink(this.tile.essay);
        }
        console.error('Error getting link for unknown tile type');
    };
    PersonalPageTileComponent.prototype.getCover = function () {
        if (this.tile.collection) {
            return this.tile.collection.cover;
        }
        else {
            return this.tile.essay.cover;
        }
    };
    PersonalPageTileComponent.prototype.getTileType = function () {
        if (this.tile.collection) {
            return 'Collection';
        }
        else {
            return 'Photo Essay';
        }
    };
    PersonalPageTileComponent.prototype.getTileName = function () {
        if (this.tile.collection) {
            return this.tile.collection.name;
        }
        else {
            return this.tile.essay.name;
        }
    };
    return PersonalPageTileComponent;
}());
export { PersonalPageTileComponent };
