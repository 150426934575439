import { KintAttribution } from './kint-attribution';
import { KintSize } from './kint-size';
import { KintCoords } from './kint-coords';
import { KintFace } from './kint-faces';
import { KintGeo, KintLocation } from './kint-location';
import { KintDate } from './kint-date';
import { KintAudio } from './kint-audio';

export interface KintPhoto {
  id: string;
  link: string;                       // Do not use this directly
  alternate_sizes: AlternateImageSize[];   // Alternative sizes of this photo (sizes + links)
  alternateLinks: string[];           // List of alternative copies of this image (URLS)
  thumbnail: string;                  // URL to a thumbnail of the image (do not use directly)
  owner: KintAttribution;
  userCanEdit: boolean;
  size: KintSize;
  people: PersonTag[];
  audioStories: KintAudio[];
  externalLinks: KintExternalLink[];
  faces: KintFace[];                  // Optional
  keywords: string[];
  autoTags: KintAutoTag[];
  geo: KintGeo;
  location: KintLocation;
  date: KintDate;
  story: string;
  title: string; // Legacy - Deprecated but for Dalai Lama
}

export class AlternateImageSize {
  link: string;
  height: number;
  width: number;

  constructor(width: number, height: number, link: string) {
    this.link = link;
    this.height = height;
    this.width = width;
  }
}

export class PersonTag {
  id: string; // Person Id
  displayName: string;
  coords: KintCoords;
}

export class PersonTagAction {

  constructor(tag: PersonTag, action: PersonTagActionType, constraints: PersonTagActionConstraints) {
    this.personTag = tag;
    this.action = action;
    this.constraints = constraints;
    this.confirmed = false;
  }

  // Tag we are acting on
  personTag: PersonTag;

  // What we are doing with it (add/remove)
  action:  PersonTagActionType;

  // How to apply this (Global, just this, everywhere)
  constraints: PersonTagActionConstraints;

  // Whether or not the user has confirmed this behavior.
  confirmed: false;
}

export class KintAutoTag {
  name: string;
  confidence: number;
  display: boolean;
  deleted: boolean;
}

export enum PersonTagActionType {
  REMOVE = 'REMOVE',
  ADD = 'ADD'
}

export enum PersonTagActionConstraints {
  GLOBAL = 'GLOBAL',
  ALL_MINE = 'ALL_MINE',
  SINGLE = 'SINGLE'
}


export interface KintExternalLink {
  text: string;
  url: string;
}
