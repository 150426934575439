import { DateHelper } from '../Util/Date/DateHelper';
import { KintTimelineEntry } from './kint-timeline-entry';
/**
 * This is a block of time for the Timeline. This includes a header and
 * dates within that range
 /**
  *
  *
  * @export
  * @class KintTimelineBlock
  */
var KintTimelineBlock = /** @class */ (function () {
    /**
     * Constructor
     */
    function KintTimelineBlock(header, timelineFormat, startDateIsoString, endDateIsoString, sortedPhotos, level) {
        var _this = this;
        // Instance Members
        this.header = '';
        this.timelineFormat = null;
        this.header = header;
        this.timelineFormat = timelineFormat;
        this.level = level;
        this.timelineBlocks = [];
        this.timelineEntries = [];
        /*
        if (sortedPhotos.length === 0) {
          console.error('Error building KintTimelineBlock. Insufficient photos');
          return;
        }
    
        this.startIsoDate = sortedPhotos[0].date.isoDate;
        this.endIsoDate = sortedPhotos[sortedPhotos.length - 1].date.isoDate;
        const startDate = new Date(this.startIsoDate);
        const endDate = new Date(this.endIsoDate);
        */
        var startDate = new Date(startDateIsoString);
        var endDate = new Date(endDateIsoString);
        // If we are done creating our "blocks", then just add the photos as timeline entries
        if (level >= timelineFormat.timelineBlockTypes.length) {
            sortedPhotos.forEach(function (photo) {
                _this.timelineEntries.push(new KintTimelineEntry(photo));
            });
        }
        // If we have more "blocks" to build, build them now (photos will go inside them)
        if (level < timelineFormat.timelineBlockTypes.length) {
            var timelineBlockToBuild = timelineFormat.timelineBlockTypes[level];
            this.timelineBlocks = KintTimelineBlocksBuilder.buildTimelineBlocks(timelineBlockToBuild, startDate, endDate, sortedPhotos, timelineFormat, level);
        }
    }
    /**
     * Adds a TimelineBlock to the given block
     *
     * @param {KintTimelineBlock} timelineBlock
     * @memberof KintTimelineBlock
     */
    KintTimelineBlock.prototype.addTimelineBlock = function (timelineBlock) {
        this.timelineBlocks.push(timelineBlock);
    };
    /**
     * Adds a TimelineEntry to the given block
     *
     * @param {KintTimelineEntry} timelineEntry
     * @memberof KintTimelineBlock
     */
    KintTimelineBlock.prototype.addTimelineEntry = function (timelineEntry) {
        // See if this timeline belongs in a sub-block or if this is the
        // best place to put it
        var addedToSubBlock = false;
        this.timelineBlocks.forEach(function (timelineBlock) {
            if (timelineBlock.isEntryInThisBlock(timelineEntry)) {
                timelineBlock.addTimelineEntry(timelineEntry);
                addedToSubBlock = true;
            }
        });
        // If we didn't add it to a sub-block, add it as a top-level entry to this block
        if (!addedToSubBlock) {
            this.timelineEntries.push(timelineEntry);
        }
    };
    /**
     * Checks t see if the given timeline entry belongs in the given block or no
     */
    KintTimelineBlock.prototype.isEntryInThisBlock = function (timelineEntry) {
        if ((timelineEntry.photo.date.isoDate <= this.startIsoDate) &&
            (timelineEntry.photo.date.isoDate <= this.endIsoDate)) {
            return true;
        }
        return false;
    };
    return KintTimelineBlock;
}());
export { KintTimelineBlock };
export var KintTimelineBlockType;
(function (KintTimelineBlockType) {
    KintTimelineBlockType["MASTER"] = "MASTER";
    KintTimelineBlockType["DECADE"] = "DECADE";
    KintTimelineBlockType["YEAR"] = "YEAR";
    KintTimelineBlockType["MONTH"] = "MONTH";
    KintTimelineBlockType["WEEK"] = "WEEK";
    KintTimelineBlockType["DAY"] = "DAY";
    KintTimelineBlockType["TIME_OF_DAY"] = "TIME_OF_DAY";
    KintTimelineBlockType["TIME"] = "TIME";
})(KintTimelineBlockType || (KintTimelineBlockType = {}));
var KintTimelineBlocksBuilder = /** @class */ (function () {
    function KintTimelineBlocksBuilder() {
    }
    /**
     * Build timeline blocks of the specific type
     *
     * @param {Date} startDate
     * @param {Date} endDate
     * @param {KintPhoto[]} sortedPhotos
     * @param {number} level
     * @returns {KintTimelineBlock[]}
     * @memberof KintTimelineBlock
     */
    KintTimelineBlocksBuilder.buildTimelineBlocks = function (timelineBlockType, startDate, endDate, sortedPhotos, timelineFormat, level) {
        var timelineBlocks = [];
        switch (timelineBlockType) {
            case KintTimelineBlockType.DECADE: {
                timelineBlocks = KintTimelineBlocksBuilder.buildDecadeBlocks(startDate, endDate, sortedPhotos, timelineFormat, level);
                break;
            }
            case KintTimelineBlockType.YEAR: {
                timelineBlocks = KintTimelineBlocksBuilder.buildYearBlocks(startDate, endDate, sortedPhotos, timelineFormat, level);
                break;
            }
            case KintTimelineBlockType.MONTH: {
                timelineBlocks = KintTimelineBlocksBuilder.buildMonthBlocks(startDate, endDate, sortedPhotos, timelineFormat, level);
                break;
            }
            case KintTimelineBlockType.WEEK: {
                timelineBlocks = KintTimelineBlocksBuilder.buildWeekBlocks(startDate, endDate, sortedPhotos, timelineFormat, level);
                break;
            }
            case KintTimelineBlockType.DAY: {
                timelineBlocks = KintTimelineBlocksBuilder.buildDayBlocks(startDate, endDate, sortedPhotos, timelineFormat, level);
                break;
            }
            case KintTimelineBlockType.TIME_OF_DAY: {
                timelineBlocks = KintTimelineBlocksBuilder.buildTimeOfDayBlocks(startDate, endDate, sortedPhotos, timelineFormat, level);
                break;
            }
        }
        return timelineBlocks;
    };
    /**
     * Build decade-level blocks
     *
     * @param {Date} startDate
     * @param {Date} endDate
     * @param {KintPhoto[]} sortedPhotos
     * @param {number} level
     * @returns {KintTimelineBlock[]}
     * @memberof KintTimelineBlock
     */
    KintTimelineBlocksBuilder.buildDecadeBlocks = function (startDate, endDate, sortedPhotos, timelineFormat, level) {
        var generatedTimlineBlocks = [];
        var decadeStart = Math.floor(startDate.getFullYear() / 10) * 10;
        var decadeEnd = decadeStart + 10;
        while (decadeStart <= endDate.getFullYear()) {
            // Build a new Decade block type with all of our photos within this time span
            var decadeStartString = '' + decadeStart; // Need string value for the "Date" constructor
            var decadeEndString = '' + decadeEnd;
            // const decadeStartIsoDate = new Date(decadeStartString).toISOString();
            var decadeStartIsoDate = DateHelper.buildUnbiasZeroBasedISODateString(decadeStart, 0, 0);
            var decadeEndIsoDate = DateHelper.buildUnbiasZeroBasedISODateString(decadeEnd, 0, 0);
            var photosInDecade = this.getPhotosInTimeRange(decadeStartIsoDate, decadeEndIsoDate, sortedPhotos);
            if (photosInDecade.length > 0) {
                // Build the decade with these photos
                var header = '' + decadeStart + ' - ' + decadeEnd;
                console.log('Building decade: ' + header + ' with ' + photosInDecade.length + ' photos');
                if (decadeStart === 2000) {
                    console.log('breaking...');
                }
                var kintTimelineDecadeBlock = new KintTimelineBlock(header, timelineFormat, decadeStartIsoDate, decadeEndIsoDate, photosInDecade, level + 1);
                generatedTimlineBlocks.push(kintTimelineDecadeBlock);
            }
            // See if we have another one:
            decadeStart += 10;
            decadeEnd += 10;
        }
        return generatedTimlineBlocks;
    };
    /**
     * Build year-level blocks
     *
     * @param {Date} startDate
     * @param {Date} endDate
     * @param {KintPhoto[]} sortedPhotos
     * @param {number} level
     * @returns {KintTimelineBlock[]}
     * @memberof KintTimelineBlock
     */
    KintTimelineBlocksBuilder.buildYearBlocks = function (startDate, endDate, sortedPhotos, timelineFormat, level) {
        var generatedTimlineBlocks = [];
        var yearStart = startDate.getFullYear();
        var nextYear = yearStart + 1;
        while (yearStart <= endDate.getFullYear()) {
            // Build a new Year block type with all of our photos within this time span
            var yearStartIsoDate = DateHelper.buildUnbiasZeroBasedISODateString(yearStart, 0, 0); // new Date(yearStartString).toISOString();
            var yearEndIsoDate = DateHelper.buildUnbiasZeroBasedISODateString(nextYear, 0, 0); // new Date(nextYearString).toISOString();
            var photosInYear = this.getPhotosInTimeRange(yearStartIsoDate, yearEndIsoDate, sortedPhotos);
            if (photosInYear.length > 0) {
                // Build the Year with these photos
                var header = '' + yearStart;
                var kintTimelineDecadeBlock = new KintTimelineBlock(header, timelineFormat, yearStartIsoDate, yearEndIsoDate, photosInYear, level + 1);
                generatedTimlineBlocks.push(kintTimelineDecadeBlock);
            }
            // See if we have another year:
            yearStart += 1;
            nextYear += 1;
        }
        return generatedTimlineBlocks;
    };
    /**
     * Build month-level blocks
     *
     * @param {Date} startDate
     * @param {Date} endDate
     * @param {KintPhoto[]} sortedPhotos
     * @param {number} level
     * @returns {KintTimelineBlock[]}
     * @memberof KintTimelineBlock
     */
    KintTimelineBlocksBuilder.buildMonthBlocks = function (startDate, endDate, sortedPhotos, timelineFormat, level) {
        var generatedTimlineBlocks = [];
        var year = startDate.getFullYear();
        var monthStart = startDate.getMonth();
        var nextMonth = monthStart + 1;
        var endDateString = endDate.toISOString();
        var monthStartDateString = DateHelper.buildUnbiasZeroBasedISODateString(year, monthStart, 0);
        var monthEndDateString = DateHelper.buildUnbiasZeroBasedISODateString(year, monthStart + 1, 0);
        while (monthStartDateString <= endDateString) {
            // Build a new Decade block type with all of our photos within this time span
            var photosInMonth = this.getPhotosInTimeRange(monthStartDateString, monthEndDateString, sortedPhotos);
            if (photosInMonth.length > 0) {
                // Build the Month with these photos
                // Header is either "AUGUST" or "AUG"
                var header = '';
                if (level <= 3) {
                    // full month name
                    header = DateHelper.getLongMonth(new Date(monthStartDateString));
                }
                else {
                    // Abbreviated
                    header = DateHelper.getShortMonth(new Date(monthStartDateString));
                }
                var kintTimelineDecadeBlock = new KintTimelineBlock(header, timelineFormat, monthStartDateString, monthEndDateString, photosInMonth, level + 1);
                generatedTimlineBlocks.push(kintTimelineDecadeBlock);
            }
            // See if we have another year:
            monthStart += 1;
            nextMonth += 1;
            monthStartDateString = DateHelper.buildUnbiasZeroBasedISODateString(year, monthStart, 0);
            monthEndDateString = DateHelper.buildUnbiasZeroBasedISODateString(year, nextMonth, 0);
        }
        return generatedTimlineBlocks;
    };
    /**
     * Build week-level blocks
     *
     * @param {Date} startDate
     * @param {Date} endDate
     * @param {KintPhoto[]} sortedPhotos
     * @param {number} level
     * @returns {KintTimelineBlock[]}
     * @memberof KintTimelineBlock
     */
    KintTimelineBlocksBuilder.buildWeekBlocks = function (startDate, endDate, sortedPhotos, timelineFormat, level) {
        var generatedTimlineBlocks = [];
        var startIsoDate = startDate.toISOString();
        var endIsoDate = endDate.toISOString();
        var photosInMonth = this.getPhotosInTimeRange(startIsoDate, endIsoDate, sortedPhotos);
        if (photosInMonth.length > 0) {
            // Header is "AUG 20 - AUG 24, 2018"
            var startMonthName = DateHelper.getShortMonth(startDate);
            var endMonthName = DateHelper.getShortMonth(endDate);
            var header = startMonthName + ' ' + startDate.getDate() + ' - ' + endMonthName + ' ' + endDate.getDate();
            var kintTimelineDecadeBlock = new KintTimelineBlock(header, timelineFormat, startIsoDate, endIsoDate, photosInMonth, level + 1);
            generatedTimlineBlocks.push(kintTimelineDecadeBlock);
        }
        return generatedTimlineBlocks;
    };
    /**
     * Build month-level blocks
     *
     * @param {Date} startDate
     * @param {Date} endDate
     * @param {KintPhoto[]} sortedPhotos
     * @param {number} level
     * @returns {KintTimelineBlock[]}
     * @memberof KintTimelineBlock
     */
    KintTimelineBlocksBuilder.buildDayBlocks = function (startDate, endDate, sortedPhotos, timelineFormat, level) {
        var generatedTimlineBlocks = [];
        var currentDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
        while (currentDate <= endDate) {
            // Build a new Day block type with all of our photos within this time span
            var dayStartIsoDate = currentDate.toISOString();
            var dayEndIsoDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59, 59, 999).toISOString();
            var photosInMonth = this.getPhotosInTimeRange(dayStartIsoDate, dayEndIsoDate, sortedPhotos);
            if (photosInMonth.length > 0) {
                // Build the Month with these photos
                // Header is either "AUGUST" or "AUG"
                var header = '';
                if (level <= 3) {
                    // full month name
                    header = DateHelper.getLongMonth(currentDate);
                }
                else {
                    // Abbreviated
                    header = DateHelper.getShortMonth(currentDate);
                }
                header = header + ' ' + currentDate.getDate();
                var kintTimelineDecadeBlock = new KintTimelineBlock(header, timelineFormat, dayStartIsoDate, dayEndIsoDate, photosInMonth, level + 1);
                generatedTimlineBlocks.push(kintTimelineDecadeBlock);
            }
            // See if we have another day
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return generatedTimlineBlocks;
    };
    /**
     * Build month-level blocks
     *
     * @param {Date} startDate
     * @param {Date} endDate
     * @param {KintPhoto[]} sortedPhotos
     * @param {number} level
     * @returns {KintTimelineBlock[]}
     * @memberof KintTimelineBlock
     */
    KintTimelineBlocksBuilder.buildTimeOfDayBlocks = function (startDate, endDate, sortedPhotos, timelineFormat, level) {
        var generatedTimlineBlocks = [];
        //
        // Build fixed time-of-day blocks:
        //   - EARLY MORNING (12am-5am)
        //   - MORNING (5:01am-10am)
        //   - MID_MORNING (10:01am-12pm)
        //   - AFTERNOON (12:01pm-5pm)
        //   - EVENING (5:01pm-10pm)
        //   - TWILIGHT (10:01pm-11:59pm)
        //
        // Early Morning
        var timeOfDayStart = DateHelper.getTimeOnDate(startDate, 0, 0, false);
        var timeOfDayEnd = DateHelper.getTimeOnDate(startDate, 5, 0, true);
        var photosInTimeOfDay = KintTimelineBlocksBuilder.getPhotosInTimeRange(timeOfDayStart.toISOString(), timeOfDayEnd.toISOString(), sortedPhotos);
        if (photosInTimeOfDay.length > 0) {
            var kintTimelineDecadeBlock = new KintTimelineBlock('Early Morning', timelineFormat, timeOfDayStart.toISOString(), timeOfDayEnd.toISOString(), photosInTimeOfDay, level + 1);
            generatedTimlineBlocks.push(kintTimelineDecadeBlock);
        }
        // Morning
        timeOfDayStart = DateHelper.getTimeOnDate(startDate, 5, 1, false);
        timeOfDayEnd = DateHelper.getTimeOnDate(startDate, 10, 0, true);
        photosInTimeOfDay = KintTimelineBlocksBuilder.getPhotosInTimeRange(timeOfDayStart.toISOString(), timeOfDayEnd.toISOString(), sortedPhotos);
        if (photosInTimeOfDay.length > 0) {
            var kintTimelineDecadeBlock = new KintTimelineBlock('Morning', timelineFormat, timeOfDayStart.toISOString(), timeOfDayEnd.toISOString(), photosInTimeOfDay, level + 1);
            generatedTimlineBlocks.push(kintTimelineDecadeBlock);
        }
        // MID_MORNING
        timeOfDayStart = DateHelper.getTimeOnDate(startDate, 10, 1, false);
        timeOfDayEnd = DateHelper.getTimeOnDate(startDate, 12, 0, true);
        photosInTimeOfDay = KintTimelineBlocksBuilder.getPhotosInTimeRange(timeOfDayStart.toISOString(), timeOfDayEnd.toISOString(), sortedPhotos);
        if (photosInTimeOfDay.length > 0) {
            var kintTimelineDecadeBlock = new KintTimelineBlock('Mid Morning', timelineFormat, timeOfDayStart.toISOString(), timeOfDayEnd.toISOString(), photosInTimeOfDay, level + 1);
            generatedTimlineBlocks.push(kintTimelineDecadeBlock);
        }
        // AFTERNOON
        timeOfDayStart = DateHelper.getTimeOnDate(startDate, 12, 1, false);
        timeOfDayEnd = DateHelper.getTimeOnDate(startDate, 17, 0, true);
        photosInTimeOfDay = KintTimelineBlocksBuilder.getPhotosInTimeRange(timeOfDayStart.toISOString(), timeOfDayEnd.toISOString(), sortedPhotos);
        if (photosInTimeOfDay.length > 0) {
            var kintTimelineDecadeBlock = new KintTimelineBlock('Afternoon', timelineFormat, timeOfDayStart.toISOString(), timeOfDayEnd.toISOString(), photosInTimeOfDay, level + 1);
            generatedTimlineBlocks.push(kintTimelineDecadeBlock);
        }
        // Evening
        timeOfDayStart = DateHelper.getTimeOnDate(startDate, 17, 1, false);
        timeOfDayEnd = DateHelper.getTimeOnDate(startDate, 22, 0, true);
        photosInTimeOfDay = KintTimelineBlocksBuilder.getPhotosInTimeRange(timeOfDayStart.toISOString(), timeOfDayEnd.toISOString(), sortedPhotos);
        if (photosInTimeOfDay.length > 0) {
            var kintTimelineDecadeBlock = new KintTimelineBlock('Evening', timelineFormat, timeOfDayStart.toISOString(), timeOfDayEnd.toISOString(), photosInTimeOfDay, level + 1);
            generatedTimlineBlocks.push(kintTimelineDecadeBlock);
        }
        // TWILIGHT
        timeOfDayStart = DateHelper.getTimeOnDate(startDate, 22, 1, false);
        timeOfDayEnd = DateHelper.getTimeOnDate(startDate, 23, 59, true);
        photosInTimeOfDay = KintTimelineBlocksBuilder.getPhotosInTimeRange(timeOfDayStart.toISOString(), timeOfDayEnd.toISOString(), sortedPhotos);
        if (photosInTimeOfDay.length > 0) {
            var kintTimelineDecadeBlock = new KintTimelineBlock('Twighlight', timelineFormat, timeOfDayStart.toISOString(), timeOfDayEnd.toISOString(), photosInTimeOfDay, level + 1);
            generatedTimlineBlocks.push(kintTimelineDecadeBlock);
        }
        return generatedTimlineBlocks;
    };
    /**
     * Get photos within the current date range
     *
     * @param {string} startIsoDate
     * @param {string} endIsoDate
     * @param {KintPhoto[]} sortedPhotos
     * @returns
     * @memberof KintTimelineBlock
     */
    KintTimelineBlocksBuilder.getPhotosInTimeRange = function (onOrAfterStartDateIsoString, beforeEndDateIsoString, sortedPhotos) {
        var photosInRange = [];
        var includeEndDate = false;
        sortedPhotos.forEach(function (photo) {
            if (DateHelper.isPhotoWithinRange(photo, onOrAfterStartDateIsoString, beforeEndDateIsoString, includeEndDate)) {
                photosInRange.push(photo);
            }
        });
        return photosInRange;
    };
    // Static Members
    KintTimelineBlocksBuilder.ONE_DAY = 24 * 60 * 60 * 1000;
    KintTimelineBlocksBuilder.FIVE_DAYS = KintTimelineBlocksBuilder.ONE_DAY * 5;
    KintTimelineBlocksBuilder.ONE_WEEK = KintTimelineBlocksBuilder.ONE_DAY * 7;
    KintTimelineBlocksBuilder.ONE_MONTH = KintTimelineBlocksBuilder.ONE_DAY * 30;
    KintTimelineBlocksBuilder.ONE_YEAR = 365 * KintTimelineBlocksBuilder.ONE_DAY;
    return KintTimelineBlocksBuilder;
}());
export { KintTimelineBlocksBuilder };
var KintTimelineFormat = /** @class */ (function () {
    function KintTimelineFormat(timelineType) {
        this.timelineType = timelineType;
        this.lowestLevelIsTime = false;
        switch (this.timelineType) {
            case KintTimelineType.DECADE_YEAR_DAY: {
                this.timelineBlockTypes = [
                    KintTimelineBlockType.DECADE,
                    KintTimelineBlockType.YEAR
                ];
                this.lowestLevelIsTime = false;
                break;
            }
            case KintTimelineType.YEAR_MONTH_DAY: {
                this.timelineBlockTypes = [
                    KintTimelineBlockType.YEAR,
                    KintTimelineBlockType.MONTH
                ];
                this.lowestLevelIsTime = false;
                break;
            }
            case KintTimelineType.MONTH_DAY_TIME: {
                this.timelineBlockTypes = [
                    KintTimelineBlockType.MONTH,
                    KintTimelineBlockType.DAY
                ];
                this.lowestLevelIsTime = false;
                break;
            }
            case KintTimelineType.WEEK_DAY_TIME: {
                this.timelineBlockTypes = [
                    KintTimelineBlockType.WEEK,
                    KintTimelineBlockType.DAY
                ];
                this.lowestLevelIsTime = false;
                break;
            }
            case KintTimelineType.DAY_TIMEOFDAY_TIME: {
                this.timelineBlockTypes = [
                    KintTimelineBlockType.DAY,
                    KintTimelineBlockType.TIME_OF_DAY
                ];
                this.lowestLevelIsTime = true;
                break;
            }
            default: {
                console.error('Unknown timeline type: ' + this.timelineType);
                this.timelineBlockTypes = [
                    KintTimelineBlockType.DAY,
                    KintTimelineBlockType.TIME_OF_DAY
                ];
                this.lowestLevelIsTime = true;
                break;
            }
        }
    }
    return KintTimelineFormat;
}());
export { KintTimelineFormat };
export var KintTimelineType;
(function (KintTimelineType) {
    KintTimelineType["DECADE_YEAR_DAY"] = "DECADE_YEAR_DAY";
    KintTimelineType["YEAR_MONTH_DAY"] = "YEAR_MONTH_DAY";
    KintTimelineType["MONTH_DAY_TIME"] = "MONTH_DAY_TIME";
    KintTimelineType["WEEK_DAY_TIME"] = "WEEK_DAY_TIME";
    KintTimelineType["DAY_TIMEOFDAY_TIME"] = "DAY_TIMEOFDAY_TIME";
})(KintTimelineType || (KintTimelineType = {}));
