/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./timeline-entry-horizontal.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../Universal/timeline-supplement/timeline-supplement.component.ngfactory";
import * as i3 from "../../Universal/timeline-supplement/timeline-supplement.component";
import * as i4 from "@angular/common";
import * as i5 from "../../../Photo/photo-display.directive";
import * as i6 from "../../../Log/log.service";
import * as i7 from "../../../Photo/photo-selection.service";
import * as i8 from "./timeline-entry-horizontal.component";
var styles_TimelineEntryHorizontalComponent = [i0.styles];
var RenderType_TimelineEntryHorizontalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TimelineEntryHorizontalComponent, data: {} });
export { RenderType_TimelineEntryHorizontalComponent as RenderType_TimelineEntryHorizontalComponent };
function View_TimelineEntryHorizontalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-timeline-supplement", [], null, null, null, i2.View_TimelineSupplementComponent_0, i2.RenderType_TimelineSupplementComponent)), i1.ɵdid(2, 114688, null, 0, i3.TimelineSupplementComponent, [], { supplement: [0, "supplement"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
function View_TimelineEntryHorizontalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "timeline-entry-horizontal-supplements"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimelineEntryHorizontalComponent_2)), i1.ɵdid(2, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.timelineEntry.supplements; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_TimelineEntryHorizontalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "timeline-entry-horizontal"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "timeline-entry-horizontal-label"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "timeline-entry-horizontal-label-top"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "timeline-entry-horizontal-label-bottom"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), (_l()(), i1.ɵeld(6, 0, null, null, 5, "div", [["class", "timeline-entry-horizontal-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 2, "div", [["class", "timeline-entry-horizontal-photo"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 1, "div", [["appPhotoDisplay", ""], ["class", "timeline-entry-horizontal-photo-img"]], null, [[null, "click"], ["window", "resize"], [null, "load"], [null, "error"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onResize($event) !== false);
        ad = (pd_0 && ad);
    } if (("load" === en)) {
        var pd_1 = (i1.ɵnov(_v, 9).onImageLoaded($event) !== false);
        ad = (pd_1 && ad);
    } if (("error" === en)) {
        var pd_2 = (i1.ɵnov(_v, 9).onImageLoadError($event) !== false);
        ad = (pd_2 && ad);
    } if (("click" === en)) {
        var pd_3 = (_co.openPhotoPreview() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 344064, null, 0, i5.PhotoDisplayDirective, [i1.ElementRef, i6.LogService, i7.PhotoSelectionService], { photoData: [0, "photoData"], isBackgroundImage: [1, "isBackgroundImage"], sizeToHeight: [2, "sizeToHeight"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimelineEntryHorizontalComponent_1)), i1.ɵdid(11, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.timelineEntry.photo; var currVal_3 = true; var currVal_4 = true; _ck(_v, 9, 0, currVal_2, currVal_3, currVal_4); var currVal_5 = (_co.timelineEntry.supplements.length > 0); _ck(_v, 11, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.labelTop; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.labelBottom; _ck(_v, 5, 0, currVal_1); }); }
export function View_TimelineEntryHorizontalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-timeline-entry-horizontal", [], null, null, null, View_TimelineEntryHorizontalComponent_0, RenderType_TimelineEntryHorizontalComponent)), i1.ɵdid(1, 114688, null, 0, i8.TimelineEntryHorizontalComponent, ["ModalService"], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TimelineEntryHorizontalComponentNgFactory = i1.ɵccf("app-timeline-entry-horizontal", i8.TimelineEntryHorizontalComponent, View_TimelineEntryHorizontalComponent_Host_0, { timelineEntry: "timelineEntry", timeline: "timeline" }, {}, []);
export { TimelineEntryHorizontalComponentNgFactory as TimelineEntryHorizontalComponentNgFactory };
