/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./timeline-entry-vertical.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../Universal/timeline-supplement/timeline-supplement.component.ngfactory";
import * as i3 from "../../Universal/timeline-supplement/timeline-supplement.component";
import * as i4 from "@angular/common";
import * as i5 from "../../../Photo/photo-display.directive";
import * as i6 from "../../../Log/log.service";
import * as i7 from "../../../Photo/photo-selection.service";
import * as i8 from "./timeline-entry-vertical.component";
var styles_TimelineEntryVerticalComponent = [i0.styles];
var RenderType_TimelineEntryVerticalComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TimelineEntryVerticalComponent, data: {} });
export { RenderType_TimelineEntryVerticalComponent as RenderType_TimelineEntryVerticalComponent };
function View_TimelineEntryVerticalComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-timeline-supplement", [], null, null, null, i2.View_TimelineSupplementComponent_0, i2.RenderType_TimelineSupplementComponent)), i1.ɵdid(2, 114688, null, 0, i3.TimelineSupplementComponent, [], { supplement: [0, "supplement"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 2, 0, currVal_0); }, null); }
function View_TimelineEntryVerticalComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "timeline-entry-vertical-supplements"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i4.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "timeline-entry-vertical-supplements-mobile": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimelineEntryVerticalComponent_2)), i1.ɵdid(4, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "timeline-entry-vertical-supplements"; var currVal_1 = _ck(_v, 2, 0, _co.isMobile()); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.timelineEntry.supplements; _ck(_v, 4, 0, currVal_2); }, null); }
export function View_TimelineEntryVerticalComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "div", [["class", "timeline-entry-vertical"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "timeline-entry-vertical-label"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "timeline-entry-vertical-label-top"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, [" ", " "])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "timeline-entry-vertical-label-bottom"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), (_l()(), i1.ɵeld(6, 0, null, null, 10, "div", [["class", "timeline-entry-vertical-content"]], null, null, null, null, null)), i1.ɵdid(7, 278528, null, 0, i4.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(8, { "timeline-entry-vertical-content-mobile": 0 }), (_l()(), i1.ɵeld(9, 0, null, null, 5, "div", [["class", "timeline-entry-vertical-photo"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openPhotoPreview() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 278528, null, 0, i4.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(11, { "timeline-entry-vertical-photo-split": 0, "timeline-entry-vertical-photo-split-mobile": 1 }), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "timeline-entry-vertical-img-holder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "img", [["appPhotoDisplay", ""], ["class", "timeline-entry-vertical-photo-img"]], null, [["window", "resize"], [null, "load"], [null, "error"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 14).onResize($event) !== false);
        ad = (pd_0 && ad);
    } if (("load" === en)) {
        var pd_1 = (i1.ɵnov(_v, 14).onImageLoaded($event) !== false);
        ad = (pd_1 && ad);
    } if (("error" === en)) {
        var pd_2 = (i1.ɵnov(_v, 14).onImageLoadError($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(14, 344064, null, 0, i5.PhotoDisplayDirective, [i1.ElementRef, i6.LogService, i7.PhotoSelectionService], { photoData: [0, "photoData"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TimelineEntryVerticalComponent_1)), i1.ɵdid(16, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "timeline-entry-vertical-content"; var currVal_3 = _ck(_v, 8, 0, _co.isMobile()); _ck(_v, 7, 0, currVal_2, currVal_3); var currVal_4 = "timeline-entry-vertical-photo"; var currVal_5 = _ck(_v, 11, 0, (_co.timelineEntry.supplements.length > 0), _co.isMobile()); _ck(_v, 10, 0, currVal_4, currVal_5); var currVal_6 = _co.timelineEntry.photo; _ck(_v, 14, 0, currVal_6); var currVal_7 = (_co.timelineEntry.supplements.length > 0); _ck(_v, 16, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.labelTop; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.labelBottom; _ck(_v, 5, 0, currVal_1); }); }
export function View_TimelineEntryVerticalComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-timeline-entry-vertical", [], null, null, null, View_TimelineEntryVerticalComponent_0, RenderType_TimelineEntryVerticalComponent)), i1.ɵdid(1, 114688, null, 0, i8.TimelineEntryVerticalComponent, ["AppDataModelService", "ModalService"], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TimelineEntryVerticalComponentNgFactory = i1.ɵccf("app-timeline-entry-vertical", i8.TimelineEntryVerticalComponent, View_TimelineEntryVerticalComponent_Host_0, { timelineEntry: "timelineEntry", timeline: "timeline" }, {}, []);
export { TimelineEntryVerticalComponentNgFactory as TimelineEntryVerticalComponentNgFactory };
