import { OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { PersonalPageEditor } from '../personal-page-editor';
var PersonalPageContentComponent = /** @class */ (function () {
    function PersonalPageContentComponent() {
    }
    PersonalPageContentComponent.prototype.ngOnInit = function () {
    };
    return PersonalPageContentComponent;
}());
export { PersonalPageContentComponent };
// Provide a downgraded version of this to run with AngularJS
angular.module('KintributeApp')
    .directive('appPersonalPageContent', downgradeComponent({
    component: PersonalPageContentComponent,
    inputs: ['page', 'editor']
}));
