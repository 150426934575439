/**
 * Generated bundle index. Do not edit.
 */

export * from './index';

export {APPLICATION_MODULE_PROVIDERS as Éµangular_packages_core_core_l,_iterableDiffersFactory as Éµangular_packages_core_core_i,_keyValueDiffersFactory as Éµangular_packages_core_core_j,_localeFactory as Éµangular_packages_core_core_k} from './src/application_module';
export {_appIdRandomProviderFactory as Éµangular_packages_core_core_f} from './src/application_tokens';
export {DefaultIterableDifferFactory as Éµangular_packages_core_core_g} from './src/change_detection/differs/default_iterable_differ';
export {DefaultKeyValueDifferFactory as Éµangular_packages_core_core_h} from './src/change_detection/differs/default_keyvalue_differ';
export {ReflectiveInjector_ as Éµangular_packages_core_core_c} from './src/di/reflective_injector';
export {ReflectiveDependency as Éµangular_packages_core_core_d,resolveReflectiveProviders as Éµangular_packages_core_core_e} from './src/di/reflective_provider';
export {wtfEnabled as Éµangular_packages_core_core_m} from './src/profile/profile';
export {createScope as Éµangular_packages_core_core_o,detectWTF as Éµangular_packages_core_core_n,endTimeRange as Éµangular_packages_core_core_r,leave as Éµangular_packages_core_core_p,startTimeRange as Éµangular_packages_core_core_q} from './src/profile/wtf_impl';
export {getOrCreateChangeDetectorRef as Éµangular_packages_core_core_v,getOrCreateContainerRef as Éµangular_packages_core_core_y,getOrCreateElementRef as Éµangular_packages_core_core_x,getOrCreateInjectable as Éµangular_packages_core_core_w,getOrCreateNodeInjector as Éµangular_packages_core_core_u,getOrCreateTemplateRef as Éµangular_packages_core_core_z} from './src/render3/di';
export {bindingUpdated as Éµangular_packages_core_core_ba} from './src/render3/instructions';
export {loadInternal as Éµangular_packages_core_core_bb} from './src/render3/util';
export {makeParamDecorator as Éµangular_packages_core_core_a,makePropDecorator as Éµangular_packages_core_core_b} from './src/util/decorators';
export {_def as Éµangular_packages_core_core_s} from './src/view/provider';
export {DebugContext as Éµangular_packages_core_core_t} from './src/view/types';