/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tag-editor.component.css.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/material/tooltip";
import * as i3 from "@angular/cdk/overlay";
import * as i4 from "@angular/cdk/scrolling";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/cdk/a11y";
import * as i7 from "@angular/cdk/bidi";
import * as i8 from "@angular/common";
import * as i9 from "@angular/forms";
import * as i10 from "./tag-editor.component";
import * as i11 from "../../../Util/util/util.service";
import * as i12 from "../../../MessageBox/message-box.service";
import * as i13 from "../../photo.service";
var styles_TagEditorComponent = [i0.styles];
var RenderType_TagEditorComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_TagEditorComponent, data: {} });
export { RenderType_TagEditorComponent as RenderType_TagEditorComponent };
function View_TagEditorComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "tag-editor-tag-bubble"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "tag-editor-tab-bubble-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 16777216, null, null, 2, "div", [["class", "tag-editor-bubble-content"]], null, [[null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 3)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 147456, null, 0, i2.MatTooltip, [i3.Overlay, i1.ElementRef, i4.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i5.Platform, i6.AriaDescriber, i6.FocusMonitor, i2.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i7.Directionality], [2, i2.MAT_TOOLTIP_DEFAULT_OPTIONS]], { message: [0, "message"] }, null), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "tag-editor-bubble-content glyphicon glyphicon-remove"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeKeyword(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit, ""); _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.context.$implicit; _ck(_v, 4, 0, currVal_1); }); }
function View_TagEditorComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "tag-editor-tag-bubble"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [["class", "tag-editor-tab-bubble-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 16777216, null, null, 2, "div", [["class", "tag-editor-bubble-content"]], null, [[null, "longpress"], [null, "keydown"], [null, "touchend"]], function (_v, en, $event) { var ad = true; if (("longpress" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).show() !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchend" === en)) {
        var pd_2 = (i1.ɵnov(_v, 3)._handleTouchend() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 147456, null, 0, i2.MatTooltip, [i3.Overlay, i1.ElementRef, i4.ScrollDispatcher, i1.ViewContainerRef, i1.NgZone, i5.Platform, i6.AriaDescriber, i6.FocusMonitor, i2.MAT_TOOLTIP_SCROLL_STRATEGY, [2, i7.Directionality], [2, i2.MAT_TOOLTIP_DEFAULT_OPTIONS]], { message: [0, "message"] }, null), (_l()(), i1.ɵted(4, null, [" ", " "])), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "tag-editor-bubble-content glyphicon glyphicon-remove"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeAutoTag(_v.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", _v.parent.context.$implicit.name, ""); _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = _v.parent.context.$implicit.name; _ck(_v, 4, 0, currVal_1); }); }
function View_TagEditorComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TagEditorComponent_3)), i1.ɵdid(2, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.display; _ck(_v, 2, 0, currVal_0); }, null); }
function View_TagEditorComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "tags-editor-add-spacer"]], null, null, null, null, null))], null, null); }
function View_TagEditorComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, "input", [["autofocus", ""], ["class", "kint-edit-field-text kint-edit-field"], ["id", "tag-editor-new-tag-field"], ["placeholder", "high school, picnic, etc"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "blur"], [null, "keydown"], [null, "input"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 2)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("ngModelChange" === en)) {
        var pd_4 = ((_co.newTagText = $event) !== false);
        ad = (pd_4 && ad);
    } if (("blur" === en)) {
        var pd_5 = (_co.finishNewTag() !== false);
        ad = (pd_5 && ad);
    } if (("keydown" === en)) {
        var pd_6 = (_co.onKeydown($event) !== false);
        ad = (pd_6 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i9.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i9.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵprd(1024, null, i9.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i9.DefaultValueAccessor]), i1.ɵdid(4, 671744, null, 0, i9.NgModel, [[8, null], [8, null], [8, null], [6, i9.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i9.NgControl, null, [i9.NgModel]), i1.ɵdid(6, 16384, null, 0, i9.NgControlStatus, [[4, i9.NgControl]], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.newTagText; _ck(_v, 4, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 6).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 6).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 6).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 6).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 6).ngClassValid; var currVal_5 = i1.ɵnov(_v, 6).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 6).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_TagEditorComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "kint-edit-field"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "kint-edit-field-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Tags "])), (_l()(), i1.ɵeld(3, 0, null, null, 12, "div", [["class", "kint-edit-field-data"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 11, "div", [["class", "tag-editor-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "tag-editor-tags-holder"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TagEditorComponent_1)), i1.ɵdid(7, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TagEditorComponent_2)), i1.ɵdid(9, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TagEditorComponent_4)), i1.ɵdid(11, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TagEditorComponent_5)), i1.ɵdid(13, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(14, 0, null, null, 1, "div", [["class", "kint-link-text"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addTag() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" +Add Tags "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.photo.keywords; _ck(_v, 7, 0, currVal_0); var currVal_1 = _co.photo.autoTags; _ck(_v, 9, 0, currVal_1); var currVal_2 = _co.hasTags(); _ck(_v, 11, 0, currVal_2); var currVal_3 = (_co.newTagText !== null); _ck(_v, 13, 0, currVal_3); }, null); }
export function View_TagEditorComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tag-editor", [], null, null, null, View_TagEditorComponent_0, RenderType_TagEditorComponent)), i1.ɵdid(1, 114688, null, 0, i10.TagEditorComponent, [i11.UtilService, i12.MessageBox, i13.PhotoService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TagEditorComponentNgFactory = i1.ɵccf("app-tag-editor", i10.TagEditorComponent, View_TagEditorComponent_Host_0, { photo: "photo", shouldFocus: "shouldFocus" }, {}, []);
export { TagEditorComponentNgFactory as TagEditorComponentNgFactory };
