<div class="timeline-entry-vertical">

  <!-- 
  {{timelineEntry.photo}}
  {{timelineEntry.photo.link}}
  -->

  <!-- Date/Time on the side -->
  <div class="timeline-entry-vertical-label">
    <div class="timeline-entry-vertical-label-top">
      {{labelTop}}
    </div>
    <div class="timeline-entry-vertical-label-bottom">
      {{labelBottom}}
    </div>
  </div>


  <div class="timeline-entry-vertical-content"
       [ngClass]="{'timeline-entry-vertical-content-mobile': isMobile()}">

    <!-- Photo on the left -->
    <div class="timeline-entry-vertical-photo"
         [ngClass]="{'timeline-entry-vertical-photo-split': timelineEntry.supplements.length > 0,
                     'timeline-entry-vertical-photo-split-mobile': isMobile()}"
         (click)="openPhotoPreview()">
      <div class="timeline-entry-vertical-img-holder">
        <img class="timeline-entry-vertical-photo-img"
             appPhotoDisplay
             [photoData]="timelineEntry.photo">
      </div>
    </div>

    <!-- Supplemental data on the right -->
    <div class="timeline-entry-vertical-supplements" *ngIf="timelineEntry.supplements.length > 0"
         [ngClass]="{'timeline-entry-vertical-supplements-mobile': isMobile()}">
      <div *ngFor="let supplement of timelineEntry.supplements">
        <app-timeline-supplement [supplement]="supplement">
        </app-timeline-supplement>
      </div>
    </div>
    
  </div>

</div>