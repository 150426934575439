import { Component, OnInit, Input, DoCheck, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { PersonalPageHeader } from 'src/app/KintObject/kint-personal-page';
import { PersonalPageEditor } from '../personal-page-editor';
import { ViewCheckHelper } from 'src/app/Util/viewCheck/view-check-helper';

// Angular is loaded separately
declare var angular: any;

@Component({
  selector: 'app-personal-page-header',
  templateUrl: './personal-page-header.component.html',
  styleUrls: ['./personal-page-header.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PersonalPageHeaderComponent extends ViewCheckHelper implements OnInit, DoCheck {

  /**
   * Inputs
   */
  @Input('header') header: PersonalPageHeader;
  @Input('isMobile') isMobile: boolean;
  @Input('editor') editor: PersonalPageEditor;

  @ViewChild('headlineField') headlineField: ElementRef;
  @ViewChild('sublineField') sublineField: ElementRef;

  /**
   * Constructor
   *
   * @param appDataModelService
   */
  constructor(private elementRef: ElementRef) {
    super();
  }


  /**
   * Private Members
   */
  private image1IsActive = true;
  private image2IsActive = false;

  private IMAGE_CYCLE_DURATION = 4250;
  private IMAGE_ANIMATION_DURATION = 1100;
  public image1 = null;
  public image2 = null;
  private currentPhotoIndex = 0;
  private nextPhotoIndex = 0;
  private loaded = false;


  /**
   * Initialize the component
   */
  ngOnInit() {
    if (this.header) {
      this.loaded = true;
      this.setupInitialImages();
    } else {
      // Wait for it to get ready and init later
    }
  }


  /**
   * Set up the initial images
   */
  setupInitialImages(): void {

    if (this.header) {
      // Set up initial images
      if (this.header.photos.length > 0) {
        this.image1 = this.header.photos[0];
      }

      if (this.header.photos.length > 1) {
        this.nextPhotoIndex = 1;
        this.image2 = this.header.photos[1];
      }

      this.cycleImages();
    }
  }

  userCanEdit(): boolean {
    if (this.editor && this.editor.userCanEdit()) {
      return true;
    }
    return false;
  }


  openSettingsDialog(): void {
   console.log('Open Settings Dialog');
  }


  ngDoCheck() {
    if (!this.loaded) {
      if (this.header) {
        this.loaded = true;
      }
    }
  }


  /**
   * Cycle to the next image (after a duration)
   */
  cycleImages(): void {

    // After the old image has transitioned out,
    // load the next image
    setTimeout(() => {
      this.preloadNextImage();
    }, this.IMAGE_ANIMATION_DURATION);

    // Swap out to the new image
    setTimeout(() => {
      this.moveNextImage();
    }, this.IMAGE_CYCLE_DURATION);
  }


  /**
   * Pre-load the next image in the list
   */
  preloadNextImage(): void {
    // Pre-load the image which is NOT active
    if (this.image1IsActive) {
      this.image2 = this.header.photos[this.nextPhotoIndex];
    } else {
      this.image1 = this.header.photos[this.nextPhotoIndex];
    }
  }


  /**
   * Move to the next image in the list
   */
  moveNextImage(): void {

    this.image1IsActive = !this.image1IsActive;
    this.image2IsActive = !this.image2IsActive;

    this.currentPhotoIndex++;
    if (this.currentPhotoIndex >= this.header.photos.length) {
      this.currentPhotoIndex = 0;
    }

    this.nextPhotoIndex = this.currentPhotoIndex + 1;
    if (this.nextPhotoIndex >= this.header.photos.length) {
      this.nextPhotoIndex = 0;
    }

    this.cycleImages();
  }

}


// Provide a downgraded version of this to run with AngularJS
angular.module('KintributeApp')
  .directive('appPersonalPageHeader', downgradeComponent(
    {
      component: PersonalPageHeaderComponent,
      inputs: ['header']
    }
  ));
