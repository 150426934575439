import { Component, OnInit, Input, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';

// Angular is loaded separately
declare var angular: any;

@Component({
  selector: 'app-save-bar',
  templateUrl: './save-bar.component.html',
  styleUrls: ['./save-bar.component.css']
})
export class SaveBarComponent implements OnInit {

  @Input('isSaving') isSaving = false;
  @Output() saveHandler = new EventEmitter<any>();
  @Output() cancelHandler = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
    console.log('SaveBarComponent.isSaving: ' + this.isSaving);
  }

  cancel(): void {
    this.cancelHandler.emit('canceling');
  }

  save(): void {
    this.saveHandler.emit('saving');
  }

}


// Provide a downgraded version of this to run with AngularJS
angular.module('KintributeApp')
  .directive('appSaveBar', downgradeComponent(
    {
      component: SaveBarComponent,
      inputs: ['isSaving'],
      outputs: ['saveHandler', 'cancelHandler']
    }
  ));



