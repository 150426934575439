import { Component, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { PhotoSampleServiceService } from '../photo-sample-service.service';
import { KintTimeline } from 'src/app/Timeline/kint-timeline';
import { KintCollection } from 'src/app/KintObject/kint-collection';
import { KintTimelineEntry } from 'src/app/Timeline/kint-timeline-entry';

// Angular is loaded separately
declare var angular: any;

@Component({
  selector: 'app-kint-ng2-samples',
  templateUrl: './kint-ng2-samples.component.html',
  styleUrls: ['./kint-ng2-samples.component.css']
})
export class KintNg2SamplesComponent implements OnInit {

  photos: any[];
  timelineEntries: KintTimelineEntry[];
  timeline: KintTimeline;

  constructor(private photoSampleService: PhotoSampleServiceService) {
    this.photos = [];
    this.photos.push(photoSampleService.getVerticalPhoto1());
    this.photos.push(photoSampleService.getVerticalPhoto2());
    this.photos.push(photoSampleService.getHorizontalPhoto1());
    this.photos.push(photoSampleService.getHorizontalPhoto2());

    this.timelineEntries = [];

    // Timeline Entry #1
    const horizontalPhoto1 = photoSampleService.getHorizontalPhoto1();
    delete horizontalPhoto1.story;
    delete horizontalPhoto1.location;
    delete horizontalPhoto1.audioStories;
    delete horizontalPhoto1.externalLinks;
    const horizontalEntry1 = new KintTimelineEntry(horizontalPhoto1);
    this.timelineEntries.push(horizontalEntry1);


    // Timeline Entry #2
    const verticalPhoto1 = photoSampleService.getVerticalPhoto1();
    const verticlEntry1 = new KintTimelineEntry(verticalPhoto1);
    this.timelineEntries.push(verticlEntry1);


    // Timeline Entry #3
    const horizontalPhoto2 = photoSampleService.getHorizontalPhoto2();
    delete horizontalPhoto2.story;
    const horizontalEntry2 = new KintTimelineEntry(horizontalPhoto2);
    this.timelineEntries.push(horizontalEntry2);


    // Timeline Entry #4
    const verticalPhoto2 = photoSampleService.getVerticalPhoto2();
    delete verticalPhoto2.location;
    delete verticalPhoto2.audioStories;
    delete verticalPhoto2.externalLinks;
    const verticlEntry2 = new KintTimelineEntry(verticalPhoto2);
    this.timelineEntries.push(verticlEntry2);


    // Fake collection
    const timelineCollection = {
      name: 'Timeline Collection',
      photos: [
        horizontalPhoto1,
        verticalPhoto1,
        horizontalPhoto2,
        verticalPhoto2
      ]
    } as KintCollection;

    this.timeline = new KintTimeline(timelineCollection);
  }

  ngOnInit() {
  }

}

// Provide a downgraded version of this to run with AngularJS
angular.module('KintributeApp')
  .directive('kintNg2Samples', downgradeComponent(
    {
    component: KintNg2SamplesComponent
    }
  )
);
