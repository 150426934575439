/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./save-bar.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../ng1/General/kint-button-component";
import * as i3 from "./save-bar.component";
var styles_SaveBarComponent = [i0.styles];
var RenderType_SaveBarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SaveBarComponent, data: {} });
export { RenderType_SaveBarComponent as RenderType_SaveBarComponent };
export function View_SaveBarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "save-bar"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "save-bar-placeholder"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 8, "div", [["class", "save-bar-fixed"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "save-bar-spacer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 2, "div", [["class", "save-bar-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "kint-button", [["size", "LARGE"], ["text", "Cancel"], ["type", "DEFAULT"]], [[8, "hidden", 0]], [[null, "clickHandler"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickHandler" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 999424, null, 0, i2.KintButtonDirective, [i1.ElementRef, i1.Injector], { text: [0, "text"], type: [1, "type"], size: [2, "size"], disable: [3, "disable"] }, { clickHandler: "clickHandler" }), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [["class", "save-bar-button-spacer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "save-bar-section"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "kint-button", [["size", "LARGE"], ["text", "Save"], ["type", "PRIMARY"]], null, [[null, "clickHandler"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickHandler" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(10, 999424, null, 0, i2.KintButtonDirective, [i1.ElementRef, i1.Injector], { text: [0, "text"], type: [1, "type"], size: [2, "size"], spin: [3, "spin"], disable: [4, "disable"] }, { clickHandler: "clickHandler" })], function (_ck, _v) { var _co = _v.component; var currVal_1 = "Cancel"; var currVal_2 = "DEFAULT"; var currVal_3 = "LARGE"; var currVal_4 = _co.isSaving; _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3, currVal_4); var currVal_5 = "Save"; var currVal_6 = "PRIMARY"; var currVal_7 = "LARGE"; var currVal_8 = _co.isSaving; var currVal_9 = _co.isSaving; _ck(_v, 10, 0, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isSaving; _ck(_v, 5, 0, currVal_0); }); }
export function View_SaveBarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-save-bar", [], null, null, null, View_SaveBarComponent_0, RenderType_SaveBarComponent)), i1.ɵdid(1, 114688, null, 0, i3.SaveBarComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SaveBarComponentNgFactory = i1.ɵccf("app-save-bar", i3.SaveBarComponent, View_SaveBarComponent_Host_0, { isSaving: "isSaving" }, { saveHandler: "saveHandler", cancelHandler: "cancelHandler" }, []);
export { SaveBarComponentNgFactory as SaveBarComponentNgFactory };
