import { OnInit } from '@angular/core';
import { PersonalPageEditor } from '../personal-page-editor';
var PersonalPageRowComponent = /** @class */ (function () {
    function PersonalPageRowComponent() {
    }
    PersonalPageRowComponent.prototype.ngOnInit = function () {
    };
    return PersonalPageRowComponent;
}());
export { PersonalPageRowComponent };
