<div>

  <!--
  <div>
    Name: {{supplement.externalLink.text}}
  </div>

  <div>
    Link: {{supplement.externalLink.url}}
  </div>
  -->

  <p>
    <a href="{{supplement.externalLink.url}}" target=”_blank”>{{supplement.externalLink.text}}</a>
  </p>

</div>
