var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ElementRef, Injector } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
export var KintButtonType;
(function (KintButtonType) {
    KintButtonType["PRIMARY"] = "PRIMARY";
    KintButtonType["DANGER"] = "DANGER";
    KintButtonType["DEFAULT"] = "DEFAULT";
    KintButtonType["LINK"] = "LINK";
})(KintButtonType || (KintButtonType = {}));
export var KintButtonSize;
(function (KintButtonSize) {
    KintButtonSize["SMALL"] = "SMALL";
    KintButtonSize["LARGE"] = "LARGE";
    KintButtonSize["LARGE_WIDE"] = "LARGE_WIDE";
})(KintButtonSize || (KintButtonSize = {}));
var KintButtonDirective = /** @class */ (function (_super) {
    __extends(KintButtonDirective, _super);
    function KintButtonDirective(elementRef, injector) {
        var _this = this;
        console.log('kint-button constructor!');
        _this = _super.call(this, 'kintButton', elementRef, injector) || this;
        return _this;
    }
    return KintButtonDirective;
}(UpgradeComponent));
export { KintButtonDirective };
