import { Injectable, Inject } from '@angular/core';
import { PersonalPageEditor } from 'src/app/PersonalPage/personal-page-editor';
import { downgradeInjectable } from '@angular/upgrade/static';
import { KintLocation } from 'src/app/KintObject/kint-location';
import { KintTimeline } from 'src/app/Timeline/kint-timeline';
import { KintCollection } from 'src/app/KintObject/kint-collection';
import { Meta } from '@angular/platform-browser';
import { KintPhoto } from 'src/app/KintObject/kint-photo';

// Angular is loaded separately
declare var angular: any;

/*
 *************************************************************************************
 *
 * The AngularJS portion of this app doesn't have access to our typescript classes
 * In order to give them access, we expose this BuilderService which can be used
 * as a factory to build our Typescript objects
 *
 * This is purely used to build objects, and is only needed for the AngularJS interop
 *
 *************************************************************************************
 */


@Injectable({
  providedIn: 'root'
})
export class BuilderService {

  constructor(@Inject('APIService') private apiService: any,
             private meta: Meta) { }


  /**
   * Builds a Personal Page Editor
   */
  buildPersonalPageEditor(): PersonalPageEditor {
    const editor = new PersonalPageEditor();
    editor.setApiService(this.apiService);
    return editor;

  }


  /**
   * Builds a KintLocation object
   */
  buildKintLocation(name: string): KintLocation {
    return new KintLocation(name);
  }


  /**
   * Builds a KintTimeline from a Collection
   */
  buildKintTimeline(collection: KintCollection): KintTimeline {
    return new KintTimeline(collection);
  }


  addMetaTagsForPhoto(photo: KintPhoto, url: string): void {

    const title = 'Kintribute Photo';
    let description = '';
    let image = '';

    if (photo) {
      if (photo.story) {
        description = photo.story;
      }
      if (photo.link) {
        image = photo.link;
      }
    }
    this.addMetaTags(title, description, image, url);
  }


  addMetaTags(title: string, description: string, image: string, url: string): void {
    this.meta.addTag({ name: 'title', content: title });
    this.meta.addTag({ name: 'og:title', content: title });
    this.meta.addTag({ name: 'description', content: description });
    this.meta.addTag({ name: 'og:description', content: description });
    this.meta.addTag({ name: 'image', content: image });
    this.meta.addTag({ name: 'og:image', content: image });
    this.meta.addTag({ name: 'url', content: url });
    this.meta.addTag({ name: 'og:url', content: url });
  }

}


// Register an AngularJS service, whose value is the "downgraded" Angular injectable.
angular.module('KintributeApp')
  .factory('BuilderService', downgradeInjectable(BuilderService) as any);
