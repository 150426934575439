/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./photo-essay-row.component.css.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../ng1/PhotoEssay/photo-essay-tile.component";
import * as i4 from "../../ng1/PhotoEssay/photo-essay-add-row.component";
import * as i5 from "./photo-essay-row.component";
import * as i6 from "../../Log/log.service";
import * as i7 from "../../Photo/photo.service";
import * as i8 from "../photo-essay-layout/photo-essay-layout.service";
import * as i9 from "../../MessageBox/message-box.service";
var styles_PhotoEssayRowComponent = [i0.styles];
var RenderType_PhotoEssayRowComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_PhotoEssayRowComponent, data: {} });
export { RenderType_PhotoEssayRowComponent as RenderType_PhotoEssayRowComponent };
function View_PhotoEssayRowComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "photo-essay-row-edit-tools"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "photo-essay-row-edit-tools-show": 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["class", "photo-essay-row-delete"], ["src", "/img/trash.svg"], ["tabindex", ""]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.deleteRow() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "photo-essay-row-edit-tools"; var currVal_1 = _ck(_v, 2, 0, _co.hasFocus()); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_PhotoEssayRowComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "photo-essay-row-content-caption"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "photo-essay-tile", [], null, null, null, null, null)), i1.ɵdid(2, 999424, null, 0, i3.PhotoEssayTileDirective, [i1.ElementRef, i1.Injector], { editor: [0, "editor"], tile: [1, "tile"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.editor; var currVal_1 = _co.row.tiles[0]; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_PhotoEssayRowComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["style", "position:relative"]], [[4, "height", null], [4, "width", null]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.rowCss.height; var currVal_1 = _co.rowCss.width; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_PhotoEssayRowComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["style", "display:inline-block;"]], [[4, "position", null], [4, "top", null], [4, "bottom", null], [4, "left", null], [4, "right", null], [4, "width", null], [4, "height", null], [4, "margin", null], [4, "padding", null]], null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "photo-essay-tile", [], null, null, null, null, null)), i1.ɵdid(2, 999424, null, 0, i3.PhotoEssayTileDirective, [i1.ElementRef, i1.Injector], { editor: [0, "editor"], tile: [1, "tile"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_9 = _co.editor; var currVal_10 = _v.context.$implicit; _ck(_v, 2, 0, currVal_9, currVal_10); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.css.position; var currVal_1 = _v.context.$implicit.css.top; var currVal_2 = _v.context.$implicit.css.bottom; var currVal_3 = _v.context.$implicit.css.left; var currVal_4 = _v.context.$implicit.css.right; var currVal_5 = _v.context.$implicit.css.width; var currVal_6 = _v.context.$implicit.css.height; var currVal_7 = _v.context.$implicit.css.margin; var currVal_8 = _v.context.$implicit.css.padding; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }); }
function View_PhotoEssayRowComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "photo-essay-row-content-photos"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PhotoEssayRowComponent_5)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PhotoEssayRowComponent_6)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isCollage(); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.row.tiles; _ck(_v, 4, 0, currVal_1); }, null); }
function View_PhotoEssayRowComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "photo-essay-row-row"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "photo-essay-row-edit-mode": 0, "photo-essay-row-caption-row": 1, "photo-essay-row-edit-mode-focused": 2 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PhotoEssayRowComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PhotoEssayRowComponent_3)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PhotoEssayRowComponent_4)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "photo-essay-row-row"; var currVal_1 = _ck(_v, 2, 0, _co.editor.isEditMode, (_co.row.type === "CAPTION"), _co.hasFocus()); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.editor.isEditMode; _ck(_v, 4, 0, currVal_2); var currVal_3 = (_co.row.type == "CAPTION"); _ck(_v, 6, 0, currVal_3); var currVal_4 = (_co.row.type !== "CAPTION"); _ck(_v, 8, 0, currVal_4); }, null); }
function View_PhotoEssayRowComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "photo-essay-row-default-text-spacing"]], null, null, null, null, null))], null, null); }
function View_PhotoEssayRowComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "photo-essay-add-row", [], null, [[null, "addRowFunc"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("addRowFunc" === en)) {
        var pd_0 = (_co.addRow(_co.rowType) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 999424, null, 0, i4.PhotoEssayAddRowDirective, [i1.ElementRef, i1.Injector], { editor: [0, "editor"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.editor; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PhotoEssayRowComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_PhotoEssayRowComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PhotoEssayRowComponent_7)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PhotoEssayRowComponent_8)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.shouldShow(); _ck(_v, 1, 0, currVal_0); var currVal_1 = !_co.shouldShow(); _ck(_v, 3, 0, currVal_1); var currVal_2 = ((_co.row.type !== "CAPTION") && _co.editor.isEditMode); _ck(_v, 5, 0, currVal_2); }, null); }
export function View_PhotoEssayRowComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-photo-essay-row", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_PhotoEssayRowComponent_0, RenderType_PhotoEssayRowComponent)), i1.ɵdid(1, 376832, null, 0, i5.PhotoEssayRowComponent, [i1.ElementRef, i6.LogService, i7.PhotoService, i8.PhotoEssayLayoutService, i9.MessageBox, "AppDataModelService"], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PhotoEssayRowComponentNgFactory = i1.ɵccf("app-photo-essay-row", i5.PhotoEssayRowComponent, View_PhotoEssayRowComponent_Host_0, { row: "row", editor: "editor" }, {}, []);
export { PhotoEssayRowComponentNgFactory as PhotoEssayRowComponentNgFactory };
