<div class="tile-supplement-map">

  {{supplement.location.name}}

  <!--
  Map centered on: {{gpsCenter}}
  -->

  <!-- 
     "mapbox-gl": "0.53.1",
    "ngx-mapbox-gl": "^3.0.1",-->
  <!--
  <mgl-map
    [style]="'mapbox://styles/mapbox/streets-v9'"
    [zoom]="[9]"
    [center]="gpsCenter"
  >
  </mgl-map>
  -->
</div>


<!--

<!DOCTYPE html>
<html class='use-all-space'>
<head>
    <meta http-equiv="X-UA-Compatible" content="IE=Edge" />
    <meta charset='UTF-8'>
    <title>Maps SDK for Web - Map initialization</title>
    <meta name='viewport'
          content='width=device-width,initial-scale=1,maximum-scale=1,user-scalable=no'/>
    <link rel='stylesheet' type='text/css' href='sdk/map.css'/>
    <link rel='stylesheet' type='text/css' href='elements.css'>
    <script type='text/javascript' src='js/form.js'></script>
    <script src='sdk/tomtom.min.js'></script>
</head>
<body class='use-all-space'>
    <div id='map' class='use-all-space'></div>
    <script>
        // Define your product name and version
        tomtom.setProductInfo('<your-product-name>', '<your-product-version>');
        tomtom.L.map('map', {
            key: '<your-tomtom-API-key>'
        });
        // Alternatively following constructions are available:
        // tomtom.key('<your-tomtom-API-key>');
        // var map = L.map('map');
        // var map = tomtom.map('map');
        // var map = new tomtom.Map('map');
        // var map = new L.Map('map');
    </script>
</body>
</html>


-->