<div>

  <h3> Face Recognition Debugger </h3>

  <div>
    Enter target Face Id here:
    <input [(ngModel)]="specificFaceId">

    <button (click)="fetchSpecificFace()">Get Face</button>
    <button (click)="fetchPersonFace()">Get PersonFace</button>
    <button (click)="fetchAllFaces()">Get All</button>
  </div>

  <div class="face-debugger-object" *ngFor="let faceCorrelation of faceCorrelations"
       [ngClass]="{'face-debugger-object-specific': mode == 'SPECIFIC_FACE'}">

    <div class="face-debugger-name">
      {{faceCorrelation.faceId}}
    </div>

    <img class="face-debugger-main-face" src="{{faceCorrelation.thumbnail}}">

    <div class="face-debugger-quality">
      Sharp: {{faceCorrelation.faceRecord.FaceDetail.Quality.Sharpness  | number:'1.0-0'}} Bright: {{faceCorrelation.faceRecord.FaceDetail.Quality.Brightness  | number:'1.0-0'}}
    </div>

    <div class="face-debugger-divider"></div>

    <div>
      Correlated Faces: {{faceCorrelation.correlatedFaces.length}}
    </div>

    <div *ngFor="let correlatedFace of faceCorrelation.correlatedFaces">
      <div>FaceId:     {{correlatedFace.faceId}}</div>
      <div>Similarity: {{correlatedFace.similarity}}</div>
      <div class="face-debugger-correlated-face-holder">
        <img class="face-debugger-correlated-face" src="{{correlatedFace.thumbnail}}">
      </div>
    </div>

  </div>

  <div class="clearfix"></div>

</div>