import { AlternateImageSize } from '../KintObject/kint-photo';
import { downgradeInjectable } from '@angular/upgrade/static';
import { LogService } from 'src/app/Log/log.service';
import * as i0 from "@angular/core";
import * as i1 from "../Log/log.service";
var PhotoSelectionService = /** @class */ (function () {
    /**
     * Creates an instance of PhotoSelectionService.
     *
     * @param {LogService} logService
     * @memberof PhotoSelectionService
     */
    function PhotoSelectionService(logService) {
        this.logService = logService;
    }
    /**
     * Given the specific photo and Element which we want to render the element in
     * select the best element for that purpose.
     *
     * Automatically handles:
     *   - Sizing
     *   - Dead links
     */
    PhotoSelectionService.prototype.chooseBestImage = function (photo, element, linksToIgnore) {
        // Select the smallest possible photo that will do the job for us. Choices include:
        // 1. photo.alternate_sizes[ {height: xxxx, width: xxxxx, link: xxxxx}...]
        // 2. photo.link
        // 3. photo.alternateLinks['xxxxxxxx', 'yyyyyyyy', 'zzzzzzzzz']
        if (!photo || (!photo.link && !photo.id)) {
            this.logService.info('Photo not supplied to chooseBestImage!');
            return null;
        }
        var elementWidth = element.width();
        if (elementWidth === 0) {
            if (element.css('max-width') === '100%') {
                elementWidth = this.getWidthOfParent(element);
            }
            else {
                // Can't determine the width. Size to the closest parent that has a width
                elementWidth = this.getWidthOfParent(element);
            }
        }
        var bestLink = this.getPhotoLinkForSize(elementWidth, photo, linksToIgnore);
        return bestLink;
    };
    /**
     * Get the best image to display in the given width on the screen.
     */
    PhotoSelectionService.prototype.getPhotoLinkForSize = function (elementWidth, photo, linksToIgnore) {
        var biggest = null;
        var bestAlternateSize = null;
        var smallestBiggerThanElementWidth = null;
        var closestSizeWidthDiff = 100000000;
        // If we are dealing with upload data AND we have a thumbnail, then use that instead!
        if (photo.link && (0 === photo.link.indexOf('actionaryUpload')) && photo.thumbnail) {
            return photo.thumbnail;
        }
        // If no size data present, use the primary link!
        if (!photo.size || !photo.alternate_sizes) {
            if (photo.link) {
                return photo.link;
            }
            if (photo.thumbnail) {
                return photo.thumbnail;
            }
            this.logService.error('Error loading photo. No links or thumbnails!');
            return photo.link;
        }
        // Start with the biggest alternate size
        if (photo.alternate_sizes) {
            for (var k = 0; k < photo.alternate_sizes.length; k++) {
                var someSize = photo.alternate_sizes[k];
                if (this.shouldIgnoreLink(someSize.link, linksToIgnore)) {
                    // Ignoring broken link
                    continue;
                }
                if (!biggest) {
                    biggest = someSize;
                }
                else {
                    if (someSize.height > biggest.height) {
                        biggest = someSize;
                    }
                }
            }
        }
        // Start with the largest photo and work our way down!
        // This ensures that we don't pick a 500-pixel photo for 750 pixel display (instead of using our 1024 version)
        // Otherwise, the images get pixelated really bad
        if (!biggest) {
            // Couldn't find a 'biggest' photo, so just use the original image as biggest
            bestAlternateSize = new AlternateImageSize(photo.size.width, photo.size.height, photo.link);
            closestSizeWidthDiff = Math.abs(bestAlternateSize.width - elementWidth);
            smallestBiggerThanElementWidth = bestAlternateSize;
            biggest = bestAlternateSize;
        }
        // Treat this biggest images as our best alternate until we find another suitable one
        bestAlternateSize = biggest;
        closestSizeWidthDiff = Math.abs(bestAlternateSize.width - elementWidth);
        smallestBiggerThanElementWidth = biggest;
        // Compare with all of the alternate sizes
        if (photo.alternate_sizes) {
            for (var i = 0; i < photo.alternate_sizes.length; i++) {
                var alternateSize = photo.alternate_sizes[i];
                if (this.shouldIgnoreLink(alternateSize.link, linksToIgnore)) {
                    // Ignoring broken link
                    continue;
                }
                var sizeDiff = Math.abs(alternateSize.width - elementWidth);
                if (sizeDiff <= closestSizeWidthDiff) {
                    closestSizeWidthDiff = sizeDiff;
                    bestAlternateSize = alternateSize;
                }
            }
            for (var j = 0; j < photo.alternate_sizes.length; j++) {
                var otherSize = photo.alternate_sizes[j];
                if (this.shouldIgnoreLink(otherSize.link, linksToIgnore)) {
                    // Ignoring broken link
                    continue;
                }
                if (otherSize.width > elementWidth) {
                    if (!smallestBiggerThanElementWidth) {
                        smallestBiggerThanElementWidth = otherSize;
                    }
                    else {
                        if (Math.abs(otherSize.width - elementWidth) <= Math.abs(smallestBiggerThanElementWidth.width - elementWidth)) {
                            smallestBiggerThanElementWidth = otherSize;
                        }
                    }
                }
            }
        }
        // Prefer the smallest photo which is also at least the size of this element's width!
        if (smallestBiggerThanElementWidth) {
            bestAlternateSize = smallestBiggerThanElementWidth;
        }
        var bestLink = null;
        if (bestAlternateSize) {
            bestLink = bestAlternateSize.link;
        }
        // We likely have the best link now. However, let's just double-check to make sure the best
        // link isn't in our "ignore" list (May happen for Actionary photos which don't have
        // alternate sizes AND whose primary links may be broken)
        if (this.shouldIgnoreLink(bestLink, linksToIgnore)) {
            // Well crap... main link is busted. Try an alternate link
            if (photo.alternateLinks) {
                for (var i = 0; i < photo.alternateLinks.length; i++) {
                    var alternateLink = photo.alternateLinks[i];
                    if (this.shouldIgnoreLink(alternateLink, linksToIgnore)) {
                        continue;
                    }
                    this.logService.info('Reverting to alternate link for image: ' + photo.id + ' link: ' + alternateLink);
                    bestLink = alternateLink;
                }
            }
        }
        // If we couldn't find a good link, just display the original link (though it will likely be dead)
        if (!bestLink) {
            this.logService.error('Error selecting photo image. Defaulting back to primary.');
            bestLink = photo.link;
        }
        return bestLink;
    };
    /**
     * Gets the width of the given element (jQuery element)
     *
     * @param {jQuery element} child
     * @returns {number}
     * @memberof PhotoSelectionService
     */
    PhotoSelectionService.prototype.getWidthOfParent = function (child) {
        var parent = child.parent();
        if (parent.length > 0) {
            var parentWidth = parent.width();
            if (parentWidth) {
                return parentWidth;
            }
            else {
                return this.getWidthOfParent(parent);
            }
        }
    };
    /**
     * Checks to see if the given link is in the list of ignorable links
     *
     * @param {string} link
     * @param {string[]} linksToIgnore
     * @returns
     */
    PhotoSelectionService.prototype.shouldIgnoreLink = function (link, linksToIgnore) {
        for (var i = 0; i < linksToIgnore.length; i++) {
            if (link === linksToIgnore[i]) {
                return true;
            }
        }
        return false;
    };
    PhotoSelectionService.ngInjectableDef = i0.defineInjectable({ factory: function PhotoSelectionService_Factory() { return new PhotoSelectionService(i0.inject(i1.LogService)); }, token: PhotoSelectionService, providedIn: "root" });
    return PhotoSelectionService;
}());
export { PhotoSelectionService };
// Register an AngularJS service, whose value is the "downgraded" Angular injectable.
angular.module('KintributeApp').factory('PhotoSelectionService', downgradeInjectable(PhotoSelectionService));
