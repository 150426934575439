/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./personal-page-row.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../personal-page-text-row/personal-page-text-row.component.ngfactory";
import * as i3 from "../personal-page-text-row/personal-page-text-row.component";
import * as i4 from "../personal-page-tiles-row/personal-page-tiles-row.component.ngfactory";
import * as i5 from "../personal-page-tiles-row/personal-page-tiles-row.component";
import * as i6 from "@angular/common";
import * as i7 from "./personal-page-row.component";
var styles_PersonalPageRowComponent = [i0.styles];
var RenderType_PersonalPageRowComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PersonalPageRowComponent, data: {} });
export { RenderType_PersonalPageRowComponent as RenderType_PersonalPageRowComponent };
function View_PersonalPageRowComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-personal-page-text-row", [], null, null, null, i2.View_PersonalPageTextRowComponent_0, i2.RenderType_PersonalPageTextRowComponent)), i1.ɵdid(2, 8503296, null, 0, i3.PersonalPageTextRowComponent, [i1.ElementRef], { row: [0, "row"], isMobile: [1, "isMobile"], editor: [2, "editor"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.row; var currVal_1 = _co.isMobile; var currVal_2 = _co.editor; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_PersonalPageRowComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "app-personal-page-tiles-row", [], null, null, null, i4.View_PersonalPageTilesRowComponent_0, i4.RenderType_PersonalPageTilesRowComponent)), i1.ɵdid(2, 8503296, null, 0, i5.PersonalPageTilesRowComponent, [i1.ElementRef], { row: [0, "row"], isMobile: [1, "isMobile"], editor: [2, "editor"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.row; var currVal_1 = _co.isMobile; var currVal_2 = _co.editor; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_PersonalPageRowComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "personal-page-row"]], null, null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i6.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { "personal-page-row-mobile": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PersonalPageRowComponent_1)), i1.ɵdid(4, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PersonalPageRowComponent_2)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "personal-page-row"; var currVal_1 = _ck(_v, 2, 0, _co.isMobile); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = (_co.row.type == "TEXT"); _ck(_v, 4, 0, currVal_2); var currVal_3 = ((_co.row.type == "COLLECTION_HIGHLIGHTS") || (_co.row.type == "ESSAY_HIGHLIGHTS")); _ck(_v, 6, 0, currVal_3); }, null); }
export function View_PersonalPageRowComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-personal-page-row", [], null, null, null, View_PersonalPageRowComponent_0, RenderType_PersonalPageRowComponent)), i1.ɵdid(1, 114688, null, 0, i7.PersonalPageRowComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PersonalPageRowComponentNgFactory = i1.ɵccf("app-personal-page-row", i7.PersonalPageRowComponent, View_PersonalPageRowComponent_Host_0, { row: "row", isMobile: "isMobile", editor: "editor" }, {}, []);
export { PersonalPageRowComponentNgFactory as PersonalPageRowComponentNgFactory };
