import { OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
var AppComponent = /** @class */ (function () {
    function AppComponent() {
        this.title = 'kintribute';
    }
    AppComponent.prototype.ngOnInit = function () {
        console.log('ngOnInit: AppComponent');
    };
    return AppComponent;
}());
export { AppComponent };
// Provide a downgraded version of this to run with AngularJS
angular.module('KintributeApp')
    .directive('ng2App', downgradeComponent({ component: AppComponent }));
