import { Directive, ElementRef, Injector, Input, Output } from '@angular/core';
import { UpgradeComponent } from '@angular/upgrade/static';
@Directive({
    // tslint:disable-next-line:directive-selector
    selector: 'kint-icon'
})
export class KintIconDirective extends UpgradeComponent {

    @Input('icon') icon: string;

    constructor(elementRef: ElementRef, injector: Injector) {
        console.log('kint-icon constructor!');
        super('kintIcon', elementRef, injector);
    }
}
