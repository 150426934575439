import { Component, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';

// Angular is loaded separately
declare var angular: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  title = 'kintribute';

  ngOnInit() {
    console.log('ngOnInit: AppComponent');
  }
}


// Provide a downgraded version of this to run with AngularJS
angular.module('KintributeApp')
  .directive('ng2App', downgradeComponent({component: AppComponent}));
