import { OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
var FaceDebuggerComponent = /** @class */ (function () {
    function FaceDebuggerComponent(apiService) {
        this.apiService = apiService;
        this.faceCorrelations = [];
        this.specificFaceId = '';
        this.mode = 'SPECIFIC_FACE';
    }
    FaceDebuggerComponent.prototype.ngOnInit = function () {
    };
    FaceDebuggerComponent.prototype.fetchSpecificFace = function () {
        this.mode = 'SPECIFIC_FACE';
        var self = this;
        var promise = this.apiService.getFaceDebugDataForFace(this.specificFaceId);
        promise.then(function (response) {
            self.faceCorrelations = response.matches;
            console.log('Fetched face correlations');
        }, function (err) {
            console.error('Error fetching faces for face id: ' + JSON.stringify(err));
        });
    };
    FaceDebuggerComponent.prototype.fetchAllFaces = function () {
        this.mode = 'ALL_FACES';
        var self = this;
        var promise = this.apiService.getFaceDebugData();
        promise.then(function (response) {
            self.faceCorrelations = response.matches;
            console.log('Fetched face correlations');
        }, function (err) {
            console.error('Error fetching faces (ALL): ' + JSON.stringify(err));
        });
    };
    FaceDebuggerComponent.prototype.fetchPersonFace = function () {
        this.mode = 'ALL_FACES';
        var self = this;
        var promise = this.apiService.getFaceDebugDataForPersonFaceFace(this.specificFaceId);
        promise.then(function (response) {
            self.faceCorrelations = response.matches;
            console.log('Fetched face correlations  for PersonFace');
        }, function (err) {
            console.error('Error fetching faces for personface: ' + JSON.stringify(err));
        });
    };
    return FaceDebuggerComponent;
}());
export { FaceDebuggerComponent };
// Provide a downgraded version of this to run with AngularJS
angular.module('KintributeApp')
    .directive('faceDebugger', downgradeComponent({
    component: FaceDebuggerComponent,
    inputs: []
}));
